import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Vehicle } from 'src/app/models/vehicle';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { VehicleService } from 'src/app/services/vehicle/vehicle.service';

@Component({
  selector: 'app-vehicle-create',
  templateUrl: './vehicle-create.component.html',
  styleUrls: ['./vehicle-create.component.scss']
})
export class VehicleCreateComponent implements OnInit {

  @Input() vehicle?: Vehicle;

  constructor(public vehicleService: VehicleService, private communicationService: CommunicationService, private permissionService: PermissionService) { 
  }

  ngOnInit(): void {
  }

  /**
   * This method will creaet the new Vehicle 
   */
  createVehicle(){
    if(!this.vehicle){
      return;
    }
    
    // Save Vehicle
    this.vehicleService.addVehicle(this.vehicle, this.communicationService.activeTeamId.getValue()!, this.permissionService.getWritePermissions()).then(vehicle => {
      if(this.vehicle){
        this.vehicle.$id = vehicle?.$id;
      }

      // Add to list of Active Vehicles
      var activeVehicles = this.communicationService.activeVehicles.getValue();
      activeVehicles.push(this.vehicle!);

      this.communicationService.activeToastKind.next("Success");
      this.communicationService.activeToastMessage.next(`Het aanmaken van voertuig ${this.vehicle?.name} is gelukt!`);
    
      this.communicationService.activeVehicles.next(activeVehicles);
      this.vehicle = undefined;
    }).catch(() => {
      this.communicationService.activeToastKind.next("Error");
      this.communicationService.activeToastMessage.next(`Het aanmaken van voertuig ${this.vehicle?.name} is mislukt!`);
    });
  }

    /**
   * This method will update the date input value for the GreenCard ExpirationDate
   * @param event 
   */
    onGreenCardExpirationDateValuesChanged(event: any){
      if(this.vehicle){
        this.vehicle.expirationDateGreenCard = moment(event).toDate();
      }
    }
  
    /**
     * This method will update the date input for the APK expiration date
     * @param event 
     */
    onApkExpirationDateValuesChanged(event: any){
      if(this.vehicle){
        this.vehicle.expirationDateApk = moment(event).toDate();
      }
    }
}

import { CommonModule, DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LogEntry } from 'src/app/models/logEntry';

@Component({
  selector: 'app-log-book-line',
  standalone: true,
  imports: [DatePipe, CommonModule],
  templateUrl: './log-book-line.component.html',
  styleUrl: './log-book-line.component.scss'
})
export class LogBookLineComponent {

    @Input() logEntry?: Partial<LogEntry>;
    @Input() inlineComponent?: boolean;
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ID, Models } from 'appwrite';

import { Api } from 'src/app/helpers/api';
import { environment } from 'src/environments/environment';
import { LoginData } from 'src/app/models/services/login-data';
import { UserPreferences } from 'src/app/models/services/user-preferences';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  user: BehaviorSubject<Partial<Models.User<UserPreferences>>>;

  constructor() {
    this.user = new BehaviorSubject({});
    this.checkUserState();
  }

  async checkUserState() {
    Api.account.get().then(userState => {
      if (userState) {
        this.user.next(userState);
        // this.isLoggedIn.next(true);
      } else {
        this.user.next({});
        // this.isLoggedIn.next(false);
      }
    });
    return
  }
  
  // This method will sign a user in if the correct values are enterd
  signIn({ email, password }: LoginData) {
    return Api.account.createEmailSession(email, password).then(session => {
      return Api.account.get().then(userState => {
        return {
          user: {
            email: userState.email,
            uid: userState.$id,
            username: userState.name,
          }
        }
      })
    });
  }

  // This method will register a new user
  register({ email, password }: LoginData) {
    return Api.account.create(ID.unique(), email, password).then(() => {
      return Api.account.createEmailSession(email, password).then(() => {
        return Api.account.get().then(userState => {
          return {
            user: {
              email: userState.email,
              uid: userState.$id,
              username: userState.name
            }
          }
        });
      });
    });
  }

  /**
   * This method will recover a password if the user exists
   * @param emailAdress 
   */
  recoverPassword(emailAdress: string){
    return Api.account.createRecovery(emailAdress, environment.baseServiceUrl);
  }


  updateUsername(user: Partial<Models.User<UserPreferences>>) {
    return Api.account.updateName(user.name ?? "");
  }

  updateUserPreferences(UserPreferences: UserPreferences) {
    return Api.account.updatePrefs(UserPreferences);
  }

  // This method will sign the current user out
  async logout() {
    return Api.account.deleteSession("current").then(() => {
      // this.isLoggedIn.next(false);
      this.user.next({});
    });
  }
}

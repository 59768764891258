import { Injectable } from '@angular/core';
import { Api } from 'src/app/helpers/api';
import { Application } from 'src/app/models/application';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  applicationTable: string = "Application";


  /**
   * This method will get the Application Context for the given Id
   * @param applicationId 
   * @returns 
   */
  async getApplicationContext(applicationId: string): Promise<Application | undefined>{
    if(applicationId){
      var applications = await Api.database.listDocuments<Application>(environment.database, this.applicationTable);
  
      if(applications.documents.length > 0){
        return applications.documents[0];
      }
      return undefined;
    }
    return undefined;
  }
}

import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Day } from 'src/app/models/day';
import { ServiceRequest } from 'src/app/models/service-request';
import { Window } from 'src/app/models/window';
import { PlannerService } from 'src/app/services/planner/planner.service';
import * as moment from 'moment';
import { UnavailabilityService } from 'src/app/services/unavailability/unavailability.service';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { ServiceRequestService } from 'src/app/services/service-request/service-request.service';
import { Mechanic } from 'src/app/models/mechanic';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-service-request-availability',
  templateUrl: './service-request-availability.component.html',
  styleUrls: ['./service-request-availability.component.scss']
})
export class ServiceRequestAvailabilityComponent implements OnInit {

  serviceRequests?: ServiceRequest[];
  days?: Day[];
  weeks: number = 1;
  show5Days: boolean = true;
  hours: any[];
  activeWeek?: number;
  dateInput?: string;
  startDate: Date;
  openServiceRequests: string[] = [];
  showDropdown: boolean = false;
  mechanics?: Mechanic[];
  allMechanics: any[] = []
  selectedMechanics: any[] = []

  // The Subscriptions to Unsub on destroy
  subs: Subscription[] = [];

  constructor(private plannerService: PlannerService, private communicationService: CommunicationService, private datePipe: DatePipe, private unavailabilityService: UnavailabilityService, public serviceRequestService: ServiceRequestService) {
    this.dateInput = new Date().toString();
    this.startDate = this.plannerService.getMonday(new Date());
    this.activeWeek = this.plannerService.getWeekNumber(this.startDate);
    this.fillActiveWeek(this.startDate, this.weeks);
    this.show5Days = true;
    this.hours = [
      {
        name: "07:00",
        hoursFromMidnight: 7
      },
      {
        name: "08:00",
      },
      {
        name: "09:00"
      },
      {
        name: "10:00"
      },
      {
        name: "11:00"
      },
      {
        name: "12:00"
      },
      {
        name: "13:00"
      },
      {
        name: "14:00"
      },
      {
        name: "15:00"
      },
      {
        name: "16:00"
      },
      {
        name: "17:00",
        hoursToMidnight: 7
      }
    ];
   }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe()
    })
  }

  ngOnInit(): void {
    var currentDate = new Date();
    var parsedDate = this.datePipe.transform(currentDate, "yyyy-MM-dd");
    if(parsedDate != null){
      this.dateInput = parsedDate;
    }

    // Subscribe on ServiceRequests
    this.subs.push(
      this.communicationService.activeServiceRequests.subscribe(serviceRequests => {
        this.serviceRequests = serviceRequests;
        this.startDateChanged();
      })
    )

    this.subs.push(
      this.communicationService.activeMechanics?.subscribe(mechanics => {
        this.mechanics = mechanics;
        
        mechanics?.forEach(m => {
          this.allMechanics.push({'name': `${m.personalData?.firstname} ${m.personalData?.lastname}`, '$id': m.$id});
          this.selectedMechanics.push( {'name': `${m.personalData?.firstname} ${m.personalData?.lastname}`, '$id': m.$id});
        });
        
        this.allMechanics.sort((a,b) => (a!.name > b!.name) ? 1 : ((b!.name > a!.name) ? -1 : 0));
      })
    )
  }

  /**
   * This method will open the edit window for ServiceRequests
   * @param serviceRequest 
   */
    editServiceRequest(serviceRequest: ServiceRequest){
      this.communicationService.activeServiceRequest.next(serviceRequest);
   }

   /**
    * Inverse the Show boolean
    */
   toggleInformationDropdown(){
    this.showDropdown = !this.showDropdown;
   }

   /**
    * This method will return a joined string of the attached Mechanics
    * @param serviceWindow 
    */
   getMechanics(serviceWindow: Window){
      var mechanics = this.communicationService.activeMechanics.getValue()?.filter(m => (serviceWindow.assignedResources?.filter(r => r == m.$id).length ?? 0) > 0);
      return mechanics?.map(m => `${m.personalData?.firstname} ${m.personalData?.lastname}`).join(", ");
   }

    /**
    * This method will return a joined string of the attached Vehicles
    * @param serviceWindow 
    */
    getVehicles(serviceWindow: Window){
      var vehicles = this.communicationService.activeVehicles.getValue()?.filter(v => (serviceWindow.assignedResources?.filter(r => r == v.$id).length ?? 0) > 0);
      return vehicles?.map(v => v.name).join(", ");
    }

  /**
   * Fire event when the date input has changed
   * @returns 
   */
  startDateChanged(){
    if(!this.dateInput){
      return;
    }

    this.startDate = new Date(this.dateInput);
    this.activeWeek = this.plannerService.getWeekNumber(this.startDate);
    this.fillActiveWeek(this.plannerService.getMonday(this.startDate), this.weeks);
  }

  /**
   * This method will get the calendar days of next or previous week
   */
  getNextOrPreviousWeek(getNext: boolean){
    var result = this.plannerService.getMonday(this.startDate);
    var incrementedDate;

    if(getNext){
      incrementedDate = result.setDate(result.getDate() + 7);
    } else{
      incrementedDate = result.setDate(result.getDate() - 7);
    }
    var newDate = new Date(incrementedDate)
    var parsedDate = this.datePipe.transform(newDate, "yyyy-MM-dd");
    if(parsedDate != null){
      this.dateInput = parsedDate;
      this.startDate = newDate;
    }

    this.fillActiveWeek(newDate, this.weeks);
    this.activeWeek = this.plannerService.getWeekNumber(this.startDate);
  }

  /**
   * This method will fill the active week based on the given start date
   * @param startDate 
   */
  fillActiveWeek(startDate: Date, weeks: number){
    this.days = [];
    var dayNames = ["Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag", "Zondag"];

    var count = 0;
    for(let i = 0; i < (7 * weeks); i++){
      // If only the days during the week should be shown then reset the count after 4
      if(this.show5Days && count > 4){
        count++;

        if(count > 6){
          count = 0;
        }
      } else {
        var date = moment(startDate).add(i, 'd');
        this.days.push({
          date: date.toDate(),
          name: dayNames[count]
        });
  
        count++;
  
        if(count > 6){
          count = 0;
        }
      }
    }
  }

  /**
   * This method will toggle the service window expander
   * @param serviceWindow 
   */
  toggleServiceWindowExpander(serviceWindow: Window, expand: boolean){
    if(expand){
      this.openServiceRequests.push(serviceWindow.$id);
    } else{
      this.openServiceRequests = this.openServiceRequests.filter(s => s != serviceWindow.$id);
    }
  }

  /**
   * Check whether the service window is expanded or not
   * @param serviceWindow 
   */
  isExpanded(serviceWindow: Window) : boolean {
    return this.openServiceRequests.filter(s => s == serviceWindow.$id).length > 0;
  }

  /**
   * This metod will get the status of an attached mechanic for the given ServiceRequest
   * @param parentRequest 
   * @returns 
   */
  getMechanicStatus(serviceWindow: Window): string{
    var availabilityResult = this.unavailabilityService.mechanicsAreUnavailable(serviceWindow?.assignedResources, this.communicationService.activeMechanics.getValue(), serviceWindow);
    if(availabilityResult == "Unavailable"){
      // No resources available
      return "#ff0000";
    }

    if(availabilityResult == "No-Resources-Attached"){
      // No resources attached
      serviceWindow.noMechanicsAttached = true;
      return "gray";
    }

    // Available
    return "#33cc33";
  }

  /**
   * This metod will get the status of an attached vehicle for the given ServiceRequest
   * @param parentRequest 
   * @returns 
   */
  getVehicleStatus(serviceWindow: Window): string{
    var availabilityResult = this.unavailabilityService.vehiclesAreUnavailable(serviceWindow?.assignedResources, this.communicationService.activeVehicles.getValue(), serviceWindow);
 
    if(availabilityResult == "No-Resources-Attached"){
      var mechanics = this.communicationService.activeMechanics.getValue()?.filter(m => (serviceWindow.assignedResources?.filter(r => r == m.$id).length ?? 0) > 0);
      
      // If there is no vehicle attatched but one of the attached mechanics has an own vehicle then the vehicle is available
      if(mechanics?.filter(m => m.hasOwnVehicle).length > 0){
        return "#33cc33";
      }
    
    }

    if(availabilityResult == "Unavailable"){
      // No resources available
      return "#ff0000";
    }

    if(availabilityResult == "No-Resources-Attached"){
      // No resources attached
      serviceWindow.noVehiclesAttached = true;
      return "gray";
    }

    // Available
    return "#33cc33";
  }
  
  /**
   * This method will return the correct Color based on the status
   * @param parentRequest 
   * @returns 
   */
  getServiceWindowColor(parentRequest: ServiceRequest | undefined): string{
    var status = this.serviceRequestService.serviceRequestStatus?.filter(s => s.value == parentRequest?.status);
    
    if(status?.length > 0){
      return status[0].color;
    }

    return "#8793de"
  
  }

  /**
   * This method will return the ServiceWindows for the current day
   * @param date 
   * @returns 
   */
  getServiceWindows(date: Date) : Window[]{
    // SaftyChecks
    if(!this.serviceRequests || !date){
      return [];
    }
    
    var windows: Window[] = [];

    // Gather all windows on the given date
    var sortedWindows = this.plannerService.getSortedWindowsOnThisDay(this.serviceRequests, date, undefined);
    sortedWindows.forEach(w => {
      var duration = moment.duration(moment(w.endTime).diff(w.startTime));
      var hours = 0;
      var hoursFromStart = 0;

      // Only determine hours from start if the StartDate is equal to input date
      if(moment(w.startTime).isSame(moment(date), 'day')){
        var startOfDay = moment(w.startTime).set('hours', 7).set('minutes', 0).set('seconds', 0);
        hoursFromStart = moment.duration(moment(w.startTime).diff(startOfDay)).asHours();
      }

      var startOfDay = moment(date).set('hours', 7).set('minutes', 0).set('seconds', 0);
      var endOfDay = moment(date).set('hours', 17).set('minutes', 0).set('seconds', 0);

      if(moment(w.startTime).isSame(moment(w.endTime), 'day')){
        // Between Start- and EndTime
        var duration = moment.duration(moment(w.endTime).diff(w.startTime));
        var hours = duration.asHours();
      } else if(moment(w.startTime).isSame(moment(date), 'day')) {
        // From StartTime
        hours = moment.duration(moment(endOfDay).diff(w.startTime)).asHours();
      } else if(moment(w.endTime).isSame(moment(date), 'day')) {
        // Till EndTime
        hours = moment.duration(moment(w.endTime).diff(startOfDay)).asHours();
      } else{
        // Entire day
        hours = moment.duration(moment(endOfDay).diff(startOfDay)).asHours();
      }

      // Determine the difference in days and which day of the total set of days this window represents
      var totalDays = moment(w.endTime).hours(0).minutes(0).seconds(0).diff(moment(w.startTime).hours(0).minutes(0).seconds(0), 'days');
      var dayOfTotalDays = moment(date).hours(0).minutes(0).seconds(0).diff(moment(w.startTime).hours(0).minutes(0).seconds(0), 'days');

      // Filter out the not selected Mechanics
      if(this.selectedMechanics?.filter(m => w.assignedResources?.includes(m.$id)).length == 0){
        return;
      }

      windows.push({
        $id: w.$id,
        $collectionId: w.$collectionId,
        $createdAt: w.$createdAt,
        $databaseId: w.$databaseId,
        $permissions: w.$permissions,
        $updatedAt: w.$updatedAt,
        startTime: moment(w.startTime).toDate(),
        endTime: moment(w.endTime).toDate(),
        width: `${hours * 10}%`,
        marginStart: `${hoursFromStart * 10}%`,
        parentRequest: w.parentRequest,
        assignedResources: w.assignedResources,
        assignedMechanics: w.assignedMechanics,
        hoursBetween: hours,
        dayOfDays: totalDays == 0 ? undefined :`${dayOfTotalDays + 1}/${totalDays + 1}`
      });
    });

    return windows;
  }

  /**
   * This method will return the ServiceWindows for the current day
   * @param date 
   * @returns 
   */
  getUnavailabilitiesMechanics(date: Date) : Window[]{
    // SaftyChecks
    if(!this.mechanics || !date){
      return [];
    }
    
    var windows: Window[] = [];

    this.mechanics.forEach(mechanic => {
      
      if(!mechanic.unavailability){
        return;
      }

      // Filter out the not selected Mechanics
      if(this.selectedMechanics?.filter(m => m.$id == mechanic.$id).length == 0){
        return;
      }
        
      // Gather all windows on the given date
      var sortedUnavailabilties = this.plannerService.getSortedUnavailabilitiessOnThisDay(mechanic.unavailability, date);
      sortedUnavailabilties.forEach(w => {
        var duration = moment.duration(moment(w.endTime).diff(w.startTime));
        var hours = 0;
        var hoursFromStart = 0;

        // Only determine hours from start if the StartDate is equal to input date
        if(moment(w.startTime).isSame(moment(date), 'day')){
          var startOfDay = moment(w.startTime).set('hours', 7).set('minutes', 0).set('seconds', 0);
          hoursFromStart = moment.duration(moment(w.startTime).diff(startOfDay)).asHours();
        }

        var startOfDay = moment(date).set('hours', 7).set('minutes', 0).set('seconds', 0);
        var endOfDay = moment(date).set('hours', 17).set('minutes', 0).set('seconds', 0);

        if(moment(w.startTime).isSame(moment(w.endTime), 'day')){
          // Between Start- and EndTime
          var duration = moment.duration(moment(w.endTime).diff(w.startTime));
          var hours = duration.asHours();
        } else if(moment(w.startTime).isSame(moment(date), 'day')) {
          // From StartTime
          hours = moment.duration(moment(endOfDay).diff(w.startTime)).asHours();
        } else if(moment(w.endTime).isSame(moment(date), 'day')) {
          // Till EndTime
          hours = moment.duration(moment(w.endTime).diff(startOfDay)).asHours();
        } else{
          // Entire day
          hours = moment.duration(moment(endOfDay).diff(startOfDay)).asHours();
        }

        // Determine the difference in days and which day of the total set of days this window represents
        var totalDays = moment(w.endTime).hours(0).minutes(0).seconds(0).diff(moment(w.startTime).hours(0).minutes(0).seconds(0), 'days');
        var dayOfTotalDays = moment(date).hours(0).minutes(0).seconds(0).diff(moment(w.startTime).hours(0).minutes(0).seconds(0), 'days');

        windows.push({
          $id: w.$id,
          $collectionId: w.$collectionId,
          $createdAt: w.$createdAt,
          $databaseId: w.$databaseId,
          $permissions: w.$permissions,
          $updatedAt: w.$updatedAt,
          startTime: moment(w.startTime).toDate(),
          endTime: moment(w.endTime).toDate(),
          width: `${hours * 10}%`,
          marginStart: `${hoursFromStart * 10}%`,
          mechanic: mechanic,
          unavailability: w,
          hoursBetween: hours,
          dayOfDays: totalDays == 0 ? undefined :`${dayOfTotalDays + 1}/${totalDays + 1}`
        });
      });
    });

    return windows;
  }

  /**
   * This method will return the available or partly available Mechanics on the given date
   * @param date 
   */
  getAvailableMechanics(date: Date) : Mechanic[] {

    var availableMechanics: Mechanic[] = [];
    var unavailabilities = this.getUnavailabilitiesMechanics(date);
    var serviceWindows = this.getServiceWindows(date);
    
    this.mechanics?.forEach(m => {
      if(m.available != "Beschikbaar" || !m.showAvailabilityInSchedule || this.selectedMechanics?.filter(sm => sm.$id == m.$id).length == 0){
        return;
      }

      var filteredUnavailabilities = unavailabilities?.filter(u => u.mechanic == m) ?? [];
      var filteredServiceWindows = serviceWindows?.filter(u => u.assignedResources?.includes(m.$id)) ?? [];

      // If there are no ServiceWindows and there are no Unavailabilities then the Mechanic is fully available for this day
      if(filteredUnavailabilities?.length == 0 && filteredServiceWindows?.length == 0){
        m.partlyAvailable = false;
        availableMechanics.push(m);
      } else {
        var sortedWindowsList = filteredServiceWindows.concat(filteredUnavailabilities).sort((a,b) => (a!.startTime > b!.startTime) ? 1 : ((b!.startTime > a!.startTime) ? -1 : 0));
        
        // Validate if there is space in front
        var minutesBetween = moment(sortedWindowsList[0].startTime).diff(moment(sortedWindowsList[0].startTime).startOf('day'), 'minutes');
        var hoursFromMidnight = this.hours[0].hoursFromMidnight;
        var spaceInFront = minutesBetween - (hoursFromMidnight * 60);

        if(spaceInFront > 30 && moment(sortedWindowsList[0].startTime).isSame(moment(date), 'day')){
          m.partlyAvailable = true;
          availableMechanics.push(m);
          return;
        }

        // Validate if there is space in rear
        var minutesBetween = moment(sortedWindowsList.slice(-1)[0].endTime).diff(moment(sortedWindowsList.slice(-1)[0].endTime).endOf('day'), 'minutes') - 1;
        var hoursToMidnight = this.hours[this.hours.length - 1].hoursToMidnight;
        var spaceInRear = Math.abs(minutesBetween) - (hoursToMidnight * 60);

        if(spaceInRear > 30 && moment(sortedWindowsList[0].endTime).isSame(moment(date), 'day')){
          m.partlyAvailable = true;
          availableMechanics.push(m);
          return;
        }

        // Validate if there is any time in between to check if the Mechanic is partly available
        var prevWindow: Window | undefined = undefined;
        var availabilityFound = false;
        sortedWindowsList?.forEach(w => {
          if(availabilityFound){
            return;
          }

          if(prevWindow){
            if(moment(w.startTime).diff(moment(prevWindow.endTime), 'minutes') >= 30){
              m.partlyAvailable = true;
              availableMechanics.push(m);
              availabilityFound = true;
            }
          }
          
          prevWindow = w;
        });
      }
    });

    return availableMechanics;
  }

  /**
   * This method will return the color of a status
   * @param status 
   * @returns 
   */
  getStatusColor(status: string) {
    if (!status) {
      return;
    }

    var statusResource = this.serviceRequestService?.serviceRequestStatus.filter(s => s.value == status);

    if (!statusResource || statusResource?.length == 0) {
      return;
    }

    return statusResource[0].color;
  }

}

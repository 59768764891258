import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Company } from 'src/app/models/company';
import { Machine } from 'src/app/models/machine';
import { ServiceRequest } from 'src/app/models/service-request';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { AssetManagementMachineEditComponent } from "../../../asset-management/asset-management-machine-edit/asset-management-machine-edit.component";
import { AssetManagementCompanyEditComponent } from "../../../asset-management/asset-management-company-edit/asset-management-company-edit.component";

@Component({
  selector: 'app-service-request-customer-values',
  standalone: true,
  imports: [FormsModule, AssetManagementMachineEditComponent, AssetManagementCompanyEditComponent],
  templateUrl: './service-request-customer-values.component.html',
  styleUrl: './service-request-customer-values.component.scss'
})
export class ServiceRequestCustomerValuesComponent {

  @Input() machine?: Machine;
  @Input() serviceRequest?: ServiceRequest;

  newMachine: boolean = false;
  newCompany: boolean = false;

  constructor(private communicationService: CommunicationService){
  }

  createMachine(){
    this.newMachine = true;
    this.machine = {
      $id: "",
      $collectionId: "",
      $createdAt: "",
      $databaseId: "",
      $permissions: [],
      $updatedAt: "",
      machineId: this.serviceRequest?.machineId ?? ""
    };
    
    this.communicationService.activeMachine.next(this.machine);
  }

  /**
   * This method will attach a new Company to the current Machine
   * @returns 
   */
  createCompany(){
    if(!this.machine){
      return;
    }

    this.newCompany = true;
    this.machine.company = {
      $id: "",
      $collectionId: "",
      $createdAt: "",
      $databaseId: "",
      $permissions: [],
      $updatedAt: "",
      companyName: "",
      customerRelationId: ""
    }

    this.communicationService.activeCompany.next(this.machine.company);
  }

  /**
   * Canceld the creation of a new company --> Return to the current proces-step
   * @returns
   */
  canceldCompanyCreation(){
    if(!this.machine){
      return;
    }

    this.newCompany = false;
    this.machine.company = undefined;
  }

  /**
   * Company was created. Attach reference
   * @returns 
   */
  createdCompany(company: Company){
    if(!this.machine){
      return;
    }

    // Update relations and set newCompany to false to close the window
    this.newCompany = false;
    this.machine.company = company;
    this.machine.relatedCompanyId = this.machine.company?.$id;
    this.communicationService.activeMachine?.next(this.machine);
  }
}

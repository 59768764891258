import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Vehicle } from 'src/app/models/vehicle';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { VehicleService } from 'src/app/services/vehicle/vehicle.service';

@Component({
  selector: 'app-vehicle-dashboard',
  templateUrl: './vehicle-dashboard.component.html',
  styleUrls: ['./vehicle-dashboard.component.scss']
})
export class VehicleDashboardComponent implements OnInit {

  searchInput: string = "";
  sortedBy?: string;
  prevSort?: string;
  sortedAscending?: boolean;
  loadingVehicles?: boolean = false;
  lazyLoadingVehicles?: boolean = false;
  paginatedActiveVehicles?: [Vehicle[]];
  allEstablishmentVehicles: Vehicle[] = [];
  isViewer?: boolean;
  perPage: number;
  activePage: number;
  activeVehicle?: Vehicle;
  newVehicle?: Vehicle;
  perPageStorageKey: string = "VEHICLES_PERPAGE";

  // The Subscriptions to Unsub on destroy
  subs: Subscription[] = [];

  constructor(public communicationService: CommunicationService, public vehicleService: VehicleService) { 
    this.perPage = Number(localStorage.getItem(this.perPageStorageKey) ?? 20);
    this.activePage = 0;
    this.sortedBy = "name"
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe()
    })
  }

  ngOnInit(): void {
    this.subs.push(
      this.communicationService.activeVehicles.subscribe(activeVehicleList => {
        if (!activeVehicleList) {
          return;
        }
        
        this.allEstablishmentVehicles = activeVehicleList;
        this.paginateActiveVehicles();
      })
    )

    this.subs.push(
      this.communicationService.activeVehicle.subscribe(activeVehicle => {
        this.activeVehicle = activeVehicle;
      })
    )

    if(this.sortedBy){
      this.sortVehiclesBy(this.sortedBy, false);
    }
  }

  onPerPageChanged(){
    localStorage.setItem(this.perPageStorageKey, this.perPage?.toString());
    this.paginateActiveVehicles();
  }
  
  /**
   * This method will create a new vehicle to fill in the Create-Component
   */
  createNewVehicle(){
    this.activeVehicle = undefined;
    this.newVehicle = {
      $id: "1",
      $collectionId: "",
      $createdAt: "",
      $databaseId: "",
      $permissions: [],
      $updatedAt: "",
      available: "Beschikbaar",
      name: "",
      owner: "",
    };
  }

  /**
   * This method will search in the present set of Vehicles
   * based on the given search input
   * @returns 
   */
  searchVehicles(){
    if (!this.searchInput || this.searchInput == "") {
      this.allEstablishmentVehicles.forEach(t => t.notMatchingFilter = false);
      this.paginateActiveVehicles();
      return;
    }


    this.allEstablishmentVehicles.forEach(vehicle => {
      var matchingAttributeFound = false;
      if([vehicle].filter(
        vehicle =>
          vehicle.name?.toLowerCase().includes(this.searchInput.toLowerCase()) ||
          vehicle.owner?.toLowerCase().includes(this.searchInput.toLowerCase()) ||
          vehicle.registrationNumber?.toLowerCase().includes(this.searchInput.toLowerCase()) ||
          vehicle.available.toLowerCase().includes(this.searchInput.toLowerCase())).length > 0){
            vehicle.notMatchingFilter = false;
            matchingAttributeFound = true;
          }
      

    if(!matchingAttributeFound){
          vehicle.notMatchingFilter = true;
        }
      });

    this.paginateActiveVehicles();
  }

  /**
   * This method will set the active Vehicle which will be shown in the detail screen
   * @param vehicle 
   */
  editVehicle(vehicle: Vehicle){
    this.activeVehicle = vehicle;
    this.newVehicle = undefined;
    this.communicationService.activeVehicle?.next(vehicle);
  }

  /**
   * This method will sort the set of vehicles ASC or DESC based on the given input
   * @param sortBy 
   */
  sortVehiclesBy(column: string, toggle: boolean = true){
    this.sortedBy = column;
    this.prevSort = column;

    if (column == "name") {
      if (this.sortedBy == column && this.sortedAscending) {
        if(toggle){
          this.sortedAscending = false;
        }

        this.allEstablishmentVehicles.sort(function (a: Vehicle, b: Vehicle) {
          var textA = b.name?.toUpperCase();
          var textB = a.name?.toUpperCase();

          if (!textA) {
            textA = "";
          }

          if (!textB) {
            textB = "";
          }

          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.paginateActiveVehicles();
        return;
      }

      this.allEstablishmentVehicles.sort(function (a: Vehicle, b: Vehicle) {
        var textA = a.name?.toUpperCase();
        var textB = b.name?.toUpperCase();

        if (!textA) {
          textA = "";
        }

        if (!textB) {
          textB = "";
        }

        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      if(toggle){
        this.sortedAscending = true;
      }
      this.sortedBy = column;
    } else if (column == "owner") {
      if (this.sortedBy == column && this.sortedAscending) {
        if(toggle){
          this.sortedAscending = false;
        }

        this.allEstablishmentVehicles.sort(function (a: Vehicle, b: Vehicle) {
          var textA = b.owner?.toUpperCase();
          var textB = a.owner?.toUpperCase();

          if (!textA) {
            textA = "";
          }

          if (!textB) {
            textB = "";
          }

          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.paginateActiveVehicles();
        return;
      }

      this.allEstablishmentVehicles.sort(function (a: Vehicle, b: Vehicle) {
        var textA = a.owner?.toUpperCase();
        var textB = b.owner?.toUpperCase();

        if (!textA) {
          textA = "";
        }

        if (!textB) {
          textB = "";
        }

        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      if(toggle){
        this.sortedAscending = true;
      }
      this.sortedBy = column;
    } else if (column == "registration number") {
      if (this.sortedBy == column && this.sortedAscending) {
        if(toggle){
          this.sortedAscending = false;
        }

        this.allEstablishmentVehicles.sort(function (a: Vehicle, b: Vehicle) {
          var textA = b.registrationNumber?.toUpperCase();
          var textB = a.registrationNumber?.toUpperCase();

          if (!textA) {
            textA = "";
          }

          if (!textB) {
            textB = "";
          }

          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.paginateActiveVehicles();
        return;
      }

      this.allEstablishmentVehicles.sort(function (a: Vehicle, b: Vehicle) {
        var textA = a.registrationNumber?.toUpperCase();
        var textB = b.registrationNumber?.toUpperCase();

        if (!textA) {
          textA = "";
        }

        if (!textB) {
          textB = "";
        }

        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      if(toggle){
        this.sortedAscending = true;
      }
      this.sortedBy = column;
    } else if (column == "availability") {
      if (this.sortedBy == column && this.sortedAscending) {
        
        if(toggle){
          this.sortedAscending = false;
        }

        this.allEstablishmentVehicles.sort(function (a: Vehicle, b: Vehicle) {
          var textA = b.available?.toUpperCase();
          var textB = a.available?.toUpperCase();

          if (!textA) {
            textA = "";
          }

          if (!textB) {
            textB = "";
          }

          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.paginateActiveVehicles();
        return;
      }

      this.allEstablishmentVehicles.sort(function (a: Vehicle, b: Vehicle) {
        var textA = a.available?.toUpperCase();
        var textB = b.available?.toUpperCase();

        if (!textA) {
          textA = "";
        }

        if (!textB) {
          textB = "";
        }

        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      
      if(toggle){
        this.sortedAscending = true;
      }

      this.sortedBy = column;
    }

    this.paginateActiveVehicles();
  }

  /**
   * This method will paginate all present Vehicles
   * @returns 
   */
  paginateActiveVehicles(){
    if (!this.allEstablishmentVehicles) {
      return;
    }

    this.allEstablishmentVehicles.forEach(vehicle => {
       vehicle.hideInList = false;
    })
    
    var filterdActiveVehicles = this.allEstablishmentVehicles.filter(t => !t.hideInList && !t.notMatchingFilter)

    // If list is empty then clear paginated list
    if (filterdActiveVehicles.length == 0) {
      this.paginatedActiveVehicles = [[]];
    }

    for (var i = 0; i < filterdActiveVehicles.length / this.perPage; i++) {
      if (i == 0) {
        this.paginatedActiveVehicles = [filterdActiveVehicles.slice(i * this.perPage, i * this.perPage + this.perPage)];
        continue;
      }

      if (!this.paginatedActiveVehicles) {
        return;
      }

      this.paginatedActiveVehicles.push(filterdActiveVehicles.slice(i * this.perPage, i * this.perPage + this.perPage));
    }

    if (!this.paginatedActiveVehicles) {
      return;
    }

    if (this.activePage >= this.paginatedActiveVehicles?.length) {
      this.activePage = 0;
    }
  }

  /**
     * This method will update the vehicle status
     * @param vehicle 
     */
  statusSelectionChanged(vehicle: Vehicle, status: string) {
    if (!vehicle || !status) {
      return;
    }
    
    var currentStatus = status + "";
    vehicle.available = status;

    this.vehicleService.updateVehicle(vehicle).then(() => {
      this.communicationService.activeToastKind.next("Success");
      this.communicationService.activeToastMessage.next(`De status van voertuig ${vehicle?.name} veranderen is gelukt!`);
    }).catch(() => {
      vehicle.available = currentStatus;
      this.communicationService.activeToastKind.next("Error");
      this.communicationService.activeToastMessage.next(`De status van voertuig ${vehicle?.name} veranderen is mislukt!`);
    });
  }

  /**
   * This method will remove a vehicle or show a cofirmation button
   * @param vehicle 
   * @param index 
   * @param confirmationNeeded 
   * @returns 
   */
  async removeVehicle(vehicle: Vehicle, index: number, confirmationNeeded: boolean) {
    if (confirmationNeeded) {
      var confirmButton = document.getElementById("confirm-remove" + index);
      var cancelButton = document.getElementById("cancel-remove" + index);
      var removeButton = document.getElementById("remove" + index);
      var editButton = document.getElementById("edit" + index);

      if (editButton) {
        editButton.style.display = "none";
      }

      if (confirmButton && removeButton && cancelButton) {
        confirmButton.style.display = "flex";
        cancelButton.style.display = "flex";
        removeButton.style.display = "none";
      }
      return;
    }

    this.vehicleService.deleteVehicle(vehicle).then(() => {
      var activeVehicles = this.communicationService.activeVehicles.getValue();
      this.communicationService.activeVehicles.next(activeVehicles.filter(v => v != vehicle));
      
      this.communicationService.activeToastKind.next("Success");
      this.communicationService.activeToastMessage.next(`Het verwijderen van voertuig ${vehicle?.name} is gelukt!`);
    }).catch(() => {
      this.communicationService.activeToastKind.next("Error");
      this.communicationService.activeToastMessage.next(`Het verwijderen van voertuig ${vehicle?.name} is mislukt!`);
    });
  }

  /**
   * This methow will cancel a remove action
   * @param index 
   */
  cancelRemoval(index: number) {
    var confirmButton = document.getElementById("confirm-remove" + index);
    var cancelButton = document.getElementById("cancel-remove" + index);
    var removeButton = document.getElementById("remove" + index);
    var editButton = document.getElementById("edit" + index);

    if (confirmButton && removeButton && cancelButton && editButton) {
      confirmButton.style.display = "none";
      cancelButton.style.display = "none";
      removeButton.style.display = "block";
      editButton.style.display = "block";
    }
  }

  /**
   * This method will return the color of a status
   * @param status 
   * @returns 
   */
  getStatusColor(status: string) {
    if (!status) {
      return;
    }

    var statusResource = this.vehicleService?.vehicleStatusItems.filter(s => s.value == status);

    if (!statusResource || statusResource?.length == 0) {
      return;
    }

    return statusResource[0].color;
  }

  /**
   * This method will set the vehicle id to the given id so that the other status line could be accessed
   * @param vehicleId 
   */
  onStatusExpanderClick(vehicleId: string | undefined) {
    this.communicationService.openStatusVehicleIdChanged = true;
    if (this.communicationService.openStatusVehicleId == vehicleId) {
      this.communicationService.openStatusVehicleId = undefined;
    }
    this.communicationService.openStatusVehicleId = vehicleId;
  }

  /**
   * When click somewhere on the screen deativate open pop-ups
   */
  onClickLostFocus() {
    if (this.communicationService.openStatusVehicleIdChanged) {
      this.communicationService.openStatusVehicleIdChanged = false;
      return;
    }

    this.communicationService.openStatusVehicleIdChanged = false;
    this.communicationService.openStatusVehicleId = undefined;
  }
}

import { Injectable } from '@angular/core';
import { ID, Models, Permission, Query, Role } from 'appwrite';
import { Api } from 'src/app/helpers/api';
import { Window } from 'src/app/models/window';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  windowTable: string = "Windows";

  constructor() { }

  /**
   * This method will retrieve all Windows that belongs to the given ServiceRequest
   * @param requestId 
   */
  async getAllWindowsOfServiceRequest(requestId: string){
    var resultCount = 50;
    var resultList: Window[] = [];
    var lastId: string | undefined = undefined;
    var results: Models.DocumentList<Window>;

    while(resultCount == 50){
      if(lastId){
        results = await Api.database.listDocuments<Window>(
          environment.database,
          this.windowTable,
          [Query.limit(50), Query.equal('serviceRequestId', requestId), Query.cursorAfter(lastId)]
        );
      } else {
        results = await Api.database.listDocuments<Window>(
          environment.database,
          this.windowTable,
          [Query.limit(50), Query.equal('serviceRequestId', requestId)]
        );
      }

      lastId = results?.documents[results.documents.length - 1]?.$id;
      resultList.push(...results.documents);
      resultCount = results.documents.length;
    }

    return resultList;
  }

  /**
    * This method will create a new Window in the database
  */
  addWindow(window: Window, teamId: string, writePermissions: string[]) {
    return Api.database.createDocument(environment.database, this.windowTable, ID.unique(), this.createPartialWindow(window))
  }

  /**
   * This method will update an existing Window in the Database
  */
  updateWindow(window: Window) {
    return Api.database.updateDocument(environment.database, this.windowTable, window.$id, this.createPartialWindow(window));
  }

  /**
   * This method will delete an existing Window from the database
  */
  deleteWindow(window: Window) {
    return Api.database.deleteDocument(environment.database, this.windowTable, window.$id);
  }

  /**
   * Creates a partial instance of the mechanic without AppWrite properties
   * @param vehicle 
   * @returns 
  */
  private createPartialWindow(window: Window): Partial<Window> {
    if (!window) {
      return {};
    }

    return {
      startTime: window.startTime,
      endTime: window.endTime,
      assignedResourceIds: window.assignedResources?.map(r => r).join(" "),
      serviceRequestId: window.serviceRequestId
    };
  }

}

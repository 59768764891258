import { Injectable } from '@angular/core';
import { ID, Query } from 'appwrite';
import { Api } from 'src/app/helpers/api';
import { ContactPerson } from 'src/app/models/contact-person';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactPersonService {

  contactPersonTable: string = "ContactPersons";

  constructor() { }

  /**
   * This method will retrieve the contact persons for the given companyId
   */
  getContactPersonsOfCompany(companyId: string){
    return Api.database.listDocuments<ContactPerson>(environment.database, this.contactPersonTable, [Query.limit(50), Query.equal('companyId', companyId)]);
  }

  /**
  * This method will create a new ContactPerson in the database
  */
  addContactPerson(contactPerson: ContactPerson) {
    return Api.database.createDocument(environment.database, this.contactPersonTable, ID.unique(), this.createPartialContactPerson(contactPerson))
  }

  /**
   * This method will update an existing ContactPerson in the Database
   */
  updateContactPerson(contactPerson: ContactPerson) {
    return Api.database.updateDocument(environment.database, this.contactPersonTable, contactPerson.$id, this.createPartialContactPerson(contactPerson));
  }

  /**
   * This method will delete an existing ContactPerson from the database
   */
  deleteContactPerson(contactPerson: ContactPerson) {
    return Api.database.deleteDocument(environment.database, this.contactPersonTable, contactPerson.$id);
  }

  /**
   * Creates a partial instance of the mechanic without AppWrite properties
   * @param vehicle 
   * @returns 
   */
  private createPartialContactPerson(contactPerson: ContactPerson): Partial<ContactPerson> {
    if (!contactPerson) {
      return {};
    }

    return {
      emailAdress: contactPerson.emailAdress,
      firstname: contactPerson.firstname,
      lastname: contactPerson.lastname,
      phoneNumber: contactPerson.phoneNumber,
      landlineNumber: contactPerson.landlineNumber,
      role: contactPerson.role,
      companyId: contactPerson.companyId
    };
  }
}

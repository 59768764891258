// Note: Roles must be ordered correctly in-line with department
export enum Role {
    manager_engineering = 'Manager Engineering',
    engineer = 'Engineer',
    manager_elektro = 'Manager Elektro',
    elektromonteur = 'Elektromonteur',
    manager_inkoop = 'Manager Inkoop',
    project_manager = 'Project Manager',
    teamleider_plaatwerkerij = 'Teamleider Plaatwerkerij',
    plaatwerker = 'Plaatwerker',
    draaier_freezer = 'Draaier / Freezer',
    productie_manager = 'Productie Manager',
    machinebouwer = 'Machinebouwer',
    medewerker_kwaliteit = 'Medewerker Kwaliteit',
    service_manager = 'Service Manager',
    service_coordinator = 'Service Coördinator',
    servicemonteur = 'Servicemonteur',
    medewerker_magazijn = 'Medewerker Magazijn',
    manager_finance = 'Manager Finance',
    administratief_medewerkster = 'Administratief Medewerkster',
    sales_director = 'Sales Director',
    sales_consulatant = 'Sales Consultant',
    sales_marketing_medewerker = 'Sales & Marketing Medewerker',
    medewerker_ict = 'Medewerker ICT',
    operations_director = 'Operations Director',
    general_manager = 'General Manager',
    zzp = 'ZZP',
    klant = 'Klant'
}

export type RoleStrings = `${Role}`
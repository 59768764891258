import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Vehicle } from 'src/app/models/vehicle';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { VehicleService } from 'src/app/services/vehicle/vehicle.service';

@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss']
})
export class VehicleDetailsComponent implements OnInit {

  @Input() vehicle?: Vehicle;
  vehicleTypes: string[];

  constructor(public vehicleService: VehicleService, private communicationService: CommunicationService) { 
    this.vehicleTypes = [
      "Bestelwagen",
      "Monteursbus",
      "Personenauto",
      "Vrachtwagen"
    ]
  }

  ngOnInit(): void {
  }

  /**
   * This method will save the changes that has been made to the current Vehicle 
   */
  saveVehicleChanges(){
    if(!this.vehicle){
      return;
    }

    this.vehicleService.updateVehicle(this.vehicle).then(() => {
      this.communicationService.activeVehicle?.next(undefined);
      this.communicationService.activeToastKind.next("Success");
      this.communicationService.activeToastMessage.next(`Het updaten van voertuig ${this.vehicle?.name} is gelukt!`);
    }).catch(() => {
      this.communicationService.activeToastKind.next("Error");
      this.communicationService.activeToastMessage.next(`Het updaten van voertuig ${this.vehicle?.name} is mislukt!`);
    });
  }

  /**
   * This method will update the date input value for the GreenCard ExpirationDate
   * @param event 
   */
  onGreenCardExpirationDateValuesChanged(event: any){
    if(this.vehicle){
      this.vehicle.expirationDateGreenCard = moment(event).toDate();
    }
  }

  /**
   * This method will update the date input for the APK expiration date
   * @param event 
   */
  onApkExpirationDateValuesChanged(event: any){
    if(this.vehicle){
      this.vehicle.expirationDateApk = moment(event).toDate();
    }
  }
}

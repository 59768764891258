<div class="container">

    <div class="row second-row">
        <div class="col card">
            <div class="card-body">
                <h4>Werkvoorbereiding Module</h4>
                <p>Beheer en bekijk in onderstaand component alle Werkvoorbereiding taken en krijg inzicht in de openstaande taken en benodigde acties. Configureer de huidige weergave door componenten in en uit te schakelen met de onderstaande knoppen.</p>
                <div class="header-actions">
                    <div class="quick-actions">

                        <div class="component-toggle" (click)="toggleAllWVBTasksComponent(!showAllWVBTasksModule)" [ngStyle]="{'background-color': !showAllWVBTasksModule ? 'rgb(38, 52, 140, 0.6)' : 'rgb(38, 140, 38, 0.6)'}">
                            <i class="bi bi-eye"></i>
                            <span>Overzichtslijst Alle WVB Taken</span>
                        </div>
                        <div class="component-toggle" (click)="toggleServiceRequestsListComponent(!showServiceRequestModule)" [ngStyle]="{'background-color': !showServiceRequestModule ? 'rgb(38, 52, 140, 0.6)' : 'rgb(38, 140, 38, 0.6)'}">
                            <i class="bi bi-eye"></i>
                            <span>Overzichtslijst Serviceverzoeken</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    @if (showAllWVBTasksModule) {
        <app-work-preparation-tasks-table [showAllMode]="true"></app-work-preparation-tasks-table>
    }

    @if (showServiceRequestModule) {
        <app-service-request-table id="service-request-table"
        [selectedStatusCustom]='[{name:"Plannen"},{name:"Gepland"},{name:"Voorbereid"}]'
        [displayedColumns]="['Name', 'StartDate', 'Company', 'Status', 'WVBTasks', 'Actions']"
        [delBtnsAvailable]="false"  ></app-service-request-table>
    }

    <app-work-preparation-tasks-table></app-work-preparation-tasks-table>

    <app-work-preparation-task-create id="wvbTableModule" [wvbTask]="activeWVBTask" [serviceRequest]="activeServiceRequest"></app-work-preparation-task-create>
</div>

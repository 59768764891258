<div class="row second-sub-row">
    @if(serviceRequest){
        <h6>Rooster</h6>
        <p class="introduction">Beheer in onderstaand gedeelte de tijdvakken waarbinnen gewerkt kan worden tijdens dit serviceverzoek.</p>
    }
    <div>
        @if(serviceRequest && !readOnly){
            <div class="current-schedule">
                @for(window of serviceRequest.windows ?? []; track window.$id){
                    <div>
                        <div class="row schedule-row">
                            <div class="col-6 mb-1">
                                <div class="form-group">
                                    <label for="inputNewWindowStartDate">Startdatum:</label>
                                    <input type="datetime-local" max="9999-12-31T23:59" class="form-control" id="inputNewWindowStartDate" [ngModel]="window.startTime | date:'yyyy-MM-ddTHH:mm'" (ngModelChange)="onWindowStartTimeValuesChanged($event, window)">
                                </div>
                            </div>
                            <div class="col-6 mb-1">
                                <div class="form-group">
                                    <label for="inputNewWindowEndDate">Einddatum:</label>
                                    <input type="datetime-local" max="9999-12-31T23:59" class="form-control" id="inputNewWindowEndDate" [ngModel]="window.endTime | date:'yyyy-MM-ddTHH:mm'" (ngModelChange)="onWindowEndTimeValuesChanged($event, window)">
                                </div>
                            </div>
                            <div class="attached-resources col mb-1">
                                <label>Gekoppelde monteurs:</label>
                                <div class="assigned-resources-wrapper">
                                    @for(mechanic of window.assignedMechanics; track mechanic.$id){
                                        <div class="assigned-resource">
                                            <div class="mechanic-and-vehicle col">
                                                <span>{{getMechanicName(mechanic.$id)}}</span>
                                                @for(vehicle of getMechanicVehicles(mechanic.$id, window); track vehicle.$id){
                                                    <span>
                                                        {{vehicle.name}} - {{vehicle.registrationNumber}} 
                                                        @if(vehicle.attached){
                                                            <span class="attached">(Gekoppeld)</span>
                                                            <i class="bi bi-x-circle dettach-vehicle" (click)="detachVehicle(vehicle, window)"></i>
                                                        } @else {
                                                            <i class="bi bi-plus-circle attach-vehicle" (click)="attachVehicle(vehicle, window)"></i>
                                                        }
                                                    </span>
                                                }
                                                @if(getMechanicHasOwnVehicle(mechanic.$id)){
                                                    <span>
                                                        Eigen voertuig
                                                    </span>
                                                }
                                            </div>
                                            <div class="col-1 resource-actions">
                                                <i class="bi bi-x-lg" (click)="detachMechanic(window, mechanic.$id)"></i>
                                            </div>
                                        </div>
                                    } @empty {
                                        <div class="assigned-resource">
                                            <span>Geen monteur gekoppeld</span>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div class="attached-resources col mb-1">
                                <label>Gekoppelde voertuigen:</label>
                                <div class="assigned-resources-wrapper">
                                    @for(vehicle of window.assignedVehicles; track vehicle.$id){
                                        <div class="assigned-resource">
                                            <div class="mechanic-and-vehicle col">
                                                <span>{{getVehicleName(vehicle.$id)}}</span>
                                            </div>
                                            <div class="col-1 resource-actions">
                                                <i class="bi bi-x-lg" (click)="detachVehicle(vehicle, window)"></i>
                                            </div>
                                        </div>
                                    } @empty {
                                        <div class="assigned-resource">
                                            <span>Geen losse-voertuigen gekoppeld</span>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div class="attached-resources col-12">
                                <div class="resource-wrapper row">
                                    <div class="assign-resource col">
                                        <div class="attach-mechanic-wrapper input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text"><i class="bi bi-person-gear"></i></div>
                                            </div>
                                            <select [(ngModel)]="mechanicIdToAttach" class="form-control attach-mechanic" name="mechanicDropdown" id="mechanicInput">
                                                @for(mechanic of mechanics; track mechanic.$id){
                                                    <option value="{{mechanic.$id}}">
                                                        {{mechanic.personalData?.firstname}} {{mechanic.personalData?.lastname}} 
                                                    </option>
                                                }
                                            </select>
                                            <div class="attach-button" (click)="attachMechanic(window)"><i class="bi bi-plus-lg"></i></div>
                                        </div>
                                    </div>
                                    <div class="assign-resource assign-vehicle col">
                                        <div class="attach-vehicle-wrapper input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text"><i class="bi bi-truck"></i></div>
                                            </div>
                                            <select [(ngModel)]="vehicleIdToAttach" class="form-control attach-vehicle" name="vehicleDropdown" id="vehicleInput">
                                                @for(vehicle of vehicles; track vehicle.$id){
                                                    <option value="{{vehicle.$id}}">
                                                        {{vehicle.registrationNumber}} - {{vehicle.name}} 
                                                    </option>
                                                }
                                            </select>
                                            <div class="attach-button" (click)="attachVehicleToWindow(window)"><i class="bi bi-plus-lg"></i></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="window-actions">
                                    <button class="btn btn-danger" (click)="deleteWindow(window)"><i class="bi bi-trash3"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                } @empty {
                    <span class="no-schedule">Nog geen rooster aangemaakt</span>
                }
            </div>
        }
        @if(scheduleDate){
            <div class="shedule">
                <div class="row">
                    @if(serviceRequest){
                        <h6>Beschikbaarheidsrooster</h6>
                        <p class="introduction">Bekijk hier de beschikbaarheid van de monteurs per dag. Selecter beschikbare tijdvakken om deze toe te voegen aan het rooster van dit serviceverzoek.</p>
                    } @else {
                        <h4>Beschikbaarheidsrooster</h4>
                    }
                    <div class="filters">
                        <div class="form-group col-3">
                            <label for="inputNewWindowStartDate">Roosterdatum:</label>
                            <div class="input-wrapper">
                                <button class="btn btn-secondary" (click)="getNextOrPreviousDay(false)"><i class="bi bi-arrow-left-circle"></i></button>
                                <input type="date" class="form-control" id="inputNewWindowStartDate" [ngModel]="scheduleDate | date:'yyyy-MM-dd'" (ngModelChange)="scheduleDateChanged($event)">
                                <button class="btn btn-secondary" (click)="getNextOrPreviousDay(true)"><i class="bi bi-arrow-right-circle"></i></button>
                                <button class="btn btn-secondary legenda-button" (click)="toggleInformationDropdown()" [ngStyle]="{'background-color': showDropdown ? 'rgb(38, 140, 38, 0.6)' : ''}"><i class="bi bi-info-circle"></i>
                                    @if(showDropdown){
                                        <div class="legenda-items">
                                            @for(statusItem of plannerSerivce.windowStatus; track statusItem){
                                                <div class="status-item">
                                                    <div class="col">
                                                        <span class="badge badge-primary" [ngStyle]="{'background-color': statusItem.color}">
                                                            <i class="bi bi-card-checklist"></i>
                                                            {{statusItem.value}}
                                                        </span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                </button>
                            </div>
                        </div>
                        <div class="form-group col-3">
                            <label for="availabilitySheduleFilter">Beschikbaarheids filter: </label>
                            <select id="availabilitySheduleFilter" class="form-select" [(ngModel)]="filter" (ngModelChange)="currentWindowChanged()">
                                <option value="all">Alle statussen</option>
                                <option value="available">Beschikbaar</option>
                                <option value="unavailable">Niet beschikbaar</option>
                                <option value="availableOnRequest">Beschikbaar/Op aanvraag</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="schedule-grid">
                        <div class="grid-item header-grid-item" [ngStyle]="{'grid-area': getGridAreaValue(1, 1, 2)}">
                            <span class="column-or-header date-of-schedule">Datum: {{scheduleDate | date: 'dd-MM'}}</span>
                        </div>
                         <!-- Plot Hours of day-->
                        @for(hour of hours; track hour; let i = $index){
                            @let colIndex = i + 3;
                            <div class="grid-item header-grid-item" [ngStyle]="{'grid-area': getGridAreaValue(1, colIndex, 1)}">
                                <span class="column-or-header">{{hour.name}}</span>
                            </div>
                        }
                         <!-- Plot Mechanics-->
                        @for(mechanic of mechanics | windowsPresent; track mechanic.$id; let i = $index){
                            @let rowIndex = i + 2;
                            <div class="grid-item mechanic-row" [ngStyle]="{'grid-area': getGridAreaValue(rowIndex, 1, 2)}">
                                @if(mechanic.personalData){
                                    <span class="column-or-header">{{mechanic.personalData.firstname}} {{mechanic.personalData.lastname}}</span>
                                }
                            </div>
                            <!-- Plot ServiceWindows-->
                            @for(serviceWindow of mechanic.windows; track $index;){
                                <div class="grid-item grid-planning-line" [ngStyle]="{
                                    'grid-area': getGridAreaValue(rowIndex, (serviceWindow.startCol + 2), serviceWindow.colsLength), 
                                    'cursor': ((serviceWindow.displayName == 'Beschikbaar' || serviceWindow.displayName == 'Op aanvraag') && serviceRequest && readOnly == false) || (serviceWindow.displayName.includes('Inzet') && !serviceRequest) ? 'pointer' : 'auto'}" 
                                    (click)="addMechanicToSchedule(serviceWindow, mechanic)">
                                    <div class="planning-line" [style.background-color]="serviceWindow.backgroundColor" [ngClass]="(serviceWindow.displayName == 'Beschikbaar' || serviceWindow.displayName == 'Op aanvraag') && serviceRequest ? 'available' : 'occupied'">
                                        <span>{{serviceWindow.displayName}}</span>
                                    </div>
                                </div>
                            }
                        }
                    </div>
                </div>
            </div>
        }
    </div>
</div>
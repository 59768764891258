import { Injectable } from '@angular/core';
import { ID, Models, Query } from 'appwrite';
import { Api } from 'src/app/helpers/api';
import { ServiceRequest } from 'src/app/models/service-request';
import { environment } from 'src/environments/environment';
import { WindowService } from '../window/window.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ServiceRequestService {

  serviceRequestTable: string = "ServiceRequests";
  serviceRequestTypes: any[];
  serviceRequestStatus: any[];

  constructor(private windowService: WindowService) { 
    this.serviceRequestStatus = [
      {
        value: "Plannen",
        description: "Plannen",
        iconSource: "bi bi-card-checklist",
        color: "#de9d87",
        id: 1
      },
      {
        value: "Gepland",
        description: "Gepland",
        iconSource: "bi bi-card-checklist",
        color: "#87dede",
        id: 2
      },
      {
        value: "Voorbereid",
        description: "Voorbereid",
        iconSource: "bi bi-card-checklist",
        color: "#c8de87",
        id: 3
      },
      {
        value: "Uitgevoerd",
        description: "Uitgevoerd",
        iconSource: "bi bi-card-checklist",
        color: "#de87de",
        id: 4
      },
      {
        value: "Afgehandeld",
        description: "Afgehandeld",
        iconSource: "bi bi-card-checklist",
        color: "#87de9d",
        id: 5
      }
    ];

    this.serviceRequestTypes = [
      {
        value: "Periodiek onderhoud",
        description: "Periodiek onderhoud",
        iconSource: "bi bi-wrench-adjustable-circle",
        color: "#ff99ff",
        id: 1
      },
      {
        value: "Onderhoud",
        description: "Onderhoud",
        iconSource: "bi bi-wrench-adjustable-circle",
        color: "#ff99ff",
        id: 2
      },
      {
        value: "Reparatie",
        description: "Reparatie",
        iconSource: "bi bi-wrench-adjustable-circle",
        color: "#ff99ff",
        id: 3
      },
      {
        value: "Vervangen onderdelen",
        description: "Vervangen onderdelen",
        iconSource: "bi bi-wrench-adjustable-circle",
        color: "#ff99ff",
        id: 4
      },
      {
        value: "Oplevering",
        description: "Oplevering",
        iconSource: "bi bi-wrench-adjustable-circle",
        color: "#ff99ff",
        id: 5
      },
      {
        value: "Reiniging",
        description: "Reiniging",
        iconSource: "bi bi-wrench-adjustable-circle",
        color: "#ff99ff",
        id: 6
      },
      {
        value: "Plaatsen",
        description: "Plaatsen",
        iconSource: "bi bi-wrench-adjustable-circle",
        color: "#ff99ff",
        id: 7
      },
      {
        value: "Overig",
        description: "Overig",
        iconSource: "bi bi-wrench-adjustable-circle",
        color: "#ff99ff",
        id: 8
      }
    ];
  }

  /**
   * This method will retrieve all ServiceRequests of the current team
   */
  async getServiceRequestsAndWindows() {
    var resultCount = 50;
    var serviceRequestList: ServiceRequest[] = [];
    var lastId: string | undefined = undefined;
    var serviceRequests: Models.DocumentList<ServiceRequest>;

    while (resultCount == 50) {
      if(lastId){
        serviceRequests = await Api.database.listDocuments<ServiceRequest>(
          environment.database,
          this.serviceRequestTable,
          [Query.limit(50), Query.cursorAfter(lastId)]
        );
      } else {
        serviceRequests = await Api.database.listDocuments<ServiceRequest>(
          environment.database,
          this.serviceRequestTable,
          [Query.limit(50)]
        );
      }

      // Retrieve all Windows of the ServiceRequest
      await Promise.all(
        serviceRequests.documents.map((s) => {
          return this.windowService
            .getAllWindowsOfServiceRequest(s.$id)
            .then((windows) => {
              windows.forEach(w => w.parentRequest = s);
              windows.forEach(w => w.endTime = moment(w.endTime).toDate());
              windows.forEach(w => w.displayName = "Inzet");
              windows.forEach(w => w.startTime = moment(w.startTime).toDate());
              windows.forEach(w => w.assignedResources = w.assignedResourceIds?.split(" "));
              s.windows = windows;
            });
        })
      );

      lastId = serviceRequests?.documents[serviceRequests.documents.length - 1]?.$id;
      serviceRequestList.push(...serviceRequests.documents);
      resultCount = serviceRequests.documents.length;
    }

    return serviceRequestList;
  }

  /**
    * This method will create a new ServiceRequest in the database
  */
  addServiceRequest(serviceRequest: ServiceRequest, teamId: string, writePermissions: string[]) {
    return Api.database.createDocument(environment.database, this.serviceRequestTable, ID.unique(), this.createPartialServiceRequest(serviceRequest))
  }

  /**
   * This method will update an existing ServiceRequest in the Database
  */
  updateServiceRequest(serviceRequest: ServiceRequest) {
    return Api.database.updateDocument(environment.database, this.serviceRequestTable, serviceRequest.$id, this.createPartialServiceRequest(serviceRequest));
  }

  /**
   * This method will delete an existing ServiceRequest from the database
  */
  deleteServiceRequest(serviceRequest: ServiceRequest) {
    return Api.database.deleteDocument(environment.database, this.serviceRequestTable, serviceRequest.$id);
  }

  /**
   * Creates a partial instance of the ServiceRequest without AppWrite properties
   * @param vehicle 
   * @returns 
  */
  private createPartialServiceRequest(serviceRequest: ServiceRequest): Partial<ServiceRequest> {
    if (!serviceRequest) {
      return {};
    }

    return {
      name: serviceRequest.name,
      status: serviceRequest.status,
      type: serviceRequest.type,
      orderId: serviceRequest.orderId,
      createdDate: serviceRequest.createdDate,
      startTime: serviceRequest.startTime,
      endTime: serviceRequest.endTime,
      streetName: serviceRequest.streetName,
      zipCode: serviceRequest.zipCode,
      city: serviceRequest.city,
      houseNumber: serviceRequest.houseNumber,
      requestSummary: serviceRequest.requestSummary,
      country: serviceRequest.country,
      createdBy: serviceRequest.createdBy,
      internalNotice: serviceRequest.internalNotice,
      referencedMachineId: serviceRequest.referencedMachineId,
      contactPersonIds: serviceRequest.contactPersonIds
    };
  }

  /**
   * This method will return the icon resource based on the status
  */
  getTypeStatusIcon(type: string) {
    if (!type) {
      return;
    }

    var statusResource = this.serviceRequestTypes.filter(s => s.value == type);

    if (!statusResource || statusResource?.length == 0) {
      return;
    }

    return statusResource[0].iconSource;
  }

  /**
   * This method will return the icon resource based on the status
  */
  getStatusIcon(type: string) {
    if (!type) {
      return;
    }

    var statusResource = this.serviceRequestStatus.filter(s => s.value == type);

    if (!statusResource || statusResource?.length == 0) {
      return;
    }

    return statusResource[0].iconSource;
  }
}

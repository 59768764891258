import { Pipe, PipeTransform } from '@angular/core';
import { Mechanic } from '../models/mechanic';

@Pipe({
    name: 'windowsPresent',
    pure: false,
    standalone: true
})
export class WindowsPresentPipe implements PipeTransform {
    transform(items: Mechanic[] | undefined): any {
        if (!items) {
            return items;
        }
        return items.filter(item => (item?.windows?.length ?? 0) > 0);
    }
}
<div class="row tool-create" *ngIf="tool">
    <div class="col card">
        <div class="card-body">
            <h4>Nieuw Gereedschap</h4>
            <div class="row custom-attributes-row">
                <div class="col">
                    <h5>Gereedschapsgegevens</h5>
                    <p>Gebruik onderstaande invoervelden om alle gegevens van het aan te maken gereedschap op te geven.</p>
                                       
                    <nav class="nav-tabs-wrapper">
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                          <button class="nav-link active" id="nav-tool-value-tab" data-bs-toggle="tab" data-bs-target="#nav-tool-values" type="button" role="tab" aria-controls="nav-tool-values" aria-selected="true">Gereedschapgegevens</button>
                          <button class="nav-link" id="nav-inspection-tab" data-bs-toggle="tab" data-bs-target="#nav-inspection" type="button" role="tab" aria-controls="nav-inspection" aria-selected="false">Periodieke controle</button>
                          <button class="nav-link" id="nav-attached-tab" data-bs-toggle="tab" data-bs-target="#nav-attached" type="button" role="tab" aria-controls="nav-attached" aria-selected="false">Gekoppeld aan</button>
                        </div>
                    </nav>
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-tool-values" role="tabpanel" aria-labelledby="nav-tool-values-tab"> 
                            <div class="form-group">
                                <label for="inputName">Naam:</label>
                                <input type="text" class="form-control" id="inputName" placeholder="Naam..."  [(ngModel)]="tool.name">
                            </div>
                            <div class="form-group">
                                <label for="inputBrand">Merk:</label>
                                <input type="text" class="form-control" id="inputBrand" placeholder="Merk..."  [(ngModel)]="tool.brand">
                            </div>
                            <div class="form-group">
                                <label for="inputType">Type:</label>
                                <select [(ngModel)]="tool.type" class="form-control" name="toolTypeDropdown" id="inputType">
                                    <option *ngFor="let type of toolService.toolTypes; let i = index" value="{{type}}">
                                        {{type}} 
                                    </option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="inputPurchaseDate">Aanschafdatum:</label>
                                <input type="date" class="form-control" id="inputPurchaseDate" [(ngModel)]="tool.purchaseDate">
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-inspection" role="tabpanel" aria-labelledby="nav-inspection-tab"> 
                            <div class="form-group">
                                <label for="inputCheckBefore">Volgende keuring voor:</label>
                                <input type="date" class="form-control" id="inputCheckBefore" [ngModel]="tool.checkBeforeDate | date: 'yyyy-MM-dd'" (ngModelChange)="onCheckBeforeDateValuesChanged($event)">
                            </div>
                            <div class="form-group">
                                <label for="inputInspectionOnDate">Geplande keuring op:</label>
                                <input type="date" class="form-control" id="inputInspectionOnDate" [ngModel]="tool.scheduledInspectionDate | date: 'yyyy-MM-dd'" (ngModelChange)="onInspectionScheduledDateValuesChanged($event)">
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-attached" role="tabpanel" aria-labelledby="nav-attached-tab"> 
                            <p class="attach-introduction">Koppel middels onderstaande lijst een voertuig of locatie aan dit gereedschap.</p>
                            <div class="form-group">
                                <label for="attachedToInput">Gekoppeld Voertuig/Locatie:</label>
                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text"><i class="bi bi-link-45deg"></i></div>
                                      </div>
                                      <select [(ngModel)]="tool.attachedToId" class="form-control" name="toolAttachedToDropdown" id="attachedToInput">
                                          <option *ngFor="let attachableObject of attachableObjects; let i = index" value="{{attachableObject}}">
                                              {{getObjectName(attachableObject)}} 
                                          </option>
                                      </select>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <h5>Beschikbaarheid</h5>
                    <p>Beheer met onderstaand component de globale beschikbaarheid van het gereedschap. Bijvoorbeeld wanneer een gereedschap is afgekeurd of is aangeboden ter reparatie.</p>
                    <div class="form-group">
                        <label for="toolStatusInput">Status:</label>
                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="{{toolService.getStatusIcon(tool.available)}}"></i></div>
                              </div>
                              <select [(ngModel)]="tool.available" class="form-control" name="toolStatusDropdown" id="toolStatusInput">
                                  <option *ngFor="let statusItem of toolService.toolStatusItems; let i = index" value="{{statusItem.value}}">
                                      {{statusItem.description}} 
                                  </option>
                              </select>
                          </div>
                    </div>
                    <div class="form-group">
                        <label for="toolStatusMessageInput">Aanvullende Informatie:</label>
                        <textarea [(ngModel)]="tool.additionalMessage" rows="6" class="form-control" id="toolStatusMessageInput"></textarea>
                    </div>
                    <div class="form-actions">
                        <button class="btn btn-success" (click)="createTool()">Gereedschap aanmaken</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
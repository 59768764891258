@if(machine){
    <div [ngClass]="{'row mt-3': !serviceRequest}">
        <div [ngClass]="{'card': !serviceRequest}">
            <div [ngClass]="{'card-body': !serviceRequest}">
                @if(!serviceRequest){
                    @if(machine.$id){
                        <h4>Machine {{machine.machineId}} wijzigen</h4>
                        <p>Gebruik onderstaand component om de geselecteerde machine te wijzigen</p>
                    } @else {
                        <h4>Machine aanmaken</h4>
                        <p>Gebruik onderstaand component om een nieuwe machine aan te maken</p>
                    }
                }
                <div class="form">
                    @if(serviceRequest){
                        <div class="row mt-1">
                            <div class="form-group col">
                                <label for="contactPersonRoleSelect">Klant:</label>
                                <select class="form-select" id="contactPersonRoleSelect" [(ngModel)]="machine.relatedCompanyId" (ngModelChange)="relatedCompanyChanged()">
                                    @if(machine.relatedCompanyId){
                                        <option value="">Niet gekoppeld</option>
                                    } @else {
                                        <option value="undefined">Niet gekoppeld</option>
                                    }
                                    @for(company of companies; track company.$id){
                                    <option value="{{company.$id}}">
                                        {{company.companyName}} - ({{company.customerRelationId}})
                                    </option>
                                  }
                                </select>
                            </div>
                            <div class="form-group col-auto d-flex align-items-end">
                                <button class="btn btn-secondary" [disabled]="machine.relatedCompanyId" (click)="createNewCompany()" [disabled]="!machine.machineId"><i class="bi bi-plus-square"></i> Nieuwe klant</button>
                            </div>
                        </div>
                    }
                    <div class="row" [ngClass]="{'mt-2': serviceRequest}">
                        <div class="form-group" [ngClass]="{'col-2': !serviceRequest, 'col-5' : serviceRequest}">
                            <label for="inputMachineNumber">Machinenummer:</label>
                            <input type="text" class="form-control" id="inputMachineNumber" placeholder="Machinenummer..."  [(ngModel)]="machine.machineId">
                        </div>
                        <div class="form-group col">
                            <label for="inputMachineName">Naam:</label>
                            <input type="text" class="form-control" id="inputMachineName" placeholder="Machine naam..."  [(ngModel)]="machine.name">
                        </div>
                        @if(!serviceRequest){
                            <div class="form-group col-5">
                                <label for="contactPersonRoleSelect">Klant:</label>
                                <select class="form-select" id="contactPersonRoleSelect" [(ngModel)]="machine.relatedCompanyId" (ngModelChange)="relatedCompanyChanged()">
                                    <option value="undefined">Niet gekoppeld</option>
                                    @for(company of companies; track company.$id){
                                    <option value="{{company.$id}}">
                                        {{company.companyName}} - ({{company.customerRelationId}})
                                    </option>
                                  }
                                </select>
                            </div>
                        }
                    </div>
                    <div class="row mt-2">
                        <div class="form-group" [ngClass]="{'col-4': !serviceRequest, 'col-12' : serviceRequest}">
                            <label for="inputStreet">Straatnaam:</label>
                            <input type="text" class="form-control" id="inputStreet" placeholder="Straatnaam..."  [(ngModel)]="machine.streetName">
                        </div>
                        <div class="form-group col">
                            <label for="inputNumber">Huisnummer:</label>
                            <input type="text" class="form-control" id="inputNumber" placeholder="Huisnummer..." [(ngModel)]="machine.houseNumber">
                        </div>
                        <div class="form-group col-5">
                            <label for="inputZipcode">Postcode:</label>
                            <input type="text" class="form-control" id="inputZipcode" placeholder="Postcode..." [(ngModel)]="machine.zipCode">
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="form-group col">
                            <label for="inputCity">Plaatsnaam:</label>
                            <input type="text" class="form-control" id="inputCity" placeholder="Plaatsnaam..." [(ngModel)]="machine.city">
                        </div>
                        <div class="form-group col">
                            <label for="inputCountry" for="machineCounteryInput">Land:</label>
                            <div class="input-group mb-2">
                                <div class="input-group-prepend">
                                    <div class="input-group-text"><i class="bi bi-flag"></i></div>
                                </div>
                                <select [(ngModel)]="machine.country" class="form-select" name="machineCountery" id="machineCounteryInput">
                                @for(country of contextService.countries; track country){
                                    <option value="{{country.name}}">
                                            {{country.name}} 
                                    </option>
                                }  
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col d-flex justify-content-end action-buttons">
                            @if(!serviceRequest){
                                <button class="btn btn-secondary close-button" (click)="close()"><i class="bi bi-x-lg"></i>Sluiten</button>
                            }
                            @if(machine.$id){
                                <button class="btn btn-success" [disabled]="!machine.machineId" (click)="updateMachine()"><i class="bi bi-save"></i>Machine updaten</button>
                            } @else {
                                <button class="btn btn-success" [disabled]="!machine.machineId" (click)="updateMachine()"><i class="bi bi-save"></i>Machine aanmaken</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { CommunicationService } from '../communication/communication.service';
import { Models, Permission, Query, Role } from 'appwrite';
import { UserPreferences } from 'src/app/models/services/user-preferences';
import { TeamService } from '../team/team.service';
import { Api } from 'src/app/helpers/api';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  currentUser?: Partial<Models.User<UserPreferences>>; 
  currentMembership?: Models.Membership;
  activeTeamId?: string;

  permissionMatrix: any;
  currentUserRole: string;
  currentUserPermissions: string[]

  constructor(private authService: AuthService, private teamService: TeamService, private communicationService: CommunicationService) {
    this.currentUserRole = ""
    this.currentUserPermissions = []
  }
  async ngOnInit() {
    this.authService.user.subscribe(user => {
      this.currentUser = user;
      this.updateBaseValues();
    });

    this.communicationService.activeTeamId?.subscribe(teamId => {
      this.activeTeamId = teamId;
      this.updateBaseValues();
    })

   }
  async buildPermissions() {

    await this.getUserRole()

    // Retrieve Matrix
    await Api.teams.getPrefs('66599e92000a2b5dfbf9').then(prefs => {
      if (!prefs) {
        console.log("PermissionBuilder: No Prefs")
      }
        let pMatrix = prefs['permissions']
        this.permissionMatrix = JSON.parse(pMatrix)

        this.currentUserRole = this.currentMembership?.roles[0] || ""
        this.currentUserPermissions = this.permissionMatrix[this.currentUserRole]
    })
    

  }

  async checkPermissionFor(mod: string): Promise<boolean> {
    if(!this.permissionMatrix) {
      // This happens because of the RouteGuard checking before app is ready.
      console.log("PMatrix Fetching Directly")
      return this.getPermissionFromDatabase(mod);
    }
    return this.currentUserPermissions.indexOf(mod) != -1
  }

  async getUserRole(): Promise<string | undefined> {
    let userState = this.authService.user.value
    if(!userState.$id) {
      console.log("GetUserRole: No user logged in")
      return
    }

    return Api.teams.listMemberships('66599e92000a2b5dfbf9', [Query.equal('userId', userState.$id)]).then(memberships => {
      
        this.currentMembership = memberships.memberships[0];
        this.currentUserRole = this.currentMembership?.roles[0] || ""

        return this.currentUserRole;
    });
  }

  /**
   * This method will get the current membership based on the user and active team id
   */
  updateBaseValues(){
    if(this.currentUser && this.activeTeamId){
      this.teamService.getMembership(this.activeTeamId, this.currentUser.$id!).then(membership => {
        this.currentMembership = membership;

        // Note: Permissions do not refresh realtime
        this.currentUserRole = this.currentMembership?.roles[0] || ""
        this.currentUserPermissions = []
        if(this.currentUserRole != "") {
          this.currentUserPermissions = this.permissionMatrix[this.currentUserRole]
        }

      })
    } else {
      this.currentMembership = undefined;
    }
    
  }

  /**
   * This method will return a list of valid write permissions based on the current membership
   * @returns 
   */
  getWritePermissions(){
    if(!this.currentMembership){
      this.updateBaseValues();
      
      if(!this.currentMembership){
        return [];
      }
    }

    var permissions: string[] = []
    this.currentMembership.roles?.forEach(role => {
        if(role != "viewer" && role != "owner"){
          permissions.push(Permission.write(Role.team(this.currentMembership!.teamId, role)));
        }
    });

    return permissions;
  }

  // Guard wants to access async, before all is loaded in.
  async getPermissionFromDatabase(mod: string) {
    // Not too fancy fix
    return Api.account.get().then(async userState => {

      return Api.teams.listMemberships('66599e92000a2b5dfbf9', [Query.equal('userId', userState.$id)]).then(memberships => {
        
          this.currentMembership = memberships.memberships[0];
          this.currentUserRole = this.currentMembership?.roles[0] || ""

          return Api.teams.getPrefs('66599e92000a2b5dfbf9').then(prefs => {
            if (prefs) {
              let pMatrix = prefs['permissions']
              this.permissionMatrix = JSON.parse(pMatrix)
              
              if(this.currentUserRole != "") {
                this.currentUserPermissions = this.permissionMatrix[this.currentUserRole]
              }
              return <boolean>(this.currentUserPermissions.indexOf(mod) != -1)
            }
            console.log("No Prefs")
            return false
          })
          // End Prefs matrix
      });
      // End Team Listmembership
    })
    // End Account Get Userstate
  }
}

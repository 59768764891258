import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ServiceRequest } from 'src/app/models/service-request';
import { Unavailability } from 'src/app/models/unavailability';
import { Window } from 'src/app/models/window';

@Injectable({
  providedIn: 'root'
})
export class PlannerService {

  windowStatus = [
    {
      value: "Beschikbaar",
      color: "#20ac6b"  
    },
    {
      value: "Op aanvraag",
      color: "#cc66ff"  
    },
    {
      value: "Niet beschikbaar",
      color: "#ff3333"  
    },      
    {
      value: "Onbeschikbaar",
      color: "#ff3333"  
    },
    {
      value: "Inzet",
      color: "#ff9966"  
    },
    {
      value: "Inzet (Huidige Serviceverzoek)",
      color: "#8c66ff"  
    },
  ];

  constructor() { }

 /**
 * This method will get the weeknumber for the given date
 * @param inputDate 
 * @returns 
 */
  getWeekNumber(inputDate: Date) {
    var weeknumber = moment(inputDate).week();
    return weeknumber;
  }

  /**
   * This method will get the monday from the given date
   * @param inputDate 
   * @returns 
  */
  getMonday(inputDate: Date) {
    var day = inputDate.getDay(),
        diff = inputDate.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
    return new Date(inputDate.setDate(diff));
  }

  /**
   * This method will return a sorted list of Windows attached to the given mechanic id
   */
  getSortedWindowsOnThisDay(serviceRequests: ServiceRequest[], scheduleDate: Date, mechanicId: string | undefined){
    // Gather all windows on this date
    if(mechanicId){
      var attachedServiceWindows = serviceRequests.flatMap(r => (r.windows ?? []).filter(w => (w.assignedResources?.filter(r => r == mechanicId).length) ?? 0 > 0));
      var windowsOnThisDay = attachedServiceWindows.filter(w => {
        if(w){
          return moment(scheduleDate).isSame(moment(w.startTime), 'day') || moment(scheduleDate).isSame(moment(w.endTime), 'day') || moment(scheduleDate).isBetween(moment(w.startTime), moment(w.endTime), 'day');
        }
        return false;
      }) ?? [];
      
      // Sort by Startdate
      return windowsOnThisDay.sort((a,b) => (a!.startTime > b!.startTime) ? 1 : ((b!.startTime > a!.startTime) ? -1 : 0));
    }

      var attachedServiceWindows = serviceRequests.flatMap(r => (r.windows ?? []));
      var windowsOnThisDay = attachedServiceWindows.filter(w => {
        if(w){
          return moment(scheduleDate).isSame(moment(w.startTime), 'day') || moment(scheduleDate).isSame(moment(w.endTime), 'day') || moment(scheduleDate).isBetween(moment(w.startTime), moment(w.endTime), 'day');
        }
        return false;
      }) ?? [];

      // Sort by Startdate
      return windowsOnThisDay.sort((a,b) => (a!.startTime > b!.startTime) ? 1 : ((b!.startTime > a!.startTime) ? -1 : 0));
    }

  /**
   * This method will return a sorted list of Windows attached to the given mechanic id
  */
  getSortedUnavailabilitiessOnThisDay(unavailabilities: Unavailability[], scheduleDate: Date){
    // Gather all unavailabilties on this date
    var unavailabilitiesOnThisDay = unavailabilities?.filter(u => {
      u.slotType = "unavailability";

      if(u){
        return (moment(scheduleDate).isBetween(moment(u.startTime), moment(u.endTime), 'day')) || moment(scheduleDate).isSame(moment(u.startTime), 'day') || moment(scheduleDate).isSame(moment(u.endTime), 'day');
      }
      return false;
    }) ?? [];

    // Sort by Startdate
    return unavailabilitiesOnThisDay.sort((a,b) => (a!.startTime > b!.startTime) ? 1 : ((b!.startTime > a!.startTime) ? -1 : 0));
  }

  /**
   * This method will add a Window in front of the given window if there is any space left in the schedule
   */
  addAvailableWindowInFront(currentWindowStartTime: Date, hours: any[], windows: Window[], status: string): boolean{
    var minutesBetween = moment(currentWindowStartTime).diff(moment(currentWindowStartTime).startOf('day'), 'minutes');
    var hoursFromMidnight = hours[0].hoursFromMidnight;
    var spaceInFront = minutesBetween - (hoursFromMidnight * 60);
    var endIndex = hours.length - 1;

    if(hours.filter(h => h.name == moment(currentWindowStartTime).format('HH:mm')).length > 0){
      endIndex = hours.indexOf(hours.filter(h => h.name == moment(currentWindowStartTime).format('HH:mm'))[0]);
    }

    // Push window in front if there is any space in the schedule
    if(spaceInFront > 0){
      windows.push({
        $id: "",
        $collectionId: "",
        $createdAt: "",
        $databaseId: "",
        $permissions: [],
        $updatedAt: "",
        startTime: moment(currentWindowStartTime).subtract(spaceInFront, 'minutes').toDate(),
        endTime: moment(currentWindowStartTime).toDate(),
        displayName: status,
        backgroundColor: this.getStatusColor(status) ,
        startCol: 1,
        colsLength: endIndex,
      });
      return true;
    }

    return false;
  }

  /**
   * This method will add an Available Window in rear if there is any space left till end of the Schedule
   */
  addAvailableWindowInRear(currentWindowEndTime: Date, hours: any[], windows: Window[], status: string){
    var minutesBetween = moment(currentWindowEndTime).diff(moment(currentWindowEndTime).endOf('day'), 'minutes') - 1;
    var hoursToMidnight = hours[hours.length - 1].hoursToMidnight;
    var spaceInRear = Math.abs(minutesBetween) - (hoursToMidnight * 60);
    var startIndex = hours.indexOf(hours.filter(h => h.name == moment(currentWindowEndTime).format('HH:mm'))[0])

    // Push window in rear if there is any space in the schedule
    if(spaceInRear > 0){
      windows.push({
        $id: "",
        $collectionId: "",
        $createdAt: "",
        $databaseId: "",
        $permissions: [],
        $updatedAt: "",
        startTime: moment(currentWindowEndTime).toDate(),
        endTime: moment(currentWindowEndTime).add(spaceInRear, 'minutes').toDate(),
        displayName: status,
        backgroundColor: this.getStatusColor(status),

        startCol: startIndex + 1,
        colsLength: hours.length - 1 - startIndex,
      });
    }
  }

  /**
   * This method will Add an Available window in between two other Windows
   */
  addAvailableWindowInBetween(currentWindowEndTime: Date, nextWindowStartTime: Date, hours: any[], windows: Window[], status: string){
    var minutesBetween = moment(nextWindowStartTime).diff(moment(currentWindowEndTime), 'minutes');
    var startIndex = hours.indexOf(hours.filter(h => h.name.includes(moment(currentWindowEndTime).hours()))[0])
    var endIndex = hours.indexOf(hours.filter(h => h.name.includes(moment(nextWindowStartTime).hours()))[0])

    // Push window in rear if there is any space in the schedule
    if(minutesBetween > 0){
      windows.push({
        $id: "",
        $collectionId: "",
        $createdAt: "",
        $databaseId: "",
        $permissions: [],
        $updatedAt: "",
        startTime: moment(currentWindowEndTime).toDate(),
        endTime: moment(nextWindowStartTime).toDate(),
        displayName: status,
        backgroundColor: this.getStatusColor(status),
        
        startCol: startIndex + 1,
        colsLength: endIndex - startIndex,
      });
    }
  }

  
  /**
   * This method will return the correct color based on the given status
   * @param status 
   */
  getStatusColor(status: string) : string {
    return this.windowStatus.filter(s => s.value == status)[0]?.color ?? "yellow"
  }
}

@if(travelComponent){
    <div class="col travel-component">
        <h5><i class="{{travelComponentService.getStatusIcon(travelComponent.type)}}"></i>{{travelComponent.type}}</h5>
        <div class="row">
            <div class="form-group col-4">
                <label for="inputName">Naam:</label>
                <input type="text" class="form-control" id="inputName" [(ngModel)]="travelComponent.name">
            </div>
            <div class="form-group col">
                <label>Aanvullende Informatie:</label>
                <textarea class="form-control request-summary" rows="3" [(ngModel)]="travelComponent.additionalMessage"></textarea>
            </div>
        </div>
        <div class="col">
            <div class="row attached-documents">
                <div class="col-3 new-documents">
                    <div class="form-group">
                        <label for="uploadNewDocument">Upload reis-document</label>
                        <input type="file" class="form-control-file" id="uploadNewDocument"  
                        (change)="uploadFile($event)"
                        accept=".jpg,.png,.pdf"
                        max="1">
                      </div>
                </div>
                @for(document of travelComponent.documents; track document.name){
                    <div class="col">
                        <div class="existing-documents document" (click)="downloadFile(document)">
                            <i class="bi bi-trash remove-file" (click)="removeFile(document)"></i>
                            @switch (document.type.toLowerCase()) {
                                @case ('pdf'){
                                    <i class="bi bi-file-pdf"></i>
                                }
                                @case ('zip'){
                                    <i class="bi bi-file-earmark-zip"></i>
                                }
                                @case ('docx'){
                                    <i class="bi bi-file-word"></i>
                                }
                            }
                            <span>{{document.name}}</span>
                        </div>
                    </div>
                }
            </div>
        </div>
        <!-- Hotel -->
        @if(travelComponent.hotelInformation){
        <div>
            <div class="row">
                <div class="form-group col-4">
                    <label for="inputHotelName">Hotelnaam:</label>
                    <input type="text" class="form-control" id="inputHotelName" placeholder="Hotelnaam..." [(ngModel)]="travelComponent.hotelInformation.name">
                </div>
                <div class="form-group col">
                    <label for="inputHotelPhonenumber">Telefoonnummer:</label>
                    <input type="text" class="form-control" id="inputHotelPhonenumber" placeholder="06..." [(ngModel)]="travelComponent.hotelInformation.phoneNumber">
                </div>
                <div class="form-group col-3">
                    <label for="inputHotelPhonenumber">Parkeerplaats Inbegrepen:</label>
                    <select class="form-control" id="inputHotelPhonenumber" [(ngModel)]="travelComponent.hotelInformation.parkingIncluded"> 
                        <option value="true">Ja</option>
                        <option value="false">Nee</option>
                    </select>
                </div>
            </div>
            <div class="row">
            <div class="form-group col-4">
                <label for="inputHotelStreet">Straatnaam:</label>
                <input type="text" class="form-control" id="inputHotelStreet" placeholder="Straatnaam..."  [(ngModel)]="travelComponent.hotelInformation.streetName">
            </div>
            <div class="form-group col">
                <label for="inputHotelNumber">Huisnummer:</label>
                <input type="text" class="form-control" id="inputHotelNumber" placeholder="Huisnummer..."  [(ngModel)]="travelComponent.hotelInformation.houseNumber">
            </div>
            <div class="form-group col-3">
                <label for="inputHotelZipcode">Postcode:</label>
                <input type="text" class="form-control" id="inputHotelZipcode" placeholder="Postcode..."  [(ngModel)]="travelComponent.hotelInformation.zipCode">
            </div>
        </div>
        <div class="row">
            <div class="form-group col-4">
                <label for="inputHotelCity">Plaatsnaam:</label>
                <input type="text" class="form-control" id="inputHotelCity" placeholder="Plaastnaam..."  [(ngModel)]="travelComponent.hotelInformation.city">
            </div>
            <div class="form-group col">
                <label for="inputHotelCountry">Land:</label>
                <input type="text" class="form-control" id="inputHotelCountry" placeholder="Land..."  [(ngModel)]="travelComponent.hotelInformation.country">
            </div>
        </div>
        </div>
        }
       <div class="row">
            <div class="col action-buttons">
                @if(showConfirmationButtons){
                    <button class="btn btn-danger" (click)="deleteTravelComponent()">Bevestigen</button>
                    <button class="btn btn-secondary" (click)="cancelRemoval()">Annuleren</button>
                } @else {
                    <button class="btn btn-danger" (click)="toggleConfirmationButtons()"><i class="bi bi-trash"></i></button>
                }
            </div>
       </div>
    </div>
}
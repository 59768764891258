import { Role } from "./roleEnum"

export enum Department {
    engineering = 'Engineering',
    elektro = 'Elektro',
    inkoop = 'Inkoop',
    bedrijfsbureau = 'Bedrijfsbureau',
    plaatwerkerij = 'Plaatwerkerij',
    machinebouw = 'Machinebouw',
    kwaliteit = 'Kwaliteit',
    service = 'Service',
    magazijn = 'Magazijn',
    finance = 'Finance',
    verkoop = 'Verkoop',
    ict = 'ICT',
    operations = 'Operations',
    generalmanagement = 'General Management'
}

export type DepartmentStrings = `${Department}`

export const getDepartmentFromRole = (role: Role) => {
    // Must be if/else because of the ||
    
    if(role == Role.manager_engineering || role == Role.engineer) {
        return Department.engineering
    } else if (role == Role.manager_elektro || role == Role.elektromonteur) {
        return Department.elektro
    } else if (role == Role.manager_inkoop) {
        return Department.inkoop
    } else if (role == Role.project_manager) {
        return Department.bedrijfsbureau
    } else if (role == Role.teamleider_plaatwerkerij || role == Role.plaatwerker || role == Role.draaier_freezer) {
        return Department.plaatwerkerij
    } else if (role == Role.productie_manager || role == Role.machinebouwer) {
        return Department.machinebouw
    } else if (role == Role.medewerker_kwaliteit) {
        return Department.kwaliteit
    } else if (role == Role.service_manager || role == Role.service_coordinator || role == Role.servicemonteur) {
        return Department.service
    } else if (role == Role.medewerker_magazijn) {
        return Department.magazijn
    } else if (role == Role.manager_finance || role == Role.administratief_medewerkster) {
        return Department.finance
    } else if (role == Role.sales_director || role == Role.sales_consulatant || role == Role.sales_marketing_medewerker) {
        return Department.verkoop
    } else if (role == Role.medewerker_ict) {
        return Department.ict
    } else if (role == Role.operations_director) {
        return Department.operations
    } else if (role == Role.general_manager) {
        return Department.generalmanagement
    } else {
        return 'Anders'
    }

}
<div class="container-fluid p0 sign-in-component">
    <div class="row-section">
        <div class="col brand-side">
            <div class="blur">

            </div>
        </div>
        <div class="col-4 sign-in-wrapper container-fluid p0">
            <div class="brand-wrapper row">
                <img src="assets/unikon-logo-fullcolor.svg">
            </div>
            <div class="row">
                <div class="col">
                    <form class="login-form" [formGroup]="form" (ngSubmit)="signIn()">
                        <h3>Inloggen</h3>
                        <div class="form-group form-wrapper">
                            <label for="email-adress">E-mailadres</label>
                            <input required type="email" formControlName="email" #email class="form-control" id="email-adress" placeholder="Uw E-mailadres">
                            <small *ngIf="showEmailWarning" id="emailHelp" class="form-text text-muted">{{emailWarningMessage}}</small>
                        </div>
                        <div class="form-group form-wrapper">
                            <label for="password-input">Wachtwoord</label>
                            <input required type="password" formControlName="password" #password class="form-control" id="password-input" placeholder="Wachtwoord">
                            <small *ngIf="showPasswordWarning" id="passwordHelp" class="form-text text-muted">{{passwordWarningMessage}}</small>
                        </div>
                        <button type="submit" class="btn btn-primary login-button">Log In</button>
                    </form>
                    <div class="extra-actions">
                        <button class="btn btn-primary" routerLink="/password-recovery">Wachtwoord vergeten</button>
                        <button class="btn btn-primary primary-action" routerLink="/register">Registreren</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row vehicle-details" *ngIf="vehicle">
    <div class="col card">
        <div class="card-body">
            <h4>Beheer {{vehicle.name}}</h4>
            <div class="row custom-attributes-row">
                <div class="col">
                    <h5>Voertuig details</h5>
                    <p>Beheer met onderstaand component de voertuig details van {{vehicle.name}}.</p>
                    <nav class="nav-tabs-wrapper">
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                          <button class="nav-link active" id="nav-vehicle-value-tab" data-bs-toggle="tab" data-bs-target="#nav-vehicle-values" type="button" role="tab" aria-controls="nav-vehicle-values" aria-selected="true">Voertuiggegevens</button>
                          <button class="nav-link" id="nav-gas-maintenance-tab" data-bs-toggle="tab" data-bs-target="#nav-gas-maintenance" type="button" role="tab" aria-controls="nav-gas-maintenance" aria-selected="false">Tankpas & Onderhoud</button>
                        </div>
                    </nav>
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-vehicle-values" role="tabpanel" aria-labelledby="nav-vehicle-values-tab"> 
                            <div class="form-group">
                                <label for="inputVehicleName">Voertuignaam:</label>
                                <input type="text" class="form-control" id="inputVehicleName" placeholder="Voertuignaam..."  [(ngModel)]="vehicle.name">
                            </div>
                            <div class="form-group">
                                <label for="inputOwner">Eigenaar:</label>
                                <input type="text" class="form-control" id="inputOwner" placeholder="Eigenaar..."  [(ngModel)]="vehicle.owner">
                            </div>
                            <div class="form-group">
                                <label for="inputBrand">Merk:</label>
                                <input type="text" class="form-control" id="inputBrand" placeholder="Merk..."  [(ngModel)]="vehicle.brand">
                            </div>
                            <div class="form-group">
                                <label for="inputRegistrationNumber">Kenteken:</label>
                                <input type="text" class="form-control" id="inputRegistrationNumber" placeholder="Kenteken..."  [(ngModel)]="vehicle.registrationNumber">
                            </div>
                            <div class="form-group">
                                <label for="inputProductionYear">Productiejaar:</label>
                                <input type="number" class="form-control" id="inputProductionYear" placeholder="Productiejaar..."  [(ngModel)]="vehicle.yearOfProduction">
                            </div>
                            <div class="form-group">
                                <label for="inputType">Voertuigtype:</label>
                                <select [(ngModel)]="vehicle.type" class="form-control" name="vehicleTypeDropdown" id="inputType">
                                    <option *ngFor="let type of vehicleTypes; let i = index" value="{{type}}">
                                        {{type}} 
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-gas-maintenance" role="tabpanel" aria-labelledby="nav-gas-maintenance-tab"> 
                            <div class="form-group">
                                <label for="inputFuelPassNumber">Tankpas nummer:</label>
                                <input type="text" max="100" class="form-control" id="inputFuelPassNumber" placeholder="00000000000"  [(ngModel)]="vehicle.fuelpassNumber">
                            </div>
                            <div class="form-group">
                                <label for="inputFuelPassCode">Pincode Tankpas:</label>
                                <input type="text" max="15" class="form-control" id="inputFuelPassCode" placeholder="0000"  [(ngModel)]="vehicle.fuelpassCode">
                            </div>
                            <div class="form-group">
                                <label for="inputExpirationDateApk">Vervaldatum APK:</label>
                                <input type="date" class="form-control" id="inputExpirationDateApk" [ngModel]="vehicle.expirationDateApk | date:'yyyy-MM-dd'" (ngModelChange)="onApkExpirationDateValuesChanged($event)">
                            </div>
                            <div class="form-group">
                                <label for="inputExpirationDateGreenCard">Vervaldatum groene kaart:</label>
                                <input type="date" class="form-control" id="inputExpirationDateGreenCard" [ngModel]="vehicle.expirationDateGreenCard | date:'yyyy-MM-dd'" (ngModelChange)="onGreenCardExpirationDateValuesChanged($event)">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <h5>Beschikbaarheid</h5>
                    <p>Beheer met onderstaand component de globale beschikbaarheid van {{vehicle.name}}. Dag-specifieke beschikbaarheid kan elders worden ingesteld.</p>
                    <div class="form-group">
                        <label for="vehicleStatusInput">Status:</label>
                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text"><i class="{{vehicleService.getStatusIcon(vehicle.available)}}"></i></div>
                            </div>
                            <select [(ngModel)]="vehicle.available" class="form-control" name="vehicleStatusDropdown" id="vehicleStatusInput">
                                <option *ngFor="let statusItem of vehicleService.vehicleStatusItems; let i = index" value="{{statusItem.value}}">
                                    {{statusItem.description}} 
                                </option>
                            </select>
                          </div>
                    </div>
                    <div class="form-group">
                        <label for="vehicleStatusMessageInput">Aanvullende Informatie:</label>
                        <textarea [(ngModel)]="vehicle.additionalMessage" rows="4" class="form-control" id="vehicleStatusMessageInput"></textarea>
                    </div>
                    <div class="form-actions">
                        <button class="btn btn-success" (click)="saveVehicleChanges()">Wijzigingen opslaan</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { ID, Models, Permission, Query, Role } from 'appwrite';
import { Api } from 'src/app/helpers/api';
import { Vehicle } from 'src/app/models/vehicle';
import { environment } from 'src/environments/environment';
import { UnavailabilityService } from '../unavailability/unavailability.service';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  vehicleStatusItems: any[];
  vehicleTypes: string[];
  vehicleTable: string = "Vehicles";
  
  constructor(private unavailabilityService: UnavailabilityService) {
    this.vehicleTypes = [
      "Bestelwagen",
      "Monteursbus",
      "Personenauto",
      "Vrachtwagen"
    ]

    this.vehicleStatusItems = [
      {
        value: "Beschikbaar",
        description: "Beschikbaar",
        iconSource: "bi bi-check-circle",
        color: "#198754",
        id: 1
      },
      {
        value: "Niet Beschikbaar",
        description: "Niet Beschikbaar",
        iconSource: "bi bi-exclamation-circle",
        color: "#ff3333",
        id: 2
      },
      {
        value: "Onbekend",
        description: "Onbekend",
        iconSource: "bi bi-question-circle",
        color: "#ff6600",
        id: 3
      }
    ];
   }

   /**
    * This method will retrieve all active Vehicles for the current team
    */
   async getVehiclesAndUnavailabilities(){
    var resultCount = 50;
    var resultList: Vehicle[] = [];
    var lastId: string | undefined = undefined;
    var results: Models.DocumentList<Vehicle>;

    while(resultCount == 50){
      if(lastId){
        results = await Api.database.listDocuments<Vehicle>(
          environment.database,
          this.vehicleTable,
          [Query.limit(50), Query.cursorAfter(lastId)]
        );
      } else {
        results = await Api.database.listDocuments<Vehicle>(
          environment.database,
          this.vehicleTable,
          [Query.limit(50)]
        );
      }

      // Retrieve all Unavailabilities
      await Promise.all(results.documents.map(v => {
        return this.unavailabilityService.getUnavailabilitiesOfResource(v.$id).then(unavailabilities => {
          v.unavailability = unavailabilities;
        });
      }));

      lastId = results?.documents[results.documents.length - 1]?.$id;
      resultList.push(...results.documents);
      resultCount = results.documents.length;
    }

    return resultList;
   }

    /**
    * This method will create a new Vehicle in the database
    */
    addVehicle(vehicle: Vehicle, teamId: string, writePermissions: string[]) {
      return Api.database.createDocument(environment.database, this.vehicleTable, ID.unique(), this.createPartialVehicle(vehicle))
    }

    /**
     * This method will update an existing Vehicle in the Database
     */
    updateVehicle(vehicle: Vehicle) {
      return Api.database.updateDocument(environment.database, this.vehicleTable, vehicle.$id, this.createPartialVehicle(vehicle));
    }

    /**
     * This method will delete an existing Vehicle from the database
     */
    deleteVehicle(vehicle: Vehicle) {
      return Api.database.deleteDocument(environment.database, this.vehicleTable, vehicle.$id);
    }
    
    /**
     * This method will return the icon resource based on the status
     */
     getStatusIcon(status: string) {
      if (!status) {
        return;
      }
  
      var statusResource = this.vehicleStatusItems.filter(s => s.value == status);
  
      if (!statusResource || statusResource?.length == 0) {
        return;
      }
  
      return statusResource[0].iconSource;
    }

    /**
     * Creates a partial instance of the Vehicle without AppWrite properties
     * @param vehicle 
     * @returns 
     */
    private createPartialVehicle(vehicle: Vehicle): Partial<Vehicle> {
      if (!vehicle) {
        return {};
      }
  
      return {
        additionalMessage: vehicle.additionalMessage,
        available: vehicle.available,
        brand: vehicle.brand,
        name: vehicle.name,
        owner: vehicle.owner,
        registrationNumber: vehicle.registrationNumber,
        type: vehicle.type,
        yearOfProduction: vehicle.yearOfProduction,
        fuelpassNumber: vehicle.fuelpassNumber,
        fuelpassCode: vehicle.fuelpassCode,
        expirationDateApk: vehicle.expirationDateApk,
        expirationDateGreenCard: vehicle.expirationDateGreenCard
      };
    }
}

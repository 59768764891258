import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MultiSelectChangeEvent } from 'primeng/multiselect';
import { Subscription } from 'rxjs';
import { ServiceRequest } from 'src/app/models/service-request';
import { WVBTask } from 'src/app/models/wvb-task';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { ServiceRequestService } from 'src/app/services/service-request/service-request.service';
import { WindowService } from 'src/app/services/window/window.service';
import { WvbTaskService } from 'src/app/services/wvb-task/wvb-task.service';

@Component({
  selector: 'app-service-request-table',
  templateUrl: './service-request-table.component.html',
  styleUrls: ['./service-request-table.component.scss']
})

export class ServiceRequestTableComponent implements OnInit {
  @Input()
  // This function sets the selected statusses from outside: [selectedStatusCustom]='[{name:"Plannen"}]'
  set selectedStatusCustom(arr: { name: string; }[]) {
    this.selectedStatusses = arr;
  }

  @Input()
  set displayedColumns(cols: string[]) {
    // Check if we did not make programming mistake
    if(!cols.every(c => ['Name','Type','StartDate','Company','Status','WVBTasks','Actions'].indexOf(c) != -1)) {
      console.error("Passed on invalid col name!")
    }
    
    this._displayedCols = cols
  }

  @Input()
  set delBtnsAvailable(avail: boolean) {
    this._delBtnsAvailable = avail
  }

  searchInput: string = "";
  sortedBy?: string;
  prevSort?: string;
  initialSort?: boolean = true;
  sortedAscending?: boolean;
  allStatusses = [{name:"Plannen"},{name:"Gepland"},{name:"Voorbereid"},{name:"Uitgevoerd"},{name:"Afgehandeld"}]
  selectedStatusses = [{name:"Plannen"},{name:"Gepland"},{name:"Voorbereid"},{name:"Uitgevoerd"}];
  loadingServiceRequests?: boolean = false;
  lazyLoadingServiceRequests?: boolean = false;
  paginatedActiveServiceRequests?: [ServiceRequest[]];
  allEstablishmentServiceRequests: ServiceRequest[] = [];
  isViewer?: boolean;
  perPage: number;
  activePage: number;
  activeServiceRequest?: ServiceRequest;
  // For Edit / Delete action, unavailable at WVB
  _delBtnsAvailable: boolean = true;
  _displayedCols: string[] = [];
  allWVBTasks?: WVBTask[];
  router: Router
  sortedByStorageKey: string = "SERVICEREQUEST_ORDERBY";
  perPageStorageKey: string = "SERVICEREQUEST_PERPAGE";

  // The Subscriptions to Unsub on destroy
  subs: Subscription[] = [];

  constructor(public communicationService: CommunicationService, private windowService: WindowService, 
    public serviceRequestService: ServiceRequestService, public wvbTaskService: WvbTaskService, router: Router
  ) { 
    this.perPage = Number(localStorage.getItem(this.perPageStorageKey) ?? 20);
    this.activePage = 0;

    var storedSortedBy = localStorage.getItem(this.sortedByStorageKey)?.split('_') ?? [];
    if(storedSortedBy.length == 2){
      this.sortedBy = storedSortedBy[0];
      this.sortedAscending = storedSortedBy[1] == "true";
    } else {
      this.sortedBy = "start-date"
      this.sortedAscending = true;
    }
    
    this.router = router
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe()
    })
  }

  ngOnInit(): void {
    this.subs.push(

      this.communicationService.activeServiceRequests.subscribe(activeServiceRequestList => {
        if (!activeServiceRequestList) {
          return;
        }
        
        // Retrieve WVBTasks for # tasks only if we want to display them
        if (this._displayedCols.indexOf('WVBTasks') != -1) {
          this.communicationService.activeWVBTasks.subscribe( wvbts => {
            this.allWVBTasks = wvbts 
          })
        }
        
        this.allEstablishmentServiceRequests = activeServiceRequestList;
        
        // Sort if possible
        if(this.sortedBy){
          this.sortServiceRequestsBy(this.sortedBy);
        } else{
          this.paginateActiveServiceRequests();
        }
      })
    )

    // Subscribe to Active Service Request for highlighting row
    this.subs.push(
      this.communicationService.activeServiceRequest.subscribe(asr => {
        this.activeServiceRequest = asr
      })
    )
  }

  onPerPageChanged(){
    localStorage.setItem(this.perPageStorageKey, this.perPage?.toString());
    this.paginateActiveServiceRequests();
  }

  /**
   * This method will search in the present set of ServiceRequests
   * based on the given search input
   * @returns 
   */
  searchServiceRequests(){
    if (!this.searchInput || this.searchInput == "") {
      this.allEstablishmentServiceRequests.forEach(t => t.notMatchingFilter = false);
      this.paginateActiveServiceRequests();
      return;
    }

    this.allEstablishmentServiceRequests.forEach(serviceRequest => {
      var matchingAttributeFound = false;
      if([serviceRequest].filter(
        request =>
          request.referencedMachine?.company?.companyName?.toLowerCase().includes(this.searchInput.toLowerCase()) ||
          request.name?.toLowerCase().includes(this.searchInput.toLowerCase()) ||
          request.orderId?.includes(this.searchInput.toLowerCase()) ||
          request?.referencedMachine?.machineId?.includes(this.searchInput.toLowerCase()) ||
          request.type?.includes(this.searchInput.toLowerCase()) ||
          request.status.toLowerCase().includes(this.searchInput.toLowerCase())).length > 0){
            serviceRequest.notMatchingFilter = false;
            matchingAttributeFound = true;
          }

          if(!matchingAttributeFound){
            serviceRequest.notMatchingFilter = true;
          }
      });

      this.paginateActiveServiceRequests();
  }

  /**
   * This method will set the active ServiceRequest which will be shown in the detail screen
   * @param serviceRequest 
   */
  editServiceRequest(serviceRequest: ServiceRequest){
    this.communicationService.activeServiceRequest?.next(serviceRequest);
     
    // Scroll new-section into view
     setTimeout(() => {
      document.getElementById('edit-service-request')?.scrollIntoView();
    }, 200);
  }

  /**
   * This method will set the active ServiceRequest and jump to WVB
   * @param serviceRequest 
   */
  jumpToWVB(serviceRequest: ServiceRequest){
    this.communicationService.activeServiceRequest?.next(serviceRequest);
    this.router.navigate(['/work-preparation-dashboard'])
    // Scroll new-section into view
    setTimeout(() => {
      document.getElementById('wvbTableModule')?.scrollIntoView();
    }, 200);
  }

  /**
   * This method will set the active ServiceRequest and jump to Planning
   * @param serviceRequest 
   */
  jumpToPlanning(serviceRequest: ServiceRequest){
    this.communicationService.activeServiceRequest?.next(serviceRequest);
    this.router.navigate(['/service-requests-dashboard'])
    // Scroll new-section into view
    setTimeout(() => {
      document.getElementById('edit-service-request')?.scrollIntoView();
    }, 200);
  }

  sortAndToggleOrder(column: string){
    this.sortedAscending = !this.sortedAscending;
    this.sortServiceRequestsBy(column);
  }

  /**
   * This method will sort the set of ServiceRequests ASC or DESC based on the given input
   * @param sortBy 
   */
  sortServiceRequestsBy(column: string){
    this.sortedBy = column;
    this.prevSort = column;

    if (column == "name") {
      if (this.sortedBy == column && this.sortedAscending) {
        this.allEstablishmentServiceRequests.sort(function (a: ServiceRequest, b: ServiceRequest) {
          var textA = b.name?.toUpperCase() +  b.name?.toUpperCase();
          var textB = a.name?.toUpperCase() +  a.name?.toUpperCase();

          if (!textA) {
            textA = "";
          }

          if (!textB) {
            textB = "";
          }

          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.paginateActiveServiceRequests();
        return;
      }

      this.allEstablishmentServiceRequests.sort(function (a: ServiceRequest, b: ServiceRequest) {
        var textA = a.name?.toUpperCase() +  a.name?.toUpperCase();
        var textB = b.name?.toUpperCase() +  b.name?.toUpperCase();

        if (!textA) {
          textA = "";
        }

        if (!textB) {
          textB = "";
        }

        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      this.sortedBy = column;
    } else if (column == "type") {
      if (this.sortedBy == column && this.sortedAscending) {
        this.allEstablishmentServiceRequests.sort(function (a: ServiceRequest, b: ServiceRequest) {
          var textA = b.type?.toUpperCase();
          var textB = a.type?.toUpperCase();

          if (!textA) {
            textA = "";
          }

          if (!textB) {
            textB = "";
          }

          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.paginateActiveServiceRequests();
        return;
      }

      this.allEstablishmentServiceRequests.sort(function (a: ServiceRequest, b: ServiceRequest) {
        var textA = a.type?.toUpperCase();
        var textB = b.type?.toUpperCase();

        if (!textA) {
          textA = "";
        }

        if (!textB) {
          textB = "";
        }

        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      this.sortedBy = column;
    } else if (column == "company") {
      if (this.sortedBy == column && this.sortedAscending) {
      
        this.allEstablishmentServiceRequests.sort(function (a: ServiceRequest, b: ServiceRequest) {
          
          if(a.referencedMachine?.company && b.referencedMachine?.company){
            var textA = b.referencedMachine?.company?.companyName?.toUpperCase() +  b.referencedMachine?.company?.companyName?.toUpperCase();
            var textB = a.referencedMachine?.company?.companyName?.toUpperCase() +  a.referencedMachine?.company?.companyName?.toUpperCase();

            if (!textA) {
              textA = "";
            }

            if (!textB) {
              textB = "";
            }

            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          }

          return 0;
        });
        this.paginateActiveServiceRequests();
        return;
      }

      this.allEstablishmentServiceRequests.sort(function (a: ServiceRequest, b: ServiceRequest) {
        
        if(a.referencedMachine?.company && b.referencedMachine?.company){
          var textA = a.referencedMachine?.company?.companyName?.toUpperCase() +  a.referencedMachine?.company?.companyName?.toUpperCase();
          var textB = b.referencedMachine?.company?.companyName?.toUpperCase() +  b.referencedMachine?.company?.companyName?.toUpperCase();
  
          if (!textA) {
            textA = "";
          }
  
          if (!textB) {
            textB = "";
          }
  
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }

        return 0;
      });

      this.sortedBy = column;
    } else if (column == "status") {
      if (this.sortedBy == column && this.sortedAscending) {
     
        this.allEstablishmentServiceRequests.sort(function (a: ServiceRequest, b: ServiceRequest) {
          var textA = b.status?.toUpperCase();
          var textB = a.status?.toUpperCase();

          if (!textA) {
            textA = "";
          }

          if (!textB) {
            textB = "";
          }

          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.paginateActiveServiceRequests();
        return;
      }

      this.allEstablishmentServiceRequests.sort(function (a: ServiceRequest, b: ServiceRequest) {
        var textA = a.status?.toUpperCase();
        var textB = b.status?.toUpperCase();

        if (!textA) {
          textA = "";
        }

        if (!textB) {
          textB = "";
        }

        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
  
      this.sortedBy = column;
    } if (column == "start-date") {
      if (this.sortedBy == column && this.sortedAscending) {

        this.allEstablishmentServiceRequests.sort((a: ServiceRequest, b: ServiceRequest) => {
          // In case the values are equal to prevent from random order (spinning)
          if(this.getDateOfServiceRequest(b) == this.getDateOfServiceRequest(a)){
            return (a.$id < b.$id) ? -1 : (a.$id > b.$id) ? 1 : 0;
          }
          return this.getDateOfServiceRequest(b) - this.getDateOfServiceRequest(a);
        });
        this.paginateActiveServiceRequests();
        return;
      }

      // Sort Ascending
      this.allEstablishmentServiceRequests.sort((a: ServiceRequest, b: ServiceRequest) => {
        // In case the values are equal to prevent from random order (spinning)
        if(this.getDateOfServiceRequest(b) == this.getDateOfServiceRequest(a)){
          return (a.$id < b.$id) ? -1 : (a.$id > b.$id) ? 1 : 0;
        }

        return this.getDateOfServiceRequest(a) - this.getDateOfServiceRequest(b)
      });

      this.sortedBy = column;
    }

    this.paginateActiveServiceRequests();
  }

  /**
   * Determine Time of ServiceRequest
   * @param serviceRequest 
   * @returns 
   */
  private getDateOfServiceRequest(serviceRequest?: ServiceRequest | undefined) {
    return serviceRequest?.startTime ? new Date(serviceRequest?.startTime).getTime() : 0;
  }


  /**
   * This method will paginate all present ServiceRequests
   * @returns 
   */
  paginateActiveServiceRequests(){
    if (!this.allEstablishmentServiceRequests) {
      return;
    }

    this.allEstablishmentServiceRequests.forEach(serviceRequest => {
      serviceRequest.hideInList = false;
    })
    
    var filterdActiveServiceRequests = this.allEstablishmentServiceRequests.filter(t => !t.hideInList && !t.notMatchingFilter)

    // Filter Statusses
    let selectedStatussesArr = this.selectedStatusses.map(s => s.name);
    filterdActiveServiceRequests = filterdActiveServiceRequests.filter(s => selectedStatussesArr.indexOf(s.status) != -1);

    // If list is empty then clear paginated list
    if (filterdActiveServiceRequests.length == 0) {
      this.paginatedActiveServiceRequests = [[]];
    }

    for (var i = 0; i < filterdActiveServiceRequests.length / this.perPage; i++) {
      if (i == 0) {
        this.paginatedActiveServiceRequests = [filterdActiveServiceRequests.slice(i * this.perPage, i * this.perPage + this.perPage)];
        continue;
      }

      if (!this.paginatedActiveServiceRequests) {
        return;
      }

      this.paginatedActiveServiceRequests.push(filterdActiveServiceRequests.slice(i * this.perPage, i * this.perPage + this.perPage));
    }

    if (!this.paginatedActiveServiceRequests) {
      return;
    }

    if (this.activePage >= this.paginatedActiveServiceRequests?.length) {
      this.activePage = 0;
    }

    // Store sort order
    localStorage.setItem(this.sortedByStorageKey, `${this.sortedBy}_${this.sortedAscending}`);
  }

  /**
   * This method will update the ServiceRequests status
   * @param ServiceRequests 
   */
  statusSelectionChanged(serviceRequest: ServiceRequest, status: string) {
    if (!serviceRequest || !status) {
      return;
    }
    
    // Change status
    var currentStatus = serviceRequest.status + "";
    serviceRequest.status = status;

    this.serviceRequestService.updateServiceRequest(serviceRequest).then(() => {
      this.communicationService.activeToastKind.next("Success");
      this.communicationService.activeToastMessage.next(`Het updaten van de status van serviceverzoek ${serviceRequest?.name} is gelukt!`);
    }).catch(() => {
      this.communicationService.activeToastKind.next("Error");
      this.communicationService.activeToastMessage.next(`Het updaten van de status van serviceverzoek ${serviceRequest?.name} is mislukt!`);
      serviceRequest.status = currentStatus;
    });
  }

  /**
   * This method will remove a ServiceRequest or show a cofirmation button
   * @param serviceRequest 
   * @param index 
   * @param confirmationNeeded 
   * @returns 
   */
  async removeServiceRequest(serviceRequest: ServiceRequest, index: number, confirmationNeeded: boolean) {
    if (confirmationNeeded) {
      var confirmButton = document.getElementById("confirm-remove" + index);
      var cancelButton = document.getElementById("cancel-remove" + index);
      var removeButton = document.getElementById("remove" + index);
      var editButton = document.getElementById("edit" + index);

      if (editButton) {
        editButton.style.display = "none";
      }

      if (confirmButton && removeButton && cancelButton) {
        confirmButton.style.display = "flex";
        cancelButton.style.display = "flex";
        removeButton.style.display = "none";
      }
      return;
    }

    // Remove ServiceRequest from the database
    this.serviceRequestService.deleteServiceRequest(serviceRequest).then(() => {
      // Update set of active ServiceRequest if removal was successfull
      this.allEstablishmentServiceRequests = this.allEstablishmentServiceRequests.filter(m => m != serviceRequest);
      this.communicationService.activeServiceRequests.next(this.allEstablishmentServiceRequests);
      
      // Remove Windows
      if(serviceRequest.windows){
        Promise.all(serviceRequest.windows.map(w => {
          return this.windowService.deleteWindow(w);
        }));
      }

      this.communicationService.activeToastKind.next("Success");
      this.communicationService.activeToastMessage.next(`Het verwijderen van serviceverzoek ${serviceRequest?.name} is gelukt!`);
    }).catch(() => {
      this.communicationService.activeToastKind.next("Error");
      this.communicationService.activeToastMessage.next(`Het verwijderen van serviceverzoek ${serviceRequest?.name} is mislukt!`);
    });
  }

  /**
   * This methow will cancel a remove action
   * @param index 
   */
  cancelRemoval(index: number) {
    var confirmButton = document.getElementById("confirm-remove" + index);
    var cancelButton = document.getElementById("cancel-remove" + index);
    var removeButton = document.getElementById("remove" + index);
    var editButton = document.getElementById("edit" + index);

    if (confirmButton && removeButton && cancelButton && editButton) {
      confirmButton.style.display = "none";
      cancelButton.style.display = "none";
      removeButton.style.display = "block";
      editButton.style.display = "block";
    }
  }

  /**
   * This method will return the color of a status
   * @param status 
   * @returns 
   */
  getStatusColor(status: string) {
    if (!status) {
      return;
    }

    var statusResource = this.serviceRequestService?.serviceRequestStatus.filter(s => s.value == status);

    if (!statusResource || statusResource?.length == 0) {
      return;
    }

    return statusResource[0].color;
  }

  /**
   * This method will set the ServiceRequest id to the given id so that the other status line could be accessed
   * @param serviceRequestId 
   */
  onStatusExpanderClick(serviceRequestId: string | undefined) {
    this.communicationService.openStatusServiceRequestIdChanged = true;
    if (this.communicationService.openStatusServiceRequestId == serviceRequestId) {
      this.communicationService.openStatusServiceRequestId = undefined;
    }
    this.communicationService.openStatusServiceRequestId = serviceRequestId;
  }

  /**
   * When click somewhere on the screen deativate open pop-ups
   */
  onClickLostFocus() {
    if (this.communicationService.openStatusServiceRequestIdChanged) {
      this.communicationService.openStatusServiceRequestIdChanged = false;
      return;
    }

    this.communicationService.openStatusServiceRequestIdChanged = false;
    this.communicationService.openStatusServiceRequestId = undefined;
  }

  /**
   * Multiselect Onchange Callback
   */
  filterStatusses(event: MultiSelectChangeEvent) {
    this.paginateActiveServiceRequests()
  }

  /**
   * This method will return the number of WVB Tasks
   * @param ServiceRequest 
   * @returns 
   */
  getNumberOfWVBTasks(sr: ServiceRequest) {
    if(!this.allWVBTasks) {
      return "err"
    }
    let tasks = this.allWVBTasks.filter(t => t.serviceRequestId == sr.$id)
    if (tasks.length > 0) {
      return tasks.length
    } else {
      return  "Geen WVB"
    }
    
  }
}

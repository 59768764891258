import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Mechanic } from 'src/app/models/mechanic';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { CompetentionService } from 'src/app/services/competention/competention.service';
import { MechanicService } from 'src/app/services/mechanic/mechanic.service';
import { PersonalDataService } from 'src/app/services/personal-data/personal-data.service';

@Component({
  selector: 'app-mechanic-dashboard',
  templateUrl: './mechanic-dashboard.component.html',
  styleUrls: ['./mechanic-dashboard.component.scss']
})
export class MechanicDashboardComponent implements OnInit {

  searchInput: string = "";
  sortedBy?: string;
  prevSort?: string;
  sortedAscending?: boolean;
  loadingMechanics?: boolean = false;
  lazyLoadingMechanics?: boolean = false;
  paginatedActiveMechanics?: [Mechanic[]];
  allEstablishmentMechanics: Mechanic[] = [];
  isViewer?: boolean;
  perPage: number;
  activePage: number;
  activeMechanic?: Mechanic;
  newMechanic?: Mechanic;
  perPageStorageKey: string = "MECHANICS_PERPAGE";

  // The Subscriptions to Unsub on destroy
  subs: Subscription[] = [];

  constructor(public communicationService: CommunicationService, private personalDataService: PersonalDataService, private competentionService: CompetentionService, public mechanicService: MechanicService) { 
    this.perPage = Number(localStorage.getItem(this.perPageStorageKey) ?? 20);
    this.activePage = 0;
    this.sortedBy = "name"
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe()
    })
  }

  ngOnInit(): void {
    this.subs.push(
      this.communicationService.activeMechanics.subscribe(activeVehicleList => {
        if (!activeVehicleList) {
          return;
        }
        
        this.allEstablishmentMechanics = activeVehicleList;
        this.paginateActiveMechanics();
      })
    )

    this.subs.push(
      this.communicationService.activeMechanic.subscribe(activeVehicle => {
        this.activeMechanic = activeVehicle;
      })
    )

    if(this.sortedBy){
      this.sortMechanicsBy(this.sortedBy, false);
    }

    this.paginateActiveMechanics();
  }

  onPerPageChanged(){
    localStorage.setItem(this.perPageStorageKey, this.perPage?.toString());
    this.paginateActiveMechanics();
  }
  
  /**
   * This method will create a new mechanic to fill in the Create-Component
   */
  createNewMechanic(){
    this.activeMechanic = undefined;
     this.newMechanic = {
       $id: "1",
       $collectionId: "",
       $createdAt: "",
       $databaseId: "",
       $permissions: [],
       $updatedAt: "",
       available: "Niet beschikbaar",
       personalData: {
          $id: "1",
          $collectionId: "",
          $createdAt: "",
          $databaseId: "",
          $permissions: [],
          $updatedAt: "",
          firstname: "",
          lastname: ""
       },
       type: "",
     };

    // Scroll edit window into view
    setTimeout(() => {
      document.getElementById('add-mechanic')?.scrollIntoView();
    }, 200);
  }

  /**
   * This method will search in the present set of Mechanics
   * based on the given search input
   * @returns 
   */
  searchMechanics(){
    if (!this.searchInput || this.searchInput == "") {
      this.allEstablishmentMechanics.forEach(t => t.notMatchingFilter = false);
      this.paginateActiveMechanics();
      return;
    }


    this.allEstablishmentMechanics.forEach(mechanic => {
      var matchingAttributeFound = false;
      if([mechanic].filter(
        mechanic =>
          mechanic.personalData?.firstname?.toLowerCase().includes(this.searchInput.toLowerCase()) ||
          mechanic.personalData?.lastname?.toLowerCase().includes(this.searchInput.toLowerCase()) ||
          mechanic.type?.toLowerCase().includes(this.searchInput.toLowerCase()) ||
          mechanic.competentions?.map(c => c.name).join(', ')?.toLowerCase().includes(this.searchInput.toLowerCase()) ||
          mechanic.available.toLowerCase().includes(this.searchInput.toLowerCase())).length > 0){
            mechanic.notMatchingFilter = false;
            matchingAttributeFound = true;
          }

          if(!matchingAttributeFound){
            mechanic.notMatchingFilter = true;
          }
      });

      this.paginateActiveMechanics();
  }

  /**
   * This method will set the active Mechanic which will be shown in the detail screen
   * @param mechanic 
   */
  editMechanic(mechanic: Mechanic){
    this.activeMechanic = mechanic;
    this.newMechanic = undefined;
    this.communicationService.activeMechanic?.next(mechanic);

    // Scroll edit window into view
    setTimeout(() => {
      document.getElementById('edit-mechanic')?.scrollIntoView();
    }, 200);
  }

  /**
   * This method will sort the set of mechanics ASC or DESC based on the given input
   * @param sortBy 
   */
  sortMechanicsBy(column: string, toggle: boolean = true){
    this.sortedBy = column;
    this.prevSort = column;

    if (column == "name") {
      if (this.sortedBy == column && this.sortedAscending) {
        if(toggle){
          this.sortedAscending = false;
        }

        this.allEstablishmentMechanics.sort(function (a: Mechanic, b: Mechanic) {
          var textA = b.personalData!.firstname?.toUpperCase() +  b.personalData!.lastname?.toUpperCase();
          var textB = a.personalData!.firstname?.toUpperCase() +  a.personalData!.lastname?.toUpperCase();

          if (!textA) {
            textA = "";
          }

          if (!textB) {
            textB = "";
          }

          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.paginateActiveMechanics();
        return;
      }

      this.allEstablishmentMechanics.sort(function (a: Mechanic, b: Mechanic) {
        var textA = a.personalData!.firstname?.toUpperCase() +  a.personalData!.lastname?.toUpperCase();
        var textB = b.personalData!.firstname?.toUpperCase() +  b.personalData!.lastname?.toUpperCase();

        if (!textA) {
          textA = "";
        }

        if (!textB) {
          textB = "";
        }

        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      if(toggle){
        this.sortedAscending = true;
      }
      this.sortedBy = column;
    } else if (column == "availability") {
      if (this.sortedBy == column && this.sortedAscending) {
     
        if(toggle){
          this.sortedAscending = false;
        }

        this.allEstablishmentMechanics.sort(function (a: Mechanic, b: Mechanic) {
          var textA = b.available?.toUpperCase();
          var textB = a.available?.toUpperCase();

          if (!textA) {
            textA = "";
          }

          if (!textB) {
            textB = "";
          }

          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.paginateActiveMechanics();
        return;
      }

      this.allEstablishmentMechanics.sort(function (a: Mechanic, b: Mechanic) {
        var textA = a.available?.toUpperCase();
        var textB = b.available?.toUpperCase();

        if (!textA) {
          textA = "";
        }

        if (!textB) {
          textB = "";
        }

        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    
      if(toggle){
        this.sortedAscending = true;
      }

      this.sortedBy = column;
    } else if (column == "type") {
      if (this.sortedBy == column && this.sortedAscending) {
     
        if(toggle){
          this.sortedAscending = false;
        }

        this.allEstablishmentMechanics.sort(function (a: Mechanic, b: Mechanic) {
          var textA = b.type?.toUpperCase();
          var textB = a.type?.toUpperCase();

          if (!textA) {
            textA = "";
          }

          if (!textB) {
            textB = "";
          }

          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.paginateActiveMechanics();
        return;
      }

      this.allEstablishmentMechanics.sort(function (a: Mechanic, b: Mechanic) {
        var textA = a.type?.toUpperCase();
        var textB = b.type?.toUpperCase();

        if (!textA) {
          textA = "";
        }

        if (!textB) {
          textB = "";
        }

        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    
      if(toggle){
        this.sortedAscending = true;
      }

      this.sortedBy = column;
    }

    this.paginateActiveMechanics();
  }

  /**
   * This method will paginate all present Mechanics
   * @returns 
   */
  paginateActiveMechanics(){
    if (!this.allEstablishmentMechanics) {
      return;
    }

    this.allEstablishmentMechanics.forEach(mechanic => {
      mechanic.hideInList = false;
    })
    
    var filterdActiveVehicles = this.allEstablishmentMechanics.filter(t => !t.hideInList && !t.notMatchingFilter)

    // If list is empty then clear paginated list
    if (filterdActiveVehicles.length == 0) {
      this.paginatedActiveMechanics = [[]];
    }

    for (var i = 0; i < filterdActiveVehicles.length / this.perPage; i++) {
      if (i == 0) {
        this.paginatedActiveMechanics = [filterdActiveVehicles.slice(i * this.perPage, i * this.perPage + this.perPage)];
        continue;
      }

      if (!this.paginatedActiveMechanics) {
        return;
      }

      this.paginatedActiveMechanics.push(filterdActiveVehicles.slice(i * this.perPage, i * this.perPage + this.perPage));
    }

    if (!this.paginatedActiveMechanics) {
      return;
    }

    if (this.activePage >= this.paginatedActiveMechanics?.length) {
      this.activePage = 0;
    }
  }

  /**
     * This method will update the mechanic status
     * @param mechanic 
     */
  statusSelectionChanged(mechanic: Mechanic, status: string) {
    if (!mechanic || !status) {
      return;
    }
    
    // Change status
    var currentStatus = mechanic.available + "";
    mechanic.available = status;

    this.mechanicService.updateMechanic(mechanic).then(() => {
      this.communicationService.activeToastKind.next("Success");
      this.communicationService.activeToastMessage.next(`Het updaten van de status van monteur ${mechanic?.personalData?.firstname} is gelukt!`);
    }).catch(() => {
      this.communicationService.activeToastKind.next("Error");
      this.communicationService.activeToastMessage.next(`Het updaten van de status van monteur ${mechanic?.personalData?.firstname} is mislukt!`);
      mechanic.available = currentStatus;
    });
  }

  /**
   * This method will remove a mechanic or show a cofirmation button
   * @param mechanic 
   * @param index 
   * @param confirmationNeeded 
   * @returns 
   */
  async removeMechanic(mechanic: Mechanic, index: number, confirmationNeeded: boolean) {
    if (confirmationNeeded) {
      var confirmButton = document.getElementById("confirm-remove" + index);
      var cancelButton = document.getElementById("cancel-remove" + index);
      var removeButton = document.getElementById("remove" + index);
      var editButton = document.getElementById("edit" + index);

      if (editButton) {
        editButton.style.display = "none";
      }

      if (confirmButton && removeButton && cancelButton) {
        confirmButton.style.display = "flex";
        cancelButton.style.display = "flex";
        removeButton.style.display = "none";
      }
      return;
    }

    // Remove Mechanic from the database
    this.mechanicService.deleteMechanic(mechanic).then(() => {
      // Update set of active Mechanic if removal was successfull
      this.allEstablishmentMechanics = this.allEstablishmentMechanics.filter(m => m != mechanic);
      this.communicationService.activeMechanics.next(this.allEstablishmentMechanics);
      
      // Remove active competentions
      mechanic.competentions?.forEach(c => {
        this.competentionService.deleteCompetention(c).catch(() => {
          this.communicationService.activeToastKind.next("Error");
          this.communicationService.activeToastMessage.next(`Het verwijderen van compententie ${c.name} is mislukt!`);
        });
      });

      // Delete PersonalData
      if(mechanic.personalData){
        this.personalDataService.deletePersonalData(mechanic.personalData).catch(() => {
          this.communicationService.activeToastKind.next("Error");
          this.communicationService.activeToastMessage.next(`Het verwijderen van de persoonlijke gegevens van ${mechanic.personalData?.firstname} is mislukt!`);
        });
      }

      this.communicationService.activeToastKind.next("Success");
      this.communicationService.activeToastMessage.next(`Het verwijderen van monteur ${mechanic?.personalData?.firstname} is gelukt!`);
    }).catch(() => {
      this.communicationService.activeToastKind.next("Error");
      this.communicationService.activeToastMessage.next(`Het verwijderen van monteur ${mechanic?.personalData?.firstname} is mislukt!`);
    });
  }

  /**
   * This methow will cancel a remove action
   * @param index 
   */
  cancelRemoval(index: number) {
    var confirmButton = document.getElementById("confirm-remove" + index);
    var cancelButton = document.getElementById("cancel-remove" + index);
    var removeButton = document.getElementById("remove" + index);
    var editButton = document.getElementById("edit" + index);

    if (confirmButton && removeButton && cancelButton && editButton) {
      confirmButton.style.display = "none";
      cancelButton.style.display = "none";
      removeButton.style.display = "block";
      editButton.style.display = "block";
    }
  }

  /**
   * This method will return the color of a status
   * @param status 
   * @returns 
   */
  getStatusColor(status: string) {
    if (!status) {
      return;
    }

    var statusResource = this.mechanicService?.mechanicStatusItems.filter(s => s.value == status);

    if (!statusResource || statusResource?.length == 0) {
      return;
    }

    return statusResource[0].color;
  }

  /**
   * This method will set the mechanic id to the given id so that the other status line could be accessed
   * @param mechanicId 
   */
  onStatusExpanderClick(mechanicId: string | undefined) {
    this.communicationService.openStatusMechanicIdChanged = true;
    if (this.communicationService.openStatusMechanicId == mechanicId) {
      this.communicationService.openStatusMechanicId = undefined;
    }
    this.communicationService.openStatusMechanicId = mechanicId;
  }

  /**
   * When click somewhere on the screen deativate open pop-ups
   */
  onClickLostFocus() {
    if (this.communicationService.openStatusMechanicIdChanged) {
      this.communicationService.openStatusMechanicIdChanged = false;
      return;
    }

    this.communicationService.openStatusMechanicIdChanged = false;
    this.communicationService.openStatusMechanicId = undefined;
  }
}

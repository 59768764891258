import { Injectable } from '@angular/core';
import { ID, Models, Query } from 'appwrite';
import * as moment from 'moment';
import { Api } from 'src/app/helpers/api';
import { WVBTask } from 'src/app/models/wvb-task';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WvbTaskService {

  wvbTaskCollection = "WVBTask"
  wvbTaskStatus: any[];

  constructor() {
    this.wvbTaskStatus = [
      {
        value: "To Do",
        description: "To Do",
        iconSource: "bi bi-card-checklist",
        color: "#de9d87",
        id: 1
      },
      {
        value: "In Uitvoering",
        description: "In Uitvoering",
        iconSource: "bi bi-card-checklist",
        color: "#87dede",
        id: 2
      },
      {
        value: "Uitgevoerd",
        description: "Uitgevoerd",
        iconSource: "bi bi-card-checklist",
        color: "#de87de",
        id: 3
      },
    ];
   }

  /**
   * This method will retrieve all WVB Tasks of the current team
   */
  async getWVBTasks() {
    var resultCount = 50;
    var wvbTaskList: WVBTask[] = [];
    var lastId: string | undefined = undefined;
    var wvbTasks: Models.DocumentList<WVBTask>;

    while (resultCount == 50) {
      if(lastId){
        wvbTasks = await Api.database.listDocuments<WVBTask>(
          environment.database,
          this.wvbTaskCollection,
          [Query.limit(50), Query.cursorAfter(lastId)]
        );
      } else {
        wvbTasks = await Api.database.listDocuments<WVBTask>(
          environment.database,
          this.wvbTaskCollection,
          [Query.limit(50)]
        );
      }

      lastId = wvbTasks?.documents[wvbTasks.documents.length - 1]?.$id;
      wvbTaskList.push(...wvbTasks.documents);
      resultCount = wvbTasks.documents.length;
    }

    return wvbTaskList;
  }

  /**
    * This method will create a new ServiceRequest in the database
  */
  addWVBTask(wvbTask: WVBTask) {
    return Api.database.createDocument(environment.database, this.wvbTaskCollection, ID.unique(), this.createPartialWVBTask(wvbTask))
  }

  /**
   * This method will update an existing ServiceRequest in the Database
  */
  updateWVBTask(wvbTask: WVBTask) {
    return Api.database.updateDocument(environment.database, this.wvbTaskCollection, wvbTask.$id, this.createPartialWVBTask(wvbTask));
  }

  /**
   * This method will delete an existing ServiceRequest from the database
  */
  deleteWVBTask(wvbTask: WVBTask) {
    return Api.database.deleteDocument(environment.database, this.wvbTaskCollection, wvbTask.$id);
  }

  /**
   * Creates a partial instance of the ServiceRequest without AppWrite properties
   * @param vehicle 
   * @returns 
  */
  private createPartialWVBTask(wvbTask: WVBTask): Partial<WVBTask> {
    if (!wvbTask) {
      return {};
    }

    return {
      name: wvbTask.name,
      status: wvbTask.status,
      deadline: wvbTask.deadline,
      description: wvbTask.description,
      serviceRequestId: wvbTask.serviceRequestId,
      department: wvbTask.department,
      archived: wvbTask.archived,
      logEntries: wvbTask.logEntries
    };
  }

}

import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ServiceRequest } from 'src/app/models/service-request';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommunicationService } from 'src/app/services/communication/communication.service';

@Component({
  selector: 'app-service-request-dashboard',
  templateUrl: './service-request-dashboard.component.html',
  styleUrls: ['./service-request-dashboard.component.scss']
})
export class ServiceRequestDashboardComponent implements OnInit {

  newServiceRequest?: ServiceRequest;
  activeServiceRequest?: ServiceRequest;

  showServiceRequestsComponent?: boolean;
  showServiceRequestListComponent?: boolean;
  showMechanicsScheduleComponent?: boolean;

  showServiceRequestsKey: string = "SHOWSERVICEREQUESTS_KEY";
  showServiceRequestListKey: string = "SHOWSERVICEREQUESTLIST_KEY";
  showMechanicsScheduleKey: string = "SHOWMECHANICSHEDULE_KEY";

  // The Subscriptions to Unsub on destroy
  subs: Subscription[] = [];

  constructor(private communicationService: CommunicationService, private authService: AuthService) {

    this.showServiceRequestsComponent = localStorage.getItem(this.showServiceRequestsKey) == "true";
    this.showServiceRequestListComponent = (localStorage.getItem(this.showServiceRequestListKey) ?? "true") == "true";
    this.showMechanicsScheduleComponent = localStorage.getItem(this.showMechanicsScheduleKey) == "true";
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe()
    })
  }

  ngOnInit(): void {
    this.subs.push(
      this.communicationService.activeServiceRequest.subscribe(serviceRequest => {
        if(serviceRequest){
          // Only open edit window if the ServiceRequest was already saved to the database before
          if(serviceRequest.$id){
            this.editServiceRequest(serviceRequest);
          }
        } else {
          this.activeServiceRequest = undefined;
          this.newServiceRequest = undefined;
        }
      })
    )
  }

  /**
   * This method will open the edit window for ServiceRequests
   * @param serviceRequest 
   */
  editServiceRequest(serviceRequest: ServiceRequest){
     this.newServiceRequest = undefined;
     this.activeServiceRequest = serviceRequest;
  }

  /**
   * This method will create a new empty ServiceRequest
   */
  createServiceRequest(){
    this.activeServiceRequest = undefined;

    this.newServiceRequest = {
      $id: "",
      $collectionId: "",
      $createdAt: "",
      $databaseId: "",
      $permissions: [],
      $updatedAt: "",
      name: "",
      createdDate: new Date(),
      createdBy: this.authService.user.getValue()?.name ? this.authService.user.getValue().name! : "Onbekend",
      status: "Plannen",
      type: "Overig",
      company: {
        $id: "",
        $collectionId: "",
        $createdAt: "",
        $databaseId: "",
        $permissions: [],
        $updatedAt: "",
        companyName: "",
        customerRelationId: ""
      }
    }

    // Needed to attach TravelComponents
    this.communicationService.activeServiceRequest.next(this.newServiceRequest);
    
    // Scroll new-section into view
    setTimeout(() => {
      document.getElementById('new-service-request')?.scrollIntoView();
    }, 200);
  }

  /**
   * This method will toggle the visibility of the given component
   * @param show 
   */
  toggleServiceRequestComponent(show: boolean){
    this.showServiceRequestsComponent = show;
    localStorage.setItem(this.showServiceRequestsKey, JSON.stringify(show));

    if(show){
      // Scroll new-section into view
      setTimeout(() => {
        document.getElementById('service-request-week-schedule')?.scrollIntoView();
      }, 200);
    }
  }

  /**
   * This method will toggle the visibility of the given component
   * @param show 
   */
   toggleServiceRequestListComponent(show: boolean){
    this.showServiceRequestListComponent = show;
    localStorage.setItem(this.showServiceRequestListKey, JSON.stringify(show));

    if(show){
      // Scroll new-section into view
      setTimeout(() => {
        document.getElementById('service-request-table')?.scrollIntoView();
      }, 200);
    }
  }

  /**
   * This method will toggle the visibility of the given component
   * @param show 
   */
   toggleMechanicScheduleComponent(show: boolean){
    this.showMechanicsScheduleComponent = show;
    localStorage.setItem(this.showMechanicsScheduleKey, JSON.stringify(show));

    if(show){
      // Scroll new-section into view
      setTimeout(() => {
        document.getElementById('service-request-mechanic-shedule')?.scrollIntoView();
      }, 200);
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Competention } from 'src/app/models/competention';
import { Mechanic } from 'src/app/models/mechanic';
import { Vehicle } from 'src/app/models/vehicle';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { CompetentionService } from 'src/app/services/competention/competention.service';
import { MechanicService } from 'src/app/services/mechanic/mechanic.service';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { PersonalDataService } from 'src/app/services/personal-data/personal-data.service';

@Component({
  selector: 'app-mechanic-details',
  templateUrl: './mechanic-details.component.html',
  styleUrls: ['./mechanic-details.component.scss']
})
export class MechanicDetailsComponent implements OnInit {

  @Input() mechanic?: Mechanic;
  newCompetentionName?: string;
  competentionsFailureFeedback?: string;
  attachedVehiclesFailureFeedback?: string;
  vehicleToAttachId?: string;
  activeVehicles?: Vehicle[];
  removedCompetentions?: Competention[];

  // The Subscriptions to Unsub on destroy
  subs: Subscription[] = [];

  constructor(public mechanicService: MechanicService, private personalDataService: PersonalDataService, private competentionService: CompetentionService, private communicationService: CommunicationService, private permissionService: PermissionService) { 
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe()
    })
  }

  ngOnInit(): void {
    // Retrieve active vehicles to attach to the mechanic
    this.subs.push(
      this.communicationService.activeVehicles.subscribe(vehicles => {
        this.activeVehicles = vehicles;
      })
    )
  }

  /**
   * This method will save the changes that has been made to the current Mechanic 
   */
  saveMechanicChanges(){
    if(!this.mechanic){
      return;
    }
    
    // Update Mechanic
    this.mechanicService.updateMechanic(this.mechanic).then(() => {

      // Update Compotentions --> Remove old ones
      this.removedCompetentions?.forEach(rc => {
        if(rc.$id){
          this.competentionService.deleteCompetention(rc);
        }
      });

      // Update Competentions --> Create and update
      this.mechanic?.competentions?.forEach(c => {
        if(c.$id){
          this.competentionService.updateCompetention(c);
        } else {
          this.competentionService.addCompetention(c, this.communicationService.activeTeamId.getValue()!, this.permissionService.getWritePermissions());
        }
      });

      // Save PersonalData
      if(this.mechanic?.personalData){
        if(this.mechanic.personalData.$id && this.mechanic.personalData.$id != ""){
          this.personalDataService.updatePersonalData(this.mechanic.personalData);
        } else {
          this.personalDataService.addPersonalData(this.mechanic.personalData, this.communicationService.activeTeamId.getValue()! , this.permissionService.getWritePermissions());
        }
      }
    
      this.communicationService.activeToastKind.next("Success");
      this.communicationService.activeToastMessage.next(`Het bijwerken van monteur ${this.mechanic?.personalData?.firstname} is gelukt!`);
    }).catch(() => {
      this.communicationService.activeToastKind.next("Error");
      this.communicationService.activeToastMessage.next(`Het bijwerken van monteur ${this.mechanic?.personalData?.firstname} is mislukt!`);
    });


    this.communicationService.activeMechanic?.next(undefined);
  }

  /**
   * This method will remove an own vehicle of a mechanic
   * @param mechanic 
   */
  removeOwnVehicle(mechanic: Mechanic){
    mechanic.hasOwnVehicle = false;
  } 

  /**
   * This method will attach a permanent Vehicle to the mechanic
   */
  attachVehicle(){
    this.attachedVehiclesFailureFeedback = undefined;

    if(!this.vehicleToAttachId || !this.mechanic){
      this.attachedVehiclesFailureFeedback = "Kies eerst een voertuig alvorens deze kan worden toegevoegd"
      return;
    }

    // Check if the vehicle is not already attached
    if((this.mechanic.permanentVehicles?.filter(v => v.$id == this.vehicleToAttachId)?.length ?? 0) > 0){
      this.attachedVehiclesFailureFeedback = "Dit voertuig is al toegevoegd aan deze monteur. Kies een ander voertuig"
      return;
    }

    if(!this.mechanic.permanentVehicles){
      this.mechanic.permanentVehicles = [];
    }

    if(this.vehicleToAttachId == "own-vehicle"){
      // Set Property for own vehicle
      this.mechanic.hasOwnVehicle = true;
      
      // Empty other Vehicles
      this.mechanic.permanentVehicles = [];
      this.mechanic.permanentVehicleIds = "";
      return;
    }

    var vehicleToAttach = this.activeVehicles?.filter(v => v.$id == this.vehicleToAttachId);

    if(!vehicleToAttach || vehicleToAttach?.length == 0){
      this.attachedVehiclesFailureFeedback = "Het is niet gelukt om het voertuig toe te voegen"
      return;
    }

    // Add vehicle to list
    this.mechanic.permanentVehicles.push(vehicleToAttach[0]);
    this.mechanic.permanentVehicleIds += ` ${vehicleToAttach[0].$id}`
    this.mechanic.hasOwnVehicle = false;
    
    // Reset value
    this.vehicleToAttachId = undefined;
  }

  /**
   * This method will remove a permanent Vehicle from the mechanic
   * @param vehicle 
   */
  removePermanentVehicle(vehicle: Vehicle){
    if(this.mechanic?.permanentVehicles){
      this.mechanic.permanentVehicles = this.mechanic?.permanentVehicles?.filter(v => v != vehicle);
    }
  }

  /**
   * This method will remove a competention from a mechanic
   * @param competention 
   */
  removeCompetention(competention: Competention){
    if(this.mechanic?.competentions){
      if(!this.removedCompetentions){
        this.removedCompetentions = [];
      }

      this.removedCompetentions.push(competention)
      this.mechanic.competentions = this.mechanic?.competentions?.filter(c => c != competention);
    }
  }

  /**
   * This method will add a competention to the mechanic
   */
  addCompetention(){
    this.competentionsFailureFeedback = undefined;

    if(!this.newCompetentionName || !this.mechanic || this.newCompetentionName?.length < 1){
      this.competentionsFailureFeedback = "Voer een naam van tenminste 1 teken in";
      return;
    }

    if(!this.mechanic.competentions){
      this.mechanic.competentions = [];
    }

    // Push the new Competention --> Replace when backend call is implemented
    this.mechanic.competentions.push({
      $id: "",
      $collectionId: "",
      $createdAt: "",
      $databaseId: "",
      $permissions: [],
      $updatedAt: "",
      name: this.newCompetentionName
    });

    // Reset input
    this.newCompetentionName = undefined;
  }
}

import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ServiceRequest } from 'src/app/models/service-request';
import { WVBTask } from 'src/app/models/wvb-task';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { WvbTaskService } from 'src/app/services/wvb-task/wvb-task.service';

@Component({
  selector: 'app-work-preparation-dashboard',
  templateUrl: './work-preparation-dashboard.component.html',
  styleUrls: ['./work-preparation-dashboard.component.scss']
})
export class WorkPreparationDashboardComponent implements OnInit {

  activeWVBTask?: WVBTask;
  activeServiceRequest?: ServiceRequest;

  // View Related
  showAllWVBTasksModule = true
  showServiceRequestModule = true

  showAllWVBTasksKey: string = "SHOWALLWVBTASKS_KEY";
  showServiceRequestKey: string = "SHOWWVBSERVICEREQUESTS_KEY";

  // The Subscriptions to Unsub on destroy
  subs: Subscription[] = [];

  constructor(private communicationService: CommunicationService) { 
    this.showAllWVBTasksModule = (localStorage.getItem(this.showAllWVBTasksKey) ?? "true") == "true";
    this.showServiceRequestModule = (localStorage.getItem(this.showServiceRequestKey) ?? "true") == "true";
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe()
    })
  }

  ngOnInit(): void {
    this.subs.push(
      this.communicationService.activeWVBTask.subscribe(t => {
        this.activeWVBTask = t

        var serviceRequests = this.communicationService.activeServiceRequests?.getValue().filter(s => s.$id == t?.serviceRequestId);

        if(serviceRequests.length > 0){
          this.activeServiceRequest = serviceRequests[0];
        }
      })
    )
  }

  toggleAllWVBTasksComponent(setState: boolean){
    this.showAllWVBTasksModule = setState;
    localStorage.setItem(this.showAllWVBTasksKey, JSON.stringify(setState));
  }

  toggleServiceRequestsListComponent(setState: boolean){
    this.showServiceRequestModule = setState;
    localStorage.setItem(this.showServiceRequestKey, JSON.stringify(setState));
  }

}

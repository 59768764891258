import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AccessDeniedComponent } from './components/public/access-denied/access-denied.component';
import { SignInComponent } from './components/authentication/sign-in/sign-in.component';
import { PasswordRecoveryComponent } from './components/authentication/password-recovery/password-recovery.component';
import { SignUpComponent } from './components/authentication/sign-up/sign-up.component';
import { MechanicPlanningDashboardComponent } from './components/protected/planning-module/mechanic-planning-dashboard/mechanic-planning-dashboard.component';
import { MechanicDashboardComponent } from './components/protected/planning-module/mechanic/mechanic-dashboard/mechanic-dashboard.component';
import { ServiceRequestDashboardComponent } from './components/protected/planning-module/service-requests/service-request-dashboard/service-request-dashboard.component';
import { ToolDashboardComponent } from './components/protected/planning-module/tools/tool-dashboard/tool-dashboard.component';
import { VehicleDashboardComponent } from './components/protected/planning-module/vehicle/vehicle-dashboard/vehicle-dashboard.component';
import { AdminSettingsComponent } from './components/protected/settings-module/admin-settings/admin-settings.component';
import { WorkPreparationDashboardComponent } from './components/protected/work-preparation-module/work-preparation-dashboard/work-preparation-dashboard.component';
import { ModuleGuard } from './guards/module.guard';

const routes: Routes = [
  { path: '', redirectTo: '/portal', pathMatch: 'full' },
  { path: 'sign-in', component: SignInComponent },
  { path: 'password-recovery', component: PasswordRecoveryComponent },
  { path: 'register', component: SignUpComponent },
  {
    path: 'portal',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/protected/portal/portal/portal.component').then(m => m.PortalComponent)
  },
  {
    path: 'mechanic-planning-dashboard',
    canActivate: [AuthGuard, ModuleGuard],
    data: {module: 'mech_plan'},
    component: MechanicPlanningDashboardComponent
  },
  {
    path: 'service-requests-dashboard',
    canActivate: [AuthGuard],
    component: ServiceRequestDashboardComponent
  },
  {
    path: 'mechanic-dashboard',
    canActivate: [AuthGuard],
    component: MechanicDashboardComponent
  },
  {
    path: 'tools-dashboard',
    canActivate: [AuthGuard],
    component: ToolDashboardComponent
  },
  {
    path: 'vehicle-dashboard',
    canActivate: [AuthGuard],
    component: VehicleDashboardComponent
  },
  {
    path: 'admin-settings',
    canActivate: [AuthGuard, ModuleGuard],
    data: {module: 'settings'},
    component: AdminSettingsComponent
  },
  {
    path: 'work-preparation-dashboard',
    canActivate: [AuthGuard, ModuleGuard],
    data: {module: 'wvb'},
    component: WorkPreparationDashboardComponent
  },
  {
    path: 'service-contracts',
    canActivate: [AuthGuard, ModuleGuard],
    data: {module: 'ser_contr'},
    loadComponent: () => import('./components/protected/service-contracts/service-contract-dashboard/service-contract-dashboard.component').then(m => m.ServiceContractDashboardComponent)
  },
  {
    path: 'issue-tracker-dashboard',
    canActivate: [AuthGuard, ModuleGuard],
    data: {module: 'iss_track'},
    loadComponent: () => import('./components/protected/issue-tracker-module/issue-tracker-dashboard/issue-tracker-dashboard.component').then(m => m.IssueTrackerDashboardComponent)
  },
  {
    path: 'asset-management',
    canActivate: [AuthGuard, ModuleGuard],
    data: {module: 'ass_manage'},
    loadComponent: () => import('./components/protected/asset-management/asset-management-dashboard/asset-management-dashboard.component').then(m => m.AssetManagementDashboardComponent)
  },
  {
    path: 'issue-create',
    canActivate: [AuthGuard, ModuleGuard],
    data: {module: 'iss_track'},
    loadComponent: () => import('./components/protected/issue-tracker-module/issue-create/issue-create.component').then(m => m.IssueCreateComponent)
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div class="row vehicle-availability" *ngIf="vehicle" (click)="onClickLostFocus()">
   <div class="col card">
       <div class="card-body">
           <div class="row">
               <h4 class="col card-title">Beschikbaarheid {{vehicle.name}}</h4>
               <span class="introduction">Beheer en bekijk in onderstaand component de beschikbaarheid van {{vehicle.name}}. Deze wordt tevens gevisualiseerd in de 'Inzetten' module</span>
               <div class="filters">
                   <div class="form-check form-switch">
                       <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchShowPast" [(ngModel)]="showPastUnavailabilities" (ngModelChange)="paginateActiveUnavailabilities()">
                       <label class="form-check-label" for="flexSwitchShowPast">Toon gepasseerde onbeschikbaarheden</label>
                   </div>
                   <div class="col-4 search">
                       <i class="bi bi-search" data-toggle="tooltip" data-placement="top" title="Zoeken op: Start- Einddatum en type"></i>
                       <input [(ngModel)]="searchInput" (ngModelChange)="searchUnavailability()" class="form-control" type="text" placeholder="Zoeken...">
                   </div>
               </div>
           </div>
           <table class="table">
               <thead>
                   <tr>
                       <th style="width: 12%" class="sortable" scope="col" (click)="sortUnavailabilitiesBy('start date')">Startdatum
                           <i class="bi bi-caret-down-fill" *ngIf="sortedBy == 'start date' && sortedAscending"></i>
                           <i class="bi bi-caret-up-fill" *ngIf="sortedBy == 'start date' && !sortedAscending"></i>
                       </th>
                       <th style="width: 12%" class="sortable" scope="col" (click)="sortUnavailabilitiesBy('end date')">Einddatum
                           <i class="bi bi-caret-down-fill" *ngIf="sortedBy == 'end date' && sortedAscending"></i>
                           <i class="bi bi-caret-up-fill" *ngIf="sortedBy == 'end date' && !sortedAscending"></i>
                       </th>
                       <th style="width: 35%" class="sortable" scope="col">Aanvullend bericht</th>
                       <th style="width: 11%" class="sortable" scope="col" (click)="sortUnavailabilitiesBy('type')">Type
                           <i class="bi bi-caret-down-fill" *ngIf="sortedBy == 'type' && sortedAscending"></i>
                           <i class="bi bi-caret-up-fill" *ngIf="sortedBy == 'type' && !sortedAscending"></i>
                       </th>
                       <th style="width: 15%" scope="col">Acties</th>
                   </tr>
               </thead>
               <tbody *ngIf="paginatedActiveUnavailability">
                   <tr *ngFor="let unavailability of paginatedActiveUnavailability[activePage]; index as i">
                       <td>{{unavailability.startTime | date: 'dd-MM-yy - HH:mm'}}</td>
                       <td>{{unavailability.endTime | date: 'dd-MM-yy - HH:mm'}}</td>
                       <td *ngIf="!unavailability.additionalMessage">-</td>
                       <td *ngIf="unavailability.additionalMessage">{{unavailability.additionalMessage}}</td>
                       <td class="status-line">
                           <div class="status-badge-section">
                               <span class="badge badge-primary" (click)="onTypeExpanderClick(unavailability.$id)" [ngStyle]="{'background-color': getStatusColor(unavailability.type)}">
                                   <i class="{{unavailabilityService.getVehicleUnavailabilityStatusIcon(unavailability.type)}}"></i>
                                   {{unavailability.type}}
                               </span>
                               <div (click)="onTypeExpanderClick(unavailability.$id)" *ngIf="!isViewer">
                                   <i class="bi bi-caret-down-fill"></i>
                               </div>
                               <div class="other-status" *ngIf="communicationService.openVehicleUnavailabilityId == unavailability.$id && communicationService.openVehicleUnavailabilityId && !isViewer">
                                   <div class="row" *ngFor="let statusItem of unavailabilityService.unavailabilityTypeListVehicles">
                                       <div class="col">
                                           <span (click)="typeSelectionChanged(unavailability, statusItem.value)" class="badge badge-primary" [ngStyle]="{'background-color': getStatusColor(statusItem.value)}">
                                               <i class="{{unavailabilityService.getVehicleUnavailabilityStatusIcon(statusItem.value)}}"></i>
                                               {{statusItem.description}}
                                           </span>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </td>
                       <td>
                            <div class="mechanic-actions">
                                <!-- Remove -->
                            <button *ngIf="!isViewer" class="btn btn-danger remove-button" id="removeU{{i}}" (click)="removeUnavailability(unavailability, i, true)">
                                <i class="bi bi-trash"></i>
                            </button>
                            <button *ngIf="!isViewer" class="btn btn-danger remove-confirmation" id="confirm-removeU{{i}}" (click)="removeUnavailability(unavailability, i, false)">Bevestig</button>
                            <button *ngIf="!isViewer" class="btn btn-primary action-button cancel-remove" id="cancel-removeU{{i}}" (click)="cancelRemoval(i)"><i class="bi bi-x-circle"></i></button>
                        
                            </div>    
                        </td>
                   </tr>
                   <tr class="add-row" *ngIf="newUnavailability">
                       <td><input class="form-control" type="datetime-local" max="9999-12-31T23:59"  [(ngModel)]="newUnavailability.startTime" /></td>
                       <td><input class="form-control" type="datetime-local" max="9999-12-31T23:59" [(ngModel)]="newUnavailability.endTime" /></td>
                       <td><textarea class="form-control" maxlength="500" rows="1" [(ngModel)]="newUnavailability.additionalMessage"></textarea></td>
                       <td class="status-line">
                           <div class="status-badge-section">
                               <span class="badge badge-primary" (click)="onTypeExpanderClick(newUnavailability.$id)" [ngStyle]="{'background-color': getStatusColor(newUnavailability.type)}">
                                   <i class="{{unavailabilityService.getVehicleUnavailabilityStatusIcon(newUnavailability.type)}}"></i>
                                   {{newUnavailability.type}}
                               </span>
                               <div (click)="onTypeExpanderClick(newUnavailability.$id)" *ngIf="!isViewer">
                                   <i class="bi bi-caret-down-fill"></i>
                               </div>
                               <div class="other-status" *ngIf="communicationService.openVehicleUnavailabilityId == newUnavailability.$id && communicationService.openVehicleUnavailabilityId && !isViewer">
                                   <div class="row" *ngFor="let statusItem of unavailabilityService.unavailabilityTypeListVehicles">
                                       <div class="col">
                                           <span (click)="typeSelectionChanged(newUnavailability, statusItem.value, true)" class="badge badge-primary" [ngStyle]="{'background-color': getStatusColor(statusItem.value)}">
                                               <i class="{{unavailabilityService.getVehicleUnavailabilityStatusIcon(statusItem.value)}}"></i>
                                               {{statusItem.description}}
                                           </span>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </td>
                       <td class="mechanic-actions add-actions">
                           <!-- Remove -->
                           <button *ngIf="!isViewer" class="btn btn-success add-button" (click)="addUnavailability()">
                               <i class="bi bi-plus-square"></i>
                           </button>
                           <select class="form-control interval" disabled>
                               <option value="no-interval">Eenmalig</option>
                               <option value="daily">Dagelijks</option>
                               <option value="monthly">Wekelijks</option>
                               <option value="yearly">Jaarlijks</option>
                           </select>
                       </td>
                   </tr>
               </tbody>
           </table>
           <div class="table-footer">
               <div class="no-vehicles" *ngIf="allUnavailability.length == 0">
                   <div *ngIf="loadingUnavailability" class="loading-section">
                       <span class="loader"></span>
                       <span class="loader-text">Onbeschikbaarheden worden geladen...</span>
                   </div>
               </div>
               <div class="row" *ngIf="lazyLoadingUnavailability && !loadingUnavailability">
                   <div class="col lazy-loading">
                       <span class="lazy-loader"></span>
                       <span class="still-loading">Meer onbeschikbaarheden aan het ophalen...</span>
                   </div>
               </div>
               <div class="table-footer-actions" [ngClass]="{'align-right': allUnavailability.length == 0 || !allUnavailability}">
                   <nav aria-label="Page navigation" *ngIf="allUnavailability?.length ?? 0 > 0">
                       <ul class="pagination">
                           <li class="page-item" (click)="activePage = activePage - 1">
                               <a class="page-link" aria-label="Previous">
                                   <span aria-hidden="true">&laquo;</span>
                               </a>
                           </li>
                           <li class="page-item" (click)="activePage = i" *ngFor="let subSet of paginatedActiveUnavailability; let i = index">
                               <a class="page-link active-page" *ngIf="i == activePage">{{i + 1}}</a>
                               <a class="page-link" *ngIf="i != activePage">{{i + 1}}</a>
                           </li>
                           <li class="page-item" (click)="activePage = activePage + 1">
                               <a class="page-link" aria-label="Next">
                                   <span aria-hidden="true">&raquo;</span>
                               </a>
                           </li>
                       </ul>
                       <div class="per-page">
                           <div class="form-group">
                               <select class="form-select" [(ngModel)]="perPage" (ngModelChange)="paginateActiveUnavailabilities()">
                                   <option [ngValue]="5"><span>5</span></option>
                                   <option [ngValue]="10"><span>10</span></option>
                                   <option [ngValue]="20"><span>20</span></option>
                                   <option [ngValue]="50"><span>50</span></option>
                               </select>
                           </div>
                           <span>Onbeschikbaarheden per pagina</span>
                       </div>
                   </nav>
               </div>
           </div>
       </div>
   </div>
</div>
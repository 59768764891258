import { Injectable } from '@angular/core';
import { ID, Models, Query } from 'appwrite';
import { Api } from 'src/app/helpers/api';
import { TravelComponent } from 'src/app/models/travel-component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TravelComponentService {

  travelComponentTypes: any[];
  travelComponentTable = "TravelComponents";

  constructor() {
    this.travelComponentTypes = [
      {
        value: "Hotel",
        description: "Hotel",
        iconSource: "bi bi-building",
        color: "#198754",
        id: 1
      },
      {
        value: "Huurauto",
        description: "Huurauto",
        iconSource: "bi bi-car-front",
        color: "#198754",
        id: 2
      },
      {
        value: "Vlucht",
        description: "Vlucht",
        iconSource: "bi bi-airplane",
        color: "#198754",
        id: 3
      },
      {
        value: "Overig",
        description: "Overig",
        iconSource: "bi bi-question-lg",
        color: "#198754",
        id: 4
      },
    ];
  }

   /**
   * This method will retrieve all TravelComponents that belongs to the given ServiceRequest
   * @param requestId 
   */
   async getAllTravelComponentsOfServiceRequest(requestId: string){
    var resultCount = 50;
    var resultList: TravelComponent[] = [];
    var lastId: string | undefined = undefined;
    var results: Models.DocumentList<TravelComponent>;

    while(resultCount == 50){
      if(lastId){
        results = await Api.database.listDocuments<TravelComponent>(
          environment.database,
          this.travelComponentTable,
          [Query.limit(50), Query.equal('serviceRequestId', requestId), Query.cursorAfter(lastId)]
        );
      } else {
        results = await Api.database.listDocuments<TravelComponent>(
          environment.database,
          this.travelComponentTable,
          [Query.limit(50), Query.equal('serviceRequestId', requestId)]
        );
      }

      // Find related documents
      results.documents?.forEach(travelComponent => {
        travelComponent.documents = [];
        if(travelComponent.documentReferences){
          travelComponent.documentReferences.split(" ").forEach(async documentReference => {
            var documentMetaData = await this.getTravelDocument(documentReference);

            travelComponent.documents?.push({
              id: documentReference,
              name: documentMetaData.name,
              type: documentMetaData.mimeType?.split("/")[1]
            });
          });
        }     
      });

      lastId = results?.documents[results.documents.length - 1]?.$id;
      resultList.push(...results.documents);
      resultCount = results.documents.length;
    }

    return resultList;
  }


  /**
    * This method will create a new TravelComponent in the database
  */
  addTravelComponent(travelComponent: TravelComponent) {
    return Api.database.createDocument(environment.database, this.travelComponentTable, ID.unique(), this.createPartialTravelComponent(travelComponent))
  }

  /**
   * This method will update an existing TravelComponent in the Database
  */
  updateTravelComponent(travelComponent: TravelComponent) {
    return Api.database.updateDocument(environment.database, this.travelComponentTable, travelComponent.$id, this.createPartialTravelComponent(travelComponent));
  }

  /**
   * This method will delete an existing TravelComponent from the database
  */
  deleteTravelComponent(travelComponent: TravelComponent) {
    return Api.database.deleteDocument(environment.database, this.travelComponentTable, travelComponent.$id);
  }

  /**
   * This method will return the icon resource based on the status
   */
  getStatusIcon(status: string) {
    if (!status) {
      return;
    }

    var statusResource = this.travelComponentTypes.filter(s => s.value == status);

    if (!statusResource || statusResource?.length == 0) {
      return;
    }

    return statusResource[0].iconSource;
  }

  /**
   * This function will upload a file to the bucket
   * @param file 
   * @returns 
   */
  uploadTravelDocument(file: File) {
    return Api.storage.createFile(
      'TravelComponents',
      ID.unique(),
      file
    );
  }

  /**
   * This function will remove a file from a bucket
   * @param file 
   * @returns 
   */
  removeTravelDocument(fileId: string) {
    return Api.storage.deleteFile(
      'TravelComponents',
      fileId
    );
  }

  /**
   * This function will try to retrieve file meta data by the provided id
   * @param file 
   * @returns 
   */
  getTravelDocument(fileId: string) {
    return Api.storage.getFile(
      'TravelComponents',
      fileId
    );
  }

  /**
   * This method will download the TravelDocument
   * @param fileId 
   */
  downloadTravelDocument(fileId: string){
    return Api.storage.getFileDownload("TravelComponents", fileId);
  }

  /**
   * Creates a partial instance of the TravelComponent without AppWrite properties
   * @param travelComponent 
   * @returns 
  */
  private createPartialTravelComponent(travelComponent: TravelComponent): Partial<TravelComponent> {
    if (!travelComponent) {
      return {};
    }

    return {
      name: travelComponent.name,
      additionalMessage: travelComponent.additionalMessage,
      attachedResources: travelComponent.attachedResources,
      documentReferences: travelComponent.documentReferences,
      serviceRequestId: travelComponent.serviceRequestId,
      type: travelComponent.type
    };
  }
}

<div class="container" (click)="onClickLostFocus()">
    <div class="row initial-row">
        <div class="col card dashboard-header">
            <div class="card-body">
                <h3>Beheer Gereedschappen</h3>
                <span>Welkom in de <strong>Beheer Gereedschappen module</strong>. <br> Beheer hier gereedschappen en de eigenschappen ervan.</span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="div card">
            <div class="card-body">
                <div class="row">
                    <h4 class="col card-title">Gereedschappen</h4>
                    <div class="col-4 search">
                        <i class="bi bi-search" data-toggle="tooltip" data-placement="top" title="Zoeken op: Naam, status, merk..."></i>
                        <input [(ngModel)]="searchInput" (ngModelChange)="searchTools()" class="form-control" type="text" placeholder="Zoeken...">
                    </div>
                </div>
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width: 20%" class="sortable" scope="col" (click)="sortToolsBy('name')">Naam
                                <i class="bi bi-caret-down-fill" *ngIf="sortedBy == 'name' && sortedAscending"></i>
                                <i class="bi bi-caret-up-fill" *ngIf="sortedBy == 'name' && !sortedAscending"></i>
                            </th>
                            <th style="width: 15%" class="sortable" scope="col" (click)="sortToolsBy('attached to')">Gekoppeld aan
                                <i class="bi bi-caret-down-fill" *ngIf="sortedBy == 'attached to' && sortedAscending"></i>
                                <i class="bi bi-caret-up-fill" *ngIf="sortedBy == 'attached to' && !sortedAscending"></i>
                            </th>
                            <th style="width: 10%" class="sortable" scope="col" (click)="sortToolsBy('check before')">Controle voor
                                <i class="bi bi-caret-down-fill" *ngIf="sortedBy == 'check before' && sortedAscending"></i>
                                <i class="bi bi-caret-up-fill" *ngIf="sortedBy == 'check before' && !sortedAscending"></i>
                            </th>
                            <th style="width: 10%" class="sortable" scope="col" (click)="sortToolsBy('availability')">Status
                                <i class="bi bi-caret-down-fill" *ngIf="sortedBy == 'availability' && sortedAscending"></i>
                                <i class="bi bi-caret-up-fill" *ngIf="sortedBy == 'availability' && !sortedAscending"></i>
                            </th>
                            <th style="width: 15%" scope="col">Acties</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="paginatedActiveTools">
                        <tr *ngFor="let tool of paginatedActiveTools[activePage]; index as i" [ngClass]="{'active-tool': activeTool == tool}">
                            <td>{{tool.name}}</td>
                            <td *ngIf="tool.attachedToId">{{getAttachedToName(tool)}}</td>
                            <td *ngIf="!tool.attachedToId">Niet gekoppeld</td>
                            <td>{{tool.checkBeforeDate | date: 'dd-MM-yyyy'}}</td>
                            <td class="status-line">
                                <div class="status-badge-section">
                                    <span class="badge badge-primary" (click)="onStatusExpanderClick(tool.$id)" [ngStyle]="{'background-color': getStatusColor(tool.available)}">
                                        <i class="{{toolService.getStatusIcon(tool.available)}}"></i>
                                        {{tool.available}}
                                    </span>
                                    <div (click)="onStatusExpanderClick(tool.$id)" *ngIf="!isViewer">
                                        <i class="bi bi-caret-down-fill"></i>
                                    </div>
                                    <div class="other-status" *ngIf="communicationService.openStatusToolId == tool.$id && communicationService.openStatusToolId && !isViewer">
                                        <div class="row" *ngFor="let statusItem of toolService.toolStatusItems">
                                            <div class="col">
                                                <span (click)="statusSelectionChanged(tool, statusItem.value)" class="badge badge-primary" [ngStyle]="{'background-color': getStatusColor(statusItem.value)}">
                                                    <i class="{{toolService.getStatusIcon(statusItem.value)}}"></i>
                                                    {{statusItem.description}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
        
                            </td>
                            <td class="tool-actions">
                                <button class="btn btn-primary action-button first-button" id="edit{{i}}" (click)="editTool(tool)">
                                    <i class="bi bi-pencil"></i>
                                </button>
                                <!-- Remove -->
                                <button *ngIf="!isViewer" class="btn btn-danger remove-button" id="remove{{i}}" (click)="removeTool(tool, i, true)">
                                    <i class="bi bi-trash"></i>
                                </button>
                                <button *ngIf="!isViewer" class="btn btn-danger remove-confirmation" id="confirm-remove{{i}}" (click)="removeTool(tool, i, false)">Bevestig</button>
                                <button *ngIf="!isViewer" class="btn btn-primary action-button cancel-remove" id="cancel-remove{{i}}" (click)="cancelRemoval(i)"><i class="bi bi-x-circle"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="table-footer">
                    <div class="no-tools" *ngIf="allEstablishmentTools.length == 0">
                        <div *ngIf="loadingTools" class="loading-section">
                            <span class="loader"></span>
                            <span class="loader-text">Gereedschappen worden geladen...</span>
                        </div>
                    </div>
                    <div class="row" *ngIf="lazyLoadingTools && !loadingTools">
                        <div class="col lazy-loading">
                            <span class="lazy-loader"></span>
                            <span class="still-loading">Meer gereedschappen aan het ophalen...</span>
                        </div>
                    </div>
                    <div class="table-footer-actions" [ngClass]="{'align-right': allEstablishmentTools.length == 0 || !allEstablishmentTools}">
                        <nav aria-label="Page navigation" *ngIf="allEstablishmentTools?.length ?? 0 > 0">
                            <ul class="pagination">
                                <li class="page-item" (click)="activePage = activePage - 1">
                                    <a class="page-link" aria-label="Previous">
                                        <span aria-hidden="true">&laquo;</span>
                                    </a>
                                </li>
                                <li class="page-item" (click)="activePage = i" *ngFor="let subSet of paginatedActiveTools; let i = index">
                                    <a class="page-link active-page" *ngIf="i == activePage">{{i + 1}}</a>
                                    <a class="page-link" *ngIf="i != activePage">{{i + 1}}</a>
                                </li>
                                <li class="page-item" (click)="activePage = activePage + 1">
                                    <a class="page-link" aria-label="Next">
                                        <span aria-hidden="true">&raquo;</span>
                                    </a>
                                </li>
                            </ul>
                            <div class="per-page">
                                <div class="form-group">
                                    <select class="form-select" [(ngModel)]="perPage" (ngModelChange)="onPerPageChanged()">
                                        <option [ngValue]="5"><span>5</span></option>
                                        <option [ngValue]="10"><span>10</span></option>
                                        <option [ngValue]="20"><span>20</span></option>
                                        <option [ngValue]="50"><span>50</span></option>
                                    </select>
                                </div>
                                <span>Gereedschappen per pagina</span>
                            </div>
                        </nav>
                        <div class="tool-buttons">
                            <div class="create-tool">
                                <div class="btn-group template-tool" role="group">
                                    <button class="btn btn-success new-tool" [ngClass]="{'glowing-actions': allEstablishmentTools.length == 0 || !allEstablishmentTools}" *ngIf="!isViewer" (click)="createNewTool()">
                                        <i class="bi bi-plus-square"></i>
                                        <span>Nieuw gereedschap</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-tool-create *ngIf="newTool" [tool]="newTool"></app-tool-create>
    <app-tool-details *ngIf="activeTool" [tool]="activeTool"></app-tool-details>
</div>
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Api } from '../helpers/api';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise((resolve, reject) => {
      Api.account.get().then(async userState => {
        if (userState) {
          if (userState.emailVerification) {
            resolve(true);
            return;
          } else {
            resolve(false);
            this.router.navigate(['/access-denied']);
          }
        } else {
          this.router.navigate(['/sign-in']);
          resolve(false);
        }
      }).catch(err => {
        this.router.navigate(['/sign-in']);
        resolve(false);
      });
    });
  }
}
import { Component, Input, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import * as moment from 'moment';
import { ModalService } from 'ngx-modal-ease';
import { MultiSelectModule } from 'primeng/multiselect';
import { Subscription } from 'rxjs';
import { LogBookComponent } from 'src/app/components/protected/log-book/log-book.component';
import { ServiceRequest } from 'src/app/models/service-request';
import { WVBTask } from 'src/app/models/wvb-task';
import { WVBTaskPreset } from 'src/app/models/wvb-task-preset';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { ServiceRequestService } from 'src/app/services/service-request/service-request.service';
import { WvbTaskPresetService } from 'src/app/services/wvb-task-preset/wvb-task-preset.service';
import { WvbTaskService } from 'src/app/services/wvb-task/wvb-task.service';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    BrowserModule,
    MultiSelectModule
  ],
  // Fix p-Multiselect web component error for standalone module
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  selector: 'app-work-preparation-tasks-table',
  templateUrl: './work-preparation-tasks-table.component.html',
  styleUrl: './work-preparation-tasks-table.component.scss'
})
export class WorkPreparationTasksTableComponent implements OnInit {

  activeWVBTask?: WVBTask;
  activeServiceRequest?: ServiceRequest; //For filtering
  allWVBTasks: WVBTask[] = [];
  perPage: number;
  activePage: number;
  paginatedActiveWVBTasks?: [WVBTask[]]
  selectedPreset: WVBTaskPreset | undefined
  allPresets: WVBTaskPreset[] = []
  // Sort Related
  sortedBy?: string;
  prevSort?: string;
  sortedAscending?: boolean;
  showClosedTasks?: boolean;
  showPerformedTasksKey: string = "SHOWPERFORMED_WVBTASKS";
  perPageStorageKey: string = "WVB_TASKS_PERPAGE";

  // Show Description of Tasks in this array
  showDescriptionTaskIds: string[] = []

  // View Only (Mainly used for Service-Request-Edit)
  @Input() viewOnly = false;

  // Show All Mode (Used at top of work-prep-dashboard)
  @Input() showAllMode = false;

  allDepartments = [
    {'name': 'Engineering'},
    {'name': 'Elektro'},
    {'name': 'Inkoop'},
    {'name': 'Bedrijfsbureau'},
    {'name': 'Plaatwerkerij'},
    {'name': 'Machinebouw'},
    {'name': 'Kwaliteit'},
    {'name': 'Service'},
    {'name': 'Magazijn'},
    {'name': 'Finance'},
    {'name': 'Verkoop'},
    {'name': 'ICT'},
    {'name': 'Operations'},
    {'name': 'GeneralManagement'},
  ]
  selectedDepartments = [
    {'name': 'Engineering'},
    {'name': 'Elektro'},
    {'name': 'Inkoop'},
    {'name': 'Bedrijfsbureau'},
    {'name': 'Plaatwerkerij'},
    {'name': 'Machinebouw'},
    {'name': 'Kwaliteit'},
    {'name': 'Service'},
    {'name': 'Magazijn'},
    {'name': 'Finance'},
    {'name': 'Verkoop'},
    {'name': 'ICT'},
    {'name': 'Operations'},
    {'name': 'GeneralManagement'},
  ]

  isViewer = false
  @Input() newWVBTask?: WVBTask

  // The Subscriptions to Unsub on destroy
  subs: Subscription[] = [];

  constructor(public communicationService: CommunicationService, public serviceRequestService: ServiceRequestService, public wvbTaskService: WvbTaskService,
    public wvbTaskPresetService: WvbTaskPresetService, private modalService: ModalService
  ) { 
    this.perPage = Number(localStorage.getItem(this.perPageStorageKey) ?? 20);
    this.activePage = 0;
    this.sortedBy = "deadline"
    this.sortedAscending = true;
    this.selectedDepartments = this.allDepartments
    this.showClosedTasks = localStorage.getItem(this.showPerformedTasksKey) == "true";

    this.wvbTaskPresetService.getWVBTaskPresets().then(presets => {
      this.allPresets = presets
      this.selectedPreset = presets[0]
    })
  }
 

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe()
    })
  }

  ngOnInit(): void {
    this.subs.push(
      this.communicationService.activeWVBTasks.subscribe(activeWVBTaskList => {
      if (!activeWVBTaskList) {
        return;
      }

      this.allWVBTasks = activeWVBTaskList;
      this.paginateActiveWVBTasks();
      })
    );

    this.subs.push(
      this.communicationService.activeWVBTask.subscribe(activeWVBTask => {
        this.activeWVBTask = activeWVBTask;
      })
    )

    // We do not want to filter SRQ in showallmode
    if(!this.showAllMode) {
      this.subs.push(
        this.communicationService.activeServiceRequest.subscribe(activeServiceRequest => {
          this.activeServiceRequest = activeServiceRequest;
          this.paginateActiveWVBTasks();
        })
      )
    }

    if(this.sortedBy){
      this.sortWVBTasksBy(this.sortedBy, false);
    }
  }

  onPerPageChanged(){
    localStorage.setItem(this.perPageStorageKey, this.perPage?.toString());
    this.paginateActiveWVBTasks();
  }

  /**
   * This method remove the Service Request filter and repaginate all present WVBTasks
   * @returns 
   */
  removeServiceRequestFilter() {
    this.communicationService.activeServiceRequest.next(undefined)
    this.paginateActiveWVBTasks()
  }

  toggleShowPerformedTasks(){
    localStorage.setItem(this.showPerformedTasksKey, `${this.showClosedTasks}`);
    this.paginateActiveWVBTasks();
  }

  /**
   * This method will paginate all present WVBTasks
   * @returns 
   */
  paginateActiveWVBTasks(){
    if (!this.allWVBTasks) {
      return;
    }
   
    //Filter selected ServiceRequest iff not undefined
    let filteredWVBTasks = !!this.activeServiceRequest? this.allWVBTasks.filter(t => t.serviceRequestId == this.activeServiceRequest?.$id) : this.allWVBTasks
    
    // Filter Department
    let selectedDepartmentArr = this.selectedDepartments.map(s => s.name);
    filteredWVBTasks = filteredWVBTasks.filter(t => selectedDepartmentArr.indexOf(t.department) != -1)

    // Filter for Archived
    if(!this.showClosedTasks){
      filteredWVBTasks = filteredWVBTasks.filter(t => !t.archived)
    }

    // If list is empty then clear paginated list
    if (filteredWVBTasks.length == 0) {
      this.paginatedActiveWVBTasks = [[]];
    }

    for (var i = 0; i < filteredWVBTasks.length / this.perPage; i++) {
      if (i == 0) {
        this.paginatedActiveWVBTasks = [filteredWVBTasks.slice(i * this.perPage, i * this.perPage + this.perPage)];
        continue;
      }

      if (!this.paginatedActiveWVBTasks) {
        return;
      }

      this.paginatedActiveWVBTasks.push(filteredWVBTasks.slice(i * this.perPage, i * this.perPage + this.perPage));
    }

    if (!this.paginatedActiveWVBTasks) {
      return;
    }

    if (this.activePage >= this.paginatedActiveWVBTasks?.length) {
      this.activePage = 0;
    }

  }

    /**
   * When click somewhere on the screen deativate open pop-ups
   */
    onClickLostFocus() {
      if (this.communicationService.openStatusWVBTaskIdChanged) {
        this.communicationService.openStatusWVBTaskIdChanged = false;
        return;
      }
  
      this.communicationService.openStatusWVBTaskIdChanged = false;
      this.communicationService.openStatusWVBTaskId = undefined;
    }

  /**
   * This method will set the ServiceRequest id to the given id so that the other status line could be accessed
   * @param wvbTaskId 
   */
  onStatusExpanderClick(wvbTaskId: string | undefined) {
    this.communicationService.openStatusWVBTaskIdChanged = true;
    if (this.communicationService.openStatusWVBTaskId == wvbTaskId) {
      this.communicationService.openStatusWVBTaskId = undefined;
    }
    this.communicationService.openStatusWVBTaskId = wvbTaskId;
  }

  /**
   * This method will return the color of a status
   * @param status 
   * @returns 
   */
  getStatusColor(status: string) {
    if (!status) {
      return;
    }

    let statusResource = this.wvbTaskService?.wvbTaskStatus.filter(s => s.value == status);

    if (!statusResource || statusResource?.length == 0) {
      return;
    }

    return statusResource[0].color;
  }

  /**
   * This method will return the icon resource based on the status
  */
  getStatusIcon(type: string) {
    if (!type) {
      return;
    }

    let statusResource = this.wvbTaskService?.wvbTaskStatus.filter(s => s.value == type);

    if (!statusResource || statusResource?.length == 0) {
      return;
    }

    return statusResource[0].iconSource;
  }

  /**
   * This method will update the ServiceRequests status
   * @param ServiceRequests 
   */
  statusSelectionChanged(wvbTask: WVBTask, status: string) {
    if (!wvbTask || !status) {
      return;
    }
    
    // Change status
    var currentStatus = wvbTask.status + "";
    wvbTask.status = status;
    wvbTask.archived = status == "Uitgevoerd";

    this.wvbTaskService.updateWVBTask(wvbTask).then(() => {
        this.communicationService.activeToastKind.next("Success");
        this.communicationService.activeToastMessage.next(`Het updaten van de status van de taak ${wvbTask?.name} is gelukt!`);
        this.paginateActiveWVBTasks();
      }).catch(() => {
        this.communicationService.activeToastKind.next("Error");
        this.communicationService.activeToastMessage.next(`Het updaten van de status van de taak ${wvbTask?.name} is mislukt!`);
        wvbTask.status = currentStatus;
      });

  }

    /**
   * This method will sort the set of ServiceRequests ASC or DESC based on the given input
   * @param sortBy 
   */
  sortWVBTasksBy(column: string, toggle: boolean = true){
    this.sortedBy = column;
    this.prevSort = column;

    if (column == "name") {
      if (this.sortedBy == column && this.sortedAscending) {
        if(toggle){
          this.sortedAscending = false;
        }

        this.allWVBTasks.sort(function (a: WVBTask, b: WVBTask) {
          var textA = b.name?.toUpperCase() +  b.name?.toUpperCase();
          var textB = a.name?.toUpperCase() +  a.name?.toUpperCase();

          if (!textA) {
            textA = "";
          }

          if (!textB) {
            textB = "";
          }

          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.paginateActiveWVBTasks();
        return;
      }

      this.allWVBTasks.sort(function (a: WVBTask, b: WVBTask) {
        var textA = a.name?.toUpperCase() +  a.name?.toUpperCase();
        var textB = b.name?.toUpperCase() +  b.name?.toUpperCase();

        if (!textA) {
          textA = "";
        }

        if (!textB) {
          textB = "";
        }

        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      if(toggle){
        this.sortedAscending = true;
      }
      this.sortedBy = column;
    } else if (column == "department") {
      if (this.sortedBy == column && this.sortedAscending) {
     
        if(toggle){
          this.sortedAscending = false;
        }

        this.allWVBTasks.sort(function (a: WVBTask, b: WVBTask) {
          var textA = b.department?.toUpperCase();
          var textB = a.department?.toUpperCase();

          if (!textA) {
            textA = "";
          }

          if (!textB) {
            textB = "";
          }

          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.paginateActiveWVBTasks();
        return;
      }

      this.allWVBTasks.sort(function (a: WVBTask, b: WVBTask) {
        var textA = a.department?.toUpperCase();
        var textB = b.department?.toUpperCase();

        if (!textA) {
          textA = "";
        }

        if (!textB) {
          textB = "";
        }

        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    
      if(toggle){
        this.sortedAscending = true;
      }

      this.sortedBy = column;
    } else if (column == "status") {
      if (this.sortedBy == column && this.sortedAscending) {
     
        if(toggle){
          this.sortedAscending = false;
        }

        this.allWVBTasks.sort(function (a: WVBTask, b: WVBTask) {
          var textA = b.status?.toUpperCase();
          var textB = a.status?.toUpperCase();

          if (!textA) {
            textA = "";
          }

          if (!textB) {
            textB = "";
          }

          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.paginateActiveWVBTasks();
        return;
      }

      this.allWVBTasks.sort(function (a: WVBTask, b: WVBTask) {
        var textA = a.status?.toUpperCase();
        var textB = b.status?.toUpperCase();

        if (!textA) {
          textA = "";
        }

        if (!textB) {
          textB = "";
        }

        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    
      if(toggle){
        this.sortedAscending = true;
      }

      this.sortedBy = column;
    } if (column == "deadline") {
      if (this.sortedBy == column && this.sortedAscending) {
        
        if(toggle){
          this.sortedAscending = false;
        }

        this.allWVBTasks.sort((a: WVBTask, b: WVBTask) => {

          let diff = new Date(b.deadline).getTime() - new Date(a.deadline).getTime()
          // In case the values are equal to prevent from random order (spinning)
          if(diff == 0){
            return (a.$id < b.$id) ? -1 : (a.$id > b.$id) ? 1 : 0;
          }
          return diff ;
        });
        this.paginateActiveWVBTasks();
        return;
      }

      // Sort Ascending
      this.allWVBTasks.sort((a: WVBTask, b: WVBTask) => {

        let diff = new Date(a.deadline).getTime() - new Date(b.deadline).getTime()
        // In case the values are equal to prevent from random order (spinning)
        if(diff == 0){
          return (a.$id < b.$id) ? -1 : (a.$id > b.$id) ? 1 : 0;
        }

        return diff
      });

      if(toggle){
        this.sortedAscending = true;
      }

      this.sortedBy = column;
    }

    this.paginateActiveWVBTasks();
  }

  /**
   * This method will create a new empty WVBTask
   */
  createWVBTask(){
    this.activeWVBTask = undefined;

    if (!this.activeServiceRequest?.$id) {
      return
    }

    this.newWVBTask = {
      $id: "",
      $collectionId: "",
      $createdAt: "",
      $databaseId: "",
      $permissions: [],
      $updatedAt: "",
      name: "",
      status: "To Do",
      description: "",
      serviceRequestId: this.activeServiceRequest.$id,
      department: "Engineering",
      deadline: moment(this.activeServiceRequest.startTime).toDate(),
      archived: false
    }

    this.communicationService.activeWVBTask.next(this.newWVBTask)

    // Scroll new-section into view
    setTimeout(() => {
      document.getElementById('new-wvb-task')?.scrollIntoView();
    }, 200);
  }

  /**
   * This method will remove a ServiceRequest or show a cofirmation button
   * @param serviceRequest 
   * @param index 
   * @param confirmationNeeded 
   * @returns 
   */
  async removeWVBTask(wvbTask: WVBTask, index: number, confirmationNeeded: boolean) {
    if (confirmationNeeded) {
      var confirmButton = document.getElementById("WVBTconfirm-remove" + index);
      var cancelButton = document.getElementById("WVBTcancel-remove" + index);
      var removeButton = document.getElementById("WVBTremove" + index);
      var editButton = document.getElementById("WVBTedit" + index);

      if (editButton) {
        editButton.style.display = "none";
      }

      if (confirmButton && removeButton && cancelButton) {
        confirmButton.style.display = "flex";
        cancelButton.style.display = "flex";
        removeButton.style.display = "none";
      }
      return;
    }

    // Remove ServiceRequest from the database
    this.wvbTaskService.deleteWVBTask(wvbTask).then(() => {
      // Update set of active ServiceRequest if removal was successfull
      this.allWVBTasks = this.allWVBTasks?.filter(m => m != wvbTask);
      this.communicationService.activeWVBTasks.next(this.allWVBTasks);
      
      this.communicationService.activeToastKind.next("Success");
      this.communicationService.activeToastMessage.next(`Het verwijderen van serviceverzoek ${wvbTask?.name} is gelukt!`);
    }).catch(() => {
      this.communicationService.activeToastKind.next("Error");
      this.communicationService.activeToastMessage.next(`Het verwijderen van serviceverzoek ${wvbTask?.name} is mislukt!`);
    });
  }

   /**
   * This methow will cancel a remove action
   * @param index 
   */
   cancelRemoval(index: number) {
    var confirmButton = document.getElementById("WVBTconfirm-remove" + index);
    var cancelButton = document.getElementById("WVBTcancel-remove" + index);
    var removeButton = document.getElementById("WVBTremove" + index);
    var editButton = document.getElementById("WVBTedit" + index);

    if (confirmButton && removeButton && cancelButton && editButton) {
      confirmButton.style.display = "none";
      cancelButton.style.display = "none";
      removeButton.style.display = "inline-block";
      editButton.style.display = "inline-block";
    }
  }

  /**
   * This method will set the active ServiceRequest which will be shown in the detail screen
   * @param serviceRequest 
   */
  editWVBTask(wvbTask: WVBTask){
    this.communicationService.activeWVBTask?.next(wvbTask);

    this.newWVBTask = wvbTask

    // Scroll new-section into view
    setTimeout(() => {
      document.getElementById('new-wvb-task')?.scrollIntoView();
    }, 200);
  }

  /**
   * This method will paginate all present WVBTasks
   * @returns 
   */
  filterDepartments(event: any) {
    this.paginateActiveWVBTasks()
  }

  /**
   * This method will create a new empty WVBTask
   */
  insertPreset(){
    if (!this.activeServiceRequest?.$id) {
      return
    }
    let serviceRequest = this.activeServiceRequest

    this.selectedPreset?.wVBTasks.forEach(pTask => {
      if(!pTask.name || !pTask.description || !pTask.status || !pTask.department) {
        console.error("Missing pTask Info")
        return
      }
      this.wvbTaskService.addWVBTask({
        $id: "",
        $collectionId: "",
        $createdAt: "",
        $databaseId: "",
        $permissions: [],
        $updatedAt: "",
        name: pTask.name,
        status: pTask.status,
        description: pTask.description,
        serviceRequestId: serviceRequest.$id,
        department: pTask.department,
        deadline: moment(serviceRequest.startTime).toDate(),
        archived: false
      }).then(res => {
        // Refresh list
        let activeWVBTasks = this.communicationService.activeWVBTasks.getValue();
        activeWVBTasks.push(res as WVBTask);
        this.communicationService.activeWVBTasks.next(activeWVBTasks);
      })
    })

    this.communicationService.activeToastKind.next("Success");
    this.communicationService.activeToastMessage.next(`Het toevoegen van de Preset ${this.selectedPreset?.name} is gelukt!`);
    
  }

  toggleShowTaskDescription(task: WVBTask) {
    // Append Id. 
    // Look if ID in this array in view to determine whether or not 
    // To display Description.
    let idx = this.showDescriptionTaskIds.indexOf(task.$id)
    if (idx > -1) {
      this.showDescriptionTaskIds.splice(idx, 1)
    } else {
      this.showDescriptionTaskIds.push(task.$id)
    }
  }

  openLogBook(wvbTask: WVBTask) {
    this.modalService.open(LogBookComponent, {
      modal: {
        enter: 'enter-scale-down 0.1s ease-out',
      },
      overlay: {
        leave: 'fade-out 0.3s',
      },
      data: {
        logEntries: wvbTask.logEntries,
      },
    })
  }

}

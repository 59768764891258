import { Injectable } from '@angular/core';
import { ID, Permission, Query, Role } from 'appwrite';
import { Api } from 'src/app/helpers/api';
import { PersonalData } from 'src/app/models/personal-data';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PersonalDataService {

  personalDataTable: string = "PersonalData";

  constructor() { }

  /**
   * This method will retrieve the personal data for the given resource
   */
  async getPersonalData(resourceId: string){
    var personalData = await Api.database.listDocuments<PersonalData>(environment.database, this.personalDataTable, [Query.limit(1), Query.equal('userId', resourceId)]);

    if(personalData.documents?.length > 0){
      return personalData.documents[0];
    }

    return undefined;
  }

  /**
  * This method will create a new PersonalData in the database
  */
  addPersonalData(personalData: PersonalData, teamId: string, writePermissions: string[]) {
    return Api.database.createDocument(environment.database, this.personalDataTable, ID.unique(), this.createPartialPersonalData(personalData))
  }

  /**
   * This method will update an existing PersonalData in the Database
   */
  updatePersonalData(personalData: PersonalData) {
    return Api.database.updateDocument(environment.database, this.personalDataTable, personalData.$id, this.createPartialPersonalData(personalData));
  }

  /**
   * This method will delete an existing PersonalData from the database
   */
  deletePersonalData(personalData: PersonalData) {
    return Api.database.deleteDocument(environment.database, this.personalDataTable, personalData.$id);
  }

  /**
   * Creates a partial instance of the mechanic without AppWrite properties
   * @param vehicle 
   * @returns 
   */
  private createPartialPersonalData(personalData: PersonalData): Partial<PersonalData> {
    if (!personalData) {
      return {};
    }

    return {
      emailAdress: personalData.emailAdress,
      firstname: personalData.firstname,
      lastname: personalData.lastname,
      userId: personalData.userId,
      phoneNumber: personalData.phoneNumber
    };
  }
}

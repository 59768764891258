<div class="container" (click)="onClickLostFocus()">
    <div class="row initial-row">
        <div class="col card dashboard-header">
            <div class="card-body">
                <h3>Beheer Monteurs</h3>
                <span>Welkom in de <strong>Beheer Monteurs module</strong>. <br> Beheer hier monteurs en de beschikbaarheid ervan.</span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="div card">
            <div class="card-body">
                <div class="row">
                    <h4 class="col card-title">Monteurs</h4>
                    <div class="col-4 search">
                        <i class="bi bi-search" data-toggle="tooltip" data-placement="top" title="Zoeken op: Naam, status, merk..."></i>
                        <input [(ngModel)]="searchInput" (ngModelChange)="searchMechanics()" class="form-control" type="text" placeholder="Zoeken...">
                    </div>
                </div>
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width: 20%" class="sortable" scope="col" (click)="sortMechanicsBy('name')">Voor- en achternaam
                                <i class="bi bi-caret-down-fill" *ngIf="sortedBy == 'name' && sortedAscending"></i>
                                <i class="bi bi-caret-up-fill" *ngIf="sortedBy == 'name' && !sortedAscending"></i>
                            </th>
                            <th style="width: 25%" class="sortable" scope="col">Competenties
                                <i class="bi bi-caret-down-fill" *ngIf="sortedBy == 'competentions' && sortedAscending"></i>
                                <i class="bi bi-caret-up-fill" *ngIf="sortedBy == 'competentions' && !sortedAscending"></i>
                            </th>
                            <th style="width: 13%" class="sortable" scope="col" (click)="sortMechanicsBy('type')">Type
                                <i class="bi bi-caret-down-fill" *ngIf="sortedBy == 'type' && sortedAscending"></i>
                                <i class="bi bi-caret-up-fill" *ngIf="sortedBy == 'type' && !sortedAscending"></i>
                            </th>
                            <th style="width: 8%" class="sortable" scope="col" (click)="sortMechanicsBy('availability')">Status
                                <i class="bi bi-caret-down-fill" *ngIf="sortedBy == 'availability' && sortedAscending"></i>
                                <i class="bi bi-caret-up-fill" *ngIf="sortedBy == 'availability' && !sortedAscending"></i>
                            </th>
                            <th style="width: 15%" scope="col">Acties</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="paginatedActiveMechanics">
                        <tr *ngFor="let mechanic of paginatedActiveMechanics[activePage]; index as i" [ngClass]="{'active-mechanic': activeMechanic == mechanic}">
                            <td>{{mechanic.personalData?.firstname}} {{mechanic.personalData?.lastname}}</td>
                            <td class="competentions">
                                <div class="competention" *ngFor="let competention of mechanic.competentions?.slice(0, 2)"><span>{{competention.name}}</span></div>
                                <div class="competention" *ngIf="(mechanic.competentions?.length ?? 0) > 2"><span>+{{mechanic.competentions!.length - 2}}</span></div>
                                <div class="competention" *ngIf="(mechanic.competentions?.length ?? 0) == 0"><span>Onbekend</span></div>
                            </td>
                            <td>{{mechanic.type}}</td>
                            <td class="status-line">
                                <div class="status-badge-section">
                                    <span class="badge badge-primary" (click)="onStatusExpanderClick(mechanic.$id)" [ngStyle]="{'background-color': getStatusColor(mechanic.available)}">
                                        <i class="{{mechanicService.getStatusIcon(mechanic.available)}}"></i>
                                        {{mechanic.available}}
                                    </span>
                                    <div (click)="onStatusExpanderClick(mechanic.$id)" *ngIf="!isViewer">
                                        <i class="bi bi-caret-down-fill"></i>
                                    </div>
                                    <div class="other-status" *ngIf="communicationService.openStatusMechanicId == mechanic.$id && communicationService.openStatusMechanicId && !isViewer">
                                        <div class="row" *ngFor="let statusItem of mechanicService.mechanicStatusItems">
                                            <div class="col">
                                                <span (click)="statusSelectionChanged(mechanic, statusItem.value)" class="badge badge-primary" [ngStyle]="{'background-color': getStatusColor(statusItem.value)}">
                                                    <i class="{{mechanicService.getStatusIcon(statusItem.value)}}"></i>
                                                    {{statusItem.description}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="mechanic-actions">
                                <button class="btn btn-primary action-button first-button" id="edit{{i}}" (click)="editMechanic(mechanic)">
                                    <i class="bi bi-pencil"></i>
                                </button>
                                <!-- Remove -->
                                <button *ngIf="!isViewer" class="btn btn-danger remove-button" id="remove{{i}}" (click)="removeMechanic(mechanic, i, true)">
                                    <i class="bi bi-trash"></i>
                                </button>
                                <button *ngIf="!isViewer" class="btn btn-danger remove-confirmation" id="confirm-remove{{i}}" (click)="removeMechanic(mechanic, i, false)">Bevestig</button>
                                <button *ngIf="!isViewer" class="btn btn-primary action-button cancel-remove" id="cancel-remove{{i}}" (click)="cancelRemoval(i)"><i class="bi bi-x-circle"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="table-footer">
                    <div class="no-mechanics" *ngIf="allEstablishmentMechanics.length == 0">
                        <div *ngIf="loadingMechanics" class="loading-section">
                            <span class="loader"></span>
                            <span class="loader-text">Monteurs worden geladen...</span>
                        </div>
                    </div>
                    <div class="row" *ngIf="lazyLoadingMechanics && !loadingMechanics">
                        <div class="col lazy-loading">
                            <span class="lazy-loader"></span>
                            <span class="still-loading">Meer Monteurs aan het ophalen...</span>
                        </div>
                    </div>
                    <div class="table-footer-actions" [ngClass]="{'align-right': allEstablishmentMechanics.length == 0 || !allEstablishmentMechanics}">
                        <nav aria-label="Page navigation" *ngIf="allEstablishmentMechanics?.length ?? 0 > 0">
                            <ul class="pagination">
                                <li class="page-item" (click)="activePage = activePage - 1">
                                    <a class="page-link" aria-label="Previous">
                                        <span aria-hidden="true">&laquo;</span>
                                    </a>
                                </li>
                                <li class="page-item" (click)="activePage = i" *ngFor="let subSet of paginatedActiveMechanics; let i = index">
                                    <a class="page-link active-page" *ngIf="i == activePage">{{i + 1}}</a>
                                    <a class="page-link" *ngIf="i != activePage">{{i + 1}}</a>
                                </li>
                                <li class="page-item" (click)="activePage = activePage + 1">
                                    <a class="page-link" aria-label="Next">
                                        <span aria-hidden="true">&raquo;</span>
                                    </a>
                                </li>
                            </ul>
                            <div class="per-page">
                                <div class="form-group">
                                    <select class="form-select" [(ngModel)]="perPage" (ngModelChange)="onPerPageChanged()">
                                        <option [ngValue]="5"><span>5</span></option>
                                        <option [ngValue]="10"><span>10</span></option>
                                        <option [ngValue]="20"><span>20</span></option>
                                        <option [ngValue]="50"><span>50</span></option>
                                    </select>
                                </div>
                                <span>Monteurs per pagina</span>
                            </div>
                        </nav>
                        <div class="mechanic-buttons">
                            <div class="create-mechanic">
                                <div class="btn-group template-mechanic" role="group">
                                    <button class="btn btn-success new-mechanic" [ngClass]="{'glowing-actions': allEstablishmentMechanics.length == 0 || !allEstablishmentMechanics}" *ngIf="!isViewer" (click)="createNewMechanic()">
                                        <i class="bi bi-plus-square"></i>
                                        <span>Nieuwe monteur</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-mechanic-availability *ngIf="activeMechanic" [mechanic]="activeMechanic"></app-mechanic-availability>
    <app-mechanic-details *ngIf="activeMechanic" id="edit-mechanic" [mechanic]="activeMechanic"></app-mechanic-details>
    <app-mechanic-create *ngIf="newMechanic" id="add-mechanic" [mechanic]="newMechanic"></app-mechanic-create>
</div>
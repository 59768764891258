import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  activeVersion: string;

  constructor() {
    this.activeVersion = "0.0.20";
  }
}

<div>
    <span class="fw-bold">{{logEntry?.by}}</span>
    @if (!!logEntry?.$createdAt) {
        <span class="float-end">{{logEntry?.$createdAt | date: 'dd-MM-yyyy - HH:mm'}}</span>
    } @else {
        <span class="float-end">Recent</span>
    }
    <p [ngClass]="{'mb-0': inlineComponent}">{{logEntry?.message}}</p>
    @if(!inlineComponent){
        <hr>
    }
</div>
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Tool } from 'src/app/models/tool';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { ToolService } from 'src/app/services/tool/tool.service';

@Component({
  selector: 'app-tool-dashboard',
  templateUrl: './tool-dashboard.component.html',
  styleUrls: ['./tool-dashboard.component.scss']
})
export class ToolDashboardComponent implements OnInit {


  searchInput: string = "";
  sortedBy?: string;
  prevSort?: string;
  sortedAscending?: boolean;
  loadingTools?: boolean = false;
  lazyLoadingTools?: boolean = false;
  paginatedActiveTools?: [Tool[]];
  allEstablishmentTools: Tool[] = [];
  isViewer?: boolean;
  perPage: number;
  activePage: number;
  activeTool?: Tool;
  newTool?: Tool;
  perPageStorageKey: string = "TOOLS_VEHICLES_PERPAGE";

  // The Subscriptions to Unsub on destroy
  subs: Subscription[] = [];

  constructor(public communicationService: CommunicationService, public toolService: ToolService) { 
    this.perPage = Number(localStorage.getItem(this.perPageStorageKey) ?? 20);
    this.activePage = 0;
    this.sortedBy = "name"
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe()
    })
  }

  ngOnInit(): void {
    this.subs.push(
      this.communicationService.activeTools.subscribe(activeToolList => {
        if (!activeToolList) {
          return;
        }
        
        this.allEstablishmentTools = activeToolList;
        this.paginateActiveTools();
      })
    )

    this.subs.push(
      this.communicationService.activeTool.subscribe(activeTool => {
        this.activeTool = activeTool;
      })
    )

    if(this.sortedBy){
      this.sortToolsBy(this.sortedBy, false);
    }
  }

  onPerPageChanged(){
    localStorage.setItem(this.perPageStorageKey, this.perPage?.toString());
    this.paginateActiveTools();
  }

  /**
   * This method will return the name that belongs to the given Id
   * @param tool 
   * @returns 
   */
  getAttachedToName(tool: Tool): string{
    var activeVehicles = this.communicationService.activeVehicles?.getValue();

    var vehicle = activeVehicles?.filter(v => v.$id == tool.attachedToId);

    if(vehicle?.length > 0){
      tool.attachedToName = vehicle[0].name;
      return tool.attachedToName;
    }

    return "Onbekend";
  }
  
  /**
   * This method will create a new tool to fill in the Create-Component
   */
  createNewTool(){
    this.activeTool = undefined;
    this.newTool = {
      $id: "1",
      $collectionId: "",
      $createdAt: "",
      $databaseId: "",
      $permissions: [],
      $updatedAt: "",
      available: "Beschikbaar",
      name: "",
      type: "Overig"
    };
  }

  /**
   * This method will search in the present set of Tools
   * based on the given search input
   * @returns 
   */
  searchTools(){
    if (!this.searchInput || this.searchInput == "") {
      this.allEstablishmentTools.forEach(t => t.notMatchingFilter = false);
      this.paginateActiveTools();
      return;
    }


    this.allEstablishmentTools.forEach(tool => {
      var matchingAttributeFound = false;
      if([tool].filter(
        tool =>
          tool.name?.toLowerCase().includes(this.searchInput.toLowerCase()) ||
          tool.brand?.toLowerCase().includes(this.searchInput.toLowerCase()) ||
          tool.checkBeforeDate?.toString()?.toLowerCase().includes(this.searchInput.toLowerCase()) ||
          tool.attachedToName?.toString()?.toLowerCase().includes(this.searchInput.toLowerCase()) ||
          tool.purchaseDate?.toString()?.toLowerCase().includes(this.searchInput.toLowerCase()) ||
          tool.available?.toLowerCase().includes(this.searchInput.toLowerCase())).length > 0){
            tool.notMatchingFilter = false;
            matchingAttributeFound = true;
          }
      

    if(!matchingAttributeFound){
          tool.notMatchingFilter = true;
        }
      });

    this.paginateActiveTools();
  }

  /**
   * This method will set the active Tool which will be shown in the detail screen
   * @param tool 
   */
  editTool(tool: Tool){
    this.activeTool = tool;
    this.newTool = undefined;
    this.communicationService.activeTool?.next(tool);
  }

  /**
   * This method will sort the set of tools ASC or DESC based on the given input
   * @param sortBy 
   */
  sortToolsBy(column: string, toggle: boolean = true){
    this.sortedBy = column;
    this.prevSort = column;

    if (column == "name") {
      if (this.sortedBy == column && this.sortedAscending) {
        if(toggle){
          this.sortedAscending = false;
        }

        this.allEstablishmentTools.sort(function (a: Tool, b: Tool) {
          var textA = b.name?.toUpperCase();
          var textB = a.name?.toUpperCase();

          if (!textA) {
            textA = "";
          }

          if (!textB) {
            textB = "";
          }

          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.paginateActiveTools();
        return;
      }

      this.allEstablishmentTools.sort(function (a: Tool, b: Tool) {
        var textA = a.name?.toUpperCase();
        var textB = b.name?.toUpperCase();

        if (!textA) {
          textA = "";
        }

        if (!textB) {
          textB = "";
        }

        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      if(toggle){
        this.sortedAscending = true;
      }
      this.sortedBy = column;
    }
    else if (column == "availability") {
      if (this.sortedBy == column && this.sortedAscending) {
        
        if(toggle){
          this.sortedAscending = false;
        }

        this.allEstablishmentTools.sort(function (a: Tool, b: Tool) {
          var textA = b.available?.toUpperCase();
          var textB = a.available?.toUpperCase();

          if (!textA) {
            textA = "";
          }

          if (!textB) {
            textB = "";
          }

          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.paginateActiveTools();
        return;
      }

      this.allEstablishmentTools.sort(function (a: Tool, b: Tool) {
        var textA = a.available?.toUpperCase();
        var textB = b.available?.toUpperCase();

        if (!textA) {
          textA = "";
        }

        if (!textB) {
          textB = "";
        }

        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      
      if(toggle){
        this.sortedAscending = true;
      }

      this.sortedBy = column;
    } 
    else  if (column == "attached to") {
      if (this.sortedBy == column && this.sortedAscending) {
        if(toggle){
          this.sortedAscending = false;
        }

        this.allEstablishmentTools.sort(function (a: Tool, b: Tool) {
          var textA = b.attachedToName?.toUpperCase();
          var textB = a.attachedToName?.toUpperCase();

          if (!textA) {
            textA = "";
          }

          if (!textB) {
            textB = "";
          }

          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.paginateActiveTools();
        return;
      }

      this.allEstablishmentTools.sort(function (a: Tool, b: Tool) {
        var textA = a.attachedToName?.toUpperCase();
        var textB = b.attachedToName?.toUpperCase();

        if (!textA) {
          textA = "";
        }

        if (!textB) {
          textB = "";
        }

        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      if(toggle){
        this.sortedAscending = true;
      }
      this.sortedBy = column;
    }
    else  if (column == "check before") {
      if (this.sortedBy == column && this.sortedAscending) {
        if(toggle){
          this.sortedAscending = false;
        }

        this.allEstablishmentTools.sort(function (a: Tool, b: Tool) {
          var textA = b.checkBeforeDate?.getTime() ?? 0;
          var textB = a.checkBeforeDate?.getTime() ?? 0;

          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.paginateActiveTools();
        return;
      }

      this.allEstablishmentTools.sort(function (a: Tool, b: Tool) {
        var textA = a.checkBeforeDate?.getTime() ?? 0;
        var textB = b.checkBeforeDate?.getTime() ?? 0;

        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      if(toggle){
        this.sortedAscending = true;
      }
      this.sortedBy = column;
    }

    this.paginateActiveTools();
  }

  /**
   * This method will paginate all present Tools
   * @returns 
   */
  paginateActiveTools(){
    if (!this.allEstablishmentTools) {
      return;
    }

    this.allEstablishmentTools.forEach(tool => {
       tool.hideInList = false;
    })
    
    var filterdActiveTools = this.allEstablishmentTools.filter(t => !t.hideInList && !t.notMatchingFilter)

    // If list is empty then clear paginated list
    if (filterdActiveTools.length == 0) {
      this.paginatedActiveTools = [[]];
    }

    for (var i = 0; i < filterdActiveTools.length / this.perPage; i++) {
      if (i == 0) {
        this.paginatedActiveTools = [filterdActiveTools.slice(i * this.perPage, i * this.perPage + this.perPage)];
        continue;
      }

      if (!this.paginatedActiveTools) {
        return;
      }

      this.paginatedActiveTools.push(filterdActiveTools.slice(i * this.perPage, i * this.perPage + this.perPage));
    }

    if (!this.paginatedActiveTools) {
      return;
    }

    if (this.activePage >= this.paginatedActiveTools?.length) {
      this.activePage = 0;
    }
  }

  /**
     * This method will update the tool status
     * @param tool 
     */
  statusSelectionChanged(tool: Tool, status: string) {
    if (!tool || !status) {
      return;
    }
    
    var currentStatus = status + "";
    tool.available = status;

    this.toolService.updateTool(tool).then(() => {
      this.communicationService.activeToastKind.next("Success");
      this.communicationService.activeToastMessage.next(`De status van gereedschap ${tool?.name} veranderen is gelukt!`);
    }).catch(() => {
      tool.available = currentStatus;
      this.communicationService.activeToastKind.next("Error");
      this.communicationService.activeToastMessage.next(`De status van gereedschap ${tool?.name} veranderen is mislukt!`);
    });
  }

  /**
   * This method will remove a tool or show a cofirmation button
   * @param tool 
   * @param index 
   * @param confirmationNeeded 
   * @returns 
   */
  async removeTool(tool: Tool, index: number, confirmationNeeded: boolean) {
    if (confirmationNeeded) {
      var confirmButton = document.getElementById("confirm-remove" + index);
      var cancelButton = document.getElementById("cancel-remove" + index);
      var removeButton = document.getElementById("remove" + index);
      var editButton = document.getElementById("edit" + index);

      if (editButton) {
        editButton.style.display = "none";
      }

      if (confirmButton && removeButton && cancelButton) {
        confirmButton.style.display = "flex";
        cancelButton.style.display = "flex";
        removeButton.style.display = "none";
      }
      return;
    }

    this.toolService.deleteTool(tool).then(() => {
      var activeTools = this.communicationService.activeTools.getValue();
      this.communicationService.activeTools.next(activeTools.filter(v => v != tool));
      
      this.communicationService.activeToastKind.next("Success");
      this.communicationService.activeToastMessage.next(`Het verwijderen van gereedschap ${tool?.name} is gelukt!`);
    }).catch(() => {
      this.communicationService.activeToastKind.next("Error");
      this.communicationService.activeToastMessage.next(`Het verwijderen van gereedschap ${tool?.name} is mislukt!`);
    });
  }

  /**
   * This methow will cancel a remove action
   * @param index 
   */
  cancelRemoval(index: number) {
    var confirmButton = document.getElementById("confirm-remove" + index);
    var cancelButton = document.getElementById("cancel-remove" + index);
    var removeButton = document.getElementById("remove" + index);
    var editButton = document.getElementById("edit" + index);

    if (confirmButton && removeButton && cancelButton && editButton) {
      confirmButton.style.display = "none";
      cancelButton.style.display = "none";
      removeButton.style.display = "block";
      editButton.style.display = "block";
    }
  }

  /**
   * This method will return the color of a status
   * @param status 
   * @returns 
   */
  getStatusColor(status: string) {
    if (!status) {
      return;
    }

    var statusResource = this.toolService?.toolStatusItems.filter(s => s.value == status);

    if (!statusResource || statusResource?.length == 0) {
      return;
    }

    return statusResource[0].color;
  }

  /**
   * This method will set the tool id to the given id so that the other status line could be accessed
   * @param toolId 
   */
  onStatusExpanderClick(toolId: string | undefined) {
    this.communicationService.openStatusToolIdChanged = true;
    if (this.communicationService.openStatusToolId == toolId) {
      this.communicationService.openStatusToolId = undefined;
    }
    this.communicationService.openStatusToolId = toolId;
  }

  /**
   * When click somewhere on the screen deativate open pop-ups
   */
  onClickLostFocus() {
    if (this.communicationService.openStatusToolIdChanged) {
      this.communicationService.openStatusToolIdChanged = false;
      return;
    }

    this.communicationService.openStatusToolIdChanged = false;
    this.communicationService.openStatusToolId = undefined;
  }
}

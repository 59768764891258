<div class="container">
    <div class="row initial-row">
        <div class="col card dashboard-header">
            <div class="card-body">
                <h4>Dashboard</h4>
                <span>Welkom op het <strong>UNIKON Service dashboard</strong>. <br> Maak een keuze uit de sneltoetsen hieronder om te navigeren naar de gewenste module. Bekijken daarnaast basale statistieken over de actuele operatie. <br> Neem bij vragen contact op met de beheerder van de applicatie.</span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col card dashboard-header">
            <div class="card-body">
                <h4>Statistieken</h4>
                <div class="basic-stats">
                    <div class="stat-box">
                        <span>{{communicationService.activeServiceRequests.getValue().length}}</span>
                        <span>Service verzoeken</span>
                    </div>
                    <div class="stat-box" [ngStyle]="{'background-color': '#d98c73'}">
                        <span>{{serviceRequestsToSchedule}}</span>
                        <span>Te plannen verzoeken</span>
                    </div>
                    <div class="stat-box" [ngStyle]="{'background-color': '#87dede'}">
                        <span>{{serviceRequestsScheduled}}</span>
                        <span>Geplande verzoeken</span>
                    </div>
                    <div class="stat-box" [ngStyle]="{'background-color': '#bfd973'}">
                        <span>{{serviceRequestsPrepared}}</span>
                        <span>Voorbereidde verzoeken</span>
                    </div>
                    <div class="stat-box" [ngStyle]="{'background-color': '#de87de'}">
                        <span>{{serviceRequestsExecuted}}</span>
                        <span>Uitgevoerde verzoeken</span>
                    </div>
                    <div class="stat-box" [ngStyle]="{'background-color': '#87de9d'}">
                        <span>{{serviceRequestsFinished}}</span>
                        <span>Afgehandelde verzoeken</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row modules">
        <div class="card col">
            <div class="card-body">
                <h4>Modules</h4>
                <div class="row calls-to-actions">
                    <div class="col-2 call-to-action" routerLink="/service-requests-dashboard">
                        <i class="bi bi-calendar-week"></i>
                        <span>Inzetten</span>
                    </div>
                    <div class="col-2 call-to-action" routerLink="/mechanic-dashboard">
                        <i class="bi bi-person-fill-gear"></i>
                        <span>Beheer Monteurs</span>
                    </div>
                    <div class="col-2 call-to-action" routerLink="/vehicle-dashboard">
                        <i class="bi bi-truck"></i>
                        <span>Beheer Voertuigen</span>
                    </div>
                    <div class="col-2 call-to-action" routerLink="/tools-dashboard">
                        <i class="bi bi-wrench"></i>
                        <span>Beheer Voertuig Gereedschappen</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ContactPerson } from 'src/app/models/contact-person';
import { ServiceRequest } from 'src/app/models/service-request';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { ContactPersonService } from 'src/app/services/contact-person/contact-person.service';

@Component({
  selector: 'app-service-request-contact-persons',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './service-request-contact-persons.component.html',
  styleUrl: './service-request-contact-persons.component.scss'
})
export class ServiceRequestContactPersonsComponent implements OnInit {

  @Input() serviceRequest?: ServiceRequest;
  selectedContactPersons?: string[];
  activeContactPerson?: ContactPerson;
  feedbackMessage?: string;
  roles: any[] = [
    {
      value: 'Ter plaatse',
      description: 'Ter plaatse',
      id: 1,
    },
    {
      value: 'Administratief',
      description: 'Administratief',
      id: 2,
    },
    {
      value: 'Overig',
      description: 'Overig',
      id: 3,
    },
  ]

  constructor(private contactPersonService: ContactPersonService, private communicationService: CommunicationService){}

  ngOnInit(): void {
  }

  /**
   * This method will open the first Contact Person as contact person to edit
   */
  editContactPerson(){
    if((this.selectedContactPersons?.length ?? 0) > 1){
      // Present message
      this.feedbackMessage = "Selecteer maximaal 1 contact persoon om te kunnen wijzigen";

      setTimeout(() => {
        this.feedbackMessage = undefined;
      }, 10000);
      return;
    }

    if(this.selectedContactPersons?.length == 1){
      this.activeContactPerson = this.serviceRequest?.referencedMachine?.company?.contactPersons?.filter(c => c.$id == this.selectedContactPersons![0])[0];
    }
  }

  /**
   * This method will determine wether a Contact Person is attached to the current ServiceRequest or not
   * @param id
   */
  attachedToServiceRequest(id: string) : boolean{
    return this.serviceRequest?.contactPersonIds?.includes(id) ?? false;
  }

  /**
   * This method will attach all Contact Persons to the ServiceRequest if not attached yet
   */
  attachToServiceRequest(){
    this.selectedContactPersons?.forEach(id => {
      if(!this.serviceRequest){
        return;
      }

      if(this.serviceRequest.contactPersonIds?.includes(id)){
        return;
      }

      var attachedContactPersons = (this.serviceRequest.contactPersonIds?.split(",") ?? []);
      attachedContactPersons.push(id);
      this.serviceRequest.contactPersonIds = attachedContactPersons.join(",");
    });
  }

    /**
   * This method will dettach all Contact Persons to the ServiceRequest if not dettached yet
   */
  dettachToServiceRequest(){
    this.selectedContactPersons?.forEach(id => {
      if(!this.serviceRequest){
        return;
      }

      if(this.serviceRequest.contactPersonIds?.includes(id)){
        var attachedContactPersons = (this.serviceRequest.contactPersonIds?.split(",") ?? []);
        this.serviceRequest.contactPersonIds = attachedContactPersons.filter(c => c != id).join(",");
      }
    });
  }

  /**
   * This method will create a new Contact Person to add
   */
  newContactPerson(){
    this.activeContactPerson = {
      $id: "",
      $collectionId: "",
      $createdAt: "",
      $databaseId: "",
      $permissions: [],
      $updatedAt: "",
      role: 'Ter Plaatse'
    }
  }

  /**
   * This method will save changes to the open Contact Person
   */
  saveChanges(){
    if(!this.activeContactPerson){
      return;
    }

    this.activeContactPerson.companyId = this.serviceRequest?.referencedMachine?.company?.$id;

    if(this.activeContactPerson.$id){
      this.contactPersonService.updateContactPerson(this.activeContactPerson).then(() => {
        this.communicationService.activeToastKind.next("Success");
        this.communicationService.activeToastMessage.next(`Het updaten van contactpersoon '${this.activeContactPerson?.firstname ?? 'Onbekend'}' is gelukt!`);
      }).catch(() => {
        this.communicationService.activeToastKind.next("Error");
        this.communicationService.activeToastMessage.next(`Het updaten van contactpersoon '${this.activeContactPerson?.firstname ?? 'Onbekend'}' is mislukt!`);
      });
    } else {
      this.contactPersonService.addContactPerson(this.activeContactPerson).then((createdContactPerson) => {
        this.communicationService.activeToastKind.next("Success");
        this.communicationService.activeToastMessage.next(`Het aanmaken van contactpersoon '${this.activeContactPerson?.firstname ?? 'Onbekend'}' is gelukt!`);
        this.serviceRequest?.referencedMachine?.company?.contactPersons?.push(createdContactPerson);
      }).catch(() => {
        this.communicationService.activeToastKind.next("Error");
        this.communicationService.activeToastMessage.next(`Het aanmaken van contactpersoon '${this.activeContactPerson?.firstname ?? 'Onbekend'}' is mislukt!`);
      });
    }

    this.close();
  }

  /**
   * This method will close the open Edit/create window for Contact Person
   */
  close(){
    this.activeContactPerson = undefined;
  }

  /**
   * This method will remove a Contact Person from the current company
   */
  remove(){
    if(!this.activeContactPerson){
      return;
    }

    if(this.activeContactPerson.$id){
      this.contactPersonService.deleteContactPerson(this.activeContactPerson).then(() => {
        this.communicationService.activeToastKind.next("Success");
        this.communicationService.activeToastMessage.next(`Het verwijderen van contactpersoon '${this.activeContactPerson?.firstname ?? 'Onbekend'}' is gelukt!`);

        // Remove from the list of ContactPersons
        if( this.serviceRequest?.referencedMachine?.company){
          this.serviceRequest.referencedMachine.company.contactPersons = this.serviceRequest.referencedMachine.company.contactPersons?.filter(c => c != this.activeContactPerson);
        }

        this.close();
      }).catch(() => {
        this.communicationService.activeToastKind.next("Error");
        this.communicationService.activeToastMessage.next(`Het verwijderen van contactpersoon '${this.activeContactPerson?.firstname ?? 'Onbekend'}' is mislukt!`);
      });
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Tool } from 'src/app/models/tool';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { ToolService } from 'src/app/services/tool/tool.service';

@Component({
  selector: 'app-tool-create',
  templateUrl: './tool-create.component.html',
  styleUrls: ['./tool-create.component.scss']
})
export class ToolCreateComponent implements OnInit {

  @Input() tool?: Tool;
  attachableObjects: string[] = [];

  constructor(public toolService: ToolService, private communicationService: CommunicationService, private permissionService: PermissionService) { }

  ngOnInit(): void {
    this.attachableObjects = this.communicationService.activeVehicles?.getValue()?.map(v => v.$id);
  }

  /**
   * This method will return the name of the given object id
   * @param attachableObject 
   */
  getObjectName(attachableObject: string) : string{
    var matchingVehicles = this.communicationService.activeVehicles?.getValue()?.filter(v => v.$id == attachableObject);

    if(matchingVehicles?.length > 0){
      return matchingVehicles[0].name;
    }

    return "#";
  }

  /**
   * This method will save the current Tool to the Database
   */
  createTool(){
    if(!this.tool){
      return;
    }
    
    // Save Tool
    this.toolService.addTool(this.tool, this.communicationService.activeTeamId.getValue()!, this.permissionService.getWritePermissions()).then(tool => {
      if(this.tool){
        this.tool.$id = tool?.$id;
      }

      // Add to list of Active Tools
      var activeTools = this.communicationService.activeTools.getValue();
      activeTools.push(this.tool!);

      this.communicationService.activeToastKind.next("Success");
      this.communicationService.activeToastMessage.next(`Het aanmaken van gereedschap ${this.tool?.name} is gelukt!`);
    
      this.communicationService.activeTools.next(activeTools);
      this.tool = undefined;
    }).catch(() => {
      this.communicationService.activeToastKind.next("Error");
      this.communicationService.activeToastMessage.next(`Het aanmaken van gereedschap ${this.tool?.name} is mislukt!`);
    });
  }

  /**
   * This method will adjust the CheckBeforeDate
   * @param event 
   */
  onCheckBeforeDateValuesChanged(event: any){
    if(this.tool){
      this.tool.checkBeforeDate = moment(event).toDate();
    }
  }

  /**
   * This method will adjust the InspectionScheduledDate
   * @param event 
   */
  onInspectionScheduledDateValuesChanged(event: any){
    if(this.tool){
      this.tool.scheduledInspectionDate = moment(event).toDate();
    }
  }

}

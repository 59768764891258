import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommunicationService } from 'src/app/services/communication/communication.service';

@Component({
  selector: 'app-mechanic-planning-dashboard',
  templateUrl: './mechanic-planning-dashboard.component.html',
  styleUrls: ['./mechanic-planning-dashboard.component.scss']
})
export class MechanicPlanningDashboardComponent implements OnInit {

  serviceRequestsToSchedule: number = 0;
  serviceRequestsScheduled: number = 0;
  serviceRequestsPrepared: number = 0;
  serviceRequestsExecuted: number = 0;
  serviceRequestsFinished: number = 0;

  // The Subscriptions to Unsub on destroy
  subs: Subscription[] = [];

  constructor(public communicationService: CommunicationService) { }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe()
    })
  }

  ngOnInit(): void {
    this.subs.push(

      this.communicationService.activeServiceRequests.subscribe(serviceRequests => {
        
      // Determine the amount of Finished ServiceRequests
      this.serviceRequestsFinished = serviceRequests.filter(s => 
        s.status == "Afgehandeld").length;
        
      // Determine the amount of prepared ServiceRequests
      this.serviceRequestsPrepared = serviceRequests.filter(s => 
        s.status == "Voorbereid").length;
          
      // Determine the amount of Executed ServiceRequests
      this.serviceRequestsExecuted = serviceRequests.filter(s => 
        s.status == "Uitgevoerd").length;
        
      // Determine the amount of ServiceRequests which are scheduled
      this.serviceRequestsScheduled = serviceRequests.filter(s => 
        s.status == "Gepland").length;
        
      // Determine the amount of ServiceRequests which needs to be scheduled
      this.serviceRequestsToSchedule = serviceRequests.filter(s => 
        s.status == "Plannen").length;
      })
    )
  }
}

<div class="row week-availability">
    <div class="col card">
       <div class="card-body">
          <h4>Service Inzetten</h4>
          <div class="row filter-input-row"> 
            <div class="filters">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckFiveDays" [(ngModel)]="show5Days" (ngModelChange)="startDateChanged()">
                    <label class="form-check-label" for="flexSwitchCheckFiveDays">Toon 5-daagse werkweek</label>
                </div>
                <div class="filter-selection">
                    <i class="bi bi-person-fill-gear"></i>
                    <p-multiSelect 
                    [options]="allMechanics" 
                    [(ngModel)]="selectedMechanics" 
                    optionLabel="name"
                    placeholder="Selecteer Monteurs" />
                </div>
                <div class="form-group amount-of-weeks">
                    <input type="number" min="1" step="1" class="form-control" id="amountOfWeeks" placeholder="Weken" [(ngModel)]="weeks" (ngModelChange)="startDateChanged()">
                    <label for="amountOfWeeks">weken</label>
                </div>
            </div>
            <div class="week-input">
                <button class="btn btn-secondary" (click)="getNextOrPreviousWeek(false)"><i class="bi bi-arrow-left-circle"></i></button>
                <input type="date" class="form-control" [(ngModel)]="dateInput" (ngModelChange)="startDateChanged()"/>
                <button class="btn btn-secondary" (click)="getNextOrPreviousWeek(true)"><i class="bi bi-arrow-right-circle"></i></button>
                <button class="btn btn-secondary legenda-button" (click)="toggleInformationDropdown()" [ngStyle]="{'background-color': showDropdown ? 'rgb(38, 140, 38, 0.6)' : ''}"><i class="bi bi-info-circle"></i>
                    <div *ngIf="showDropdown" class="legenda-items">
                        <div class="status-item" *ngFor="let statusItem of serviceRequestService.serviceRequestStatus">
                            <div class="col">
                                <span class="badge badge-primary" [ngStyle]="{'background-color': getStatusColor(statusItem.value)}">
                                    <i class="{{serviceRequestService.getStatusIcon(statusItem.value)}}"></i>
                                    {{statusItem.description}}
                                </span>
                            </div>
                        </div>
                    </div>
                </button>
            </div>
          </div>
          <div class="row">
            <div class="view-wrapper">
                <div class="hours">
                    <div class="hour empty-hour">
                        <span>Week {{activeWeek}}</span>
                    </div>
                    <div class="hour" *ngFor="let hour of hours">
                        <span>{{hour.name}}</span>
                    </div>
                </div>
                <div class="days">
                    <div class="day empty-day">
                    </div>
                    @for(day of days; track day.date){
                        <div class="day">
                            <div>
                                <div class="day-header">
                                    <span><strong>{{day.name}}</strong> {{day.date | date: 'dd-MM'}}</span>
                                </div>
                                <div class="available-mechanics d-flex flex-column">
                                    @for(mechanic of getAvailableMechanics(day.date); track mechanic.$id){
                                        <div class="d-flex justify-start align-items-center">
                                            @if(mechanic.partlyAvailable){
                                                <i class="bi bi-circle-half" [style.color]="mechanic.partlyAvailable ? '#f7b36e' : '#198754'" ></i><span>{{mechanic.personalData?.firstname}} {{mechanic.personalData?.lastname}}</span>
                                            } @else {
                                                <i class="bi bi-circle-fill" [style.color]="mechanic.partlyAvailable ? '#f7b36e' : '#198754'" ></i><span>{{mechanic.personalData?.firstname}} {{mechanic.personalData?.lastname}}</span>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            <div class="service-windows">
                                @for(serviceWindow of getServiceWindows(day.date); track serviceWindow.$id){
                                    <div class="service-window">
                                        <div class="service-window-blank" [style.width]="serviceWindow.marginStart">
        
                                        </div>
                                        <div class="service-window-content" [style.background-color]="getServiceWindowColor(serviceWindow.parentRequest)" [style.width]="serviceWindow.width">
                                            <div class="content-wrapper" [ngClass]="{'compact-window': (serviceWindow?.hoursBetween ?? 0) < 4}">
                                                <div class="details">
                                                    <div class="expand-details">
                                                        <i *ngIf="!isExpanded(serviceWindow)" (click)="toggleServiceWindowExpander(serviceWindow, true)" class="bi bi-arrow-down-circle"></i>
                                                        <i *ngIf="isExpanded(serviceWindow)" (click)="toggleServiceWindowExpander(serviceWindow, false)" class="bi bi-arrow-up-circle"></i>
                                                    </div>
                                                    <div class="main-presenter" [ngClass]="{'compact-main-presenter': (serviceWindow?.hoursBetween ?? 0) < 4}">
                                                        @if((serviceWindow?.hoursBetween ?? 0) > 4){
                                                            <span>
                                                                @if(serviceWindow.dayOfDays){
                                                                    <span class="dayOfDays">{{serviceWindow.dayOfDays}} -</span>
                                                                }
                                                                <strong>{{serviceWindow.parentRequest?.name}}</strong> - {{serviceWindow.parentRequest?.referencedMachine?.company?.companyName}} - {{getMechanics(serviceWindow)}}</span>
                                                            <span><strong>Ordernummer: </strong>{{serviceWindow.parentRequest?.orderId}} - <strong>Status: </strong> {{serviceWindow.parentRequest?.status}} - <strong>Type: </strong> {{serviceWindow.parentRequest?.type}}</span>
                                                        } @else {
                                                            @if(serviceWindow.dayOfDays){
                                                                <span class="compact-details">{{serviceWindow.dayOfDays}}</span>
                                                            }
                                                            <span class="compact-details"><strong>{{serviceWindow.parentRequest?.name?.substring(0, 20)}}...</strong> - {{serviceWindow.parentRequest?.referencedMachine?.company?.companyName}} - {{getMechanics(serviceWindow)}}</span>
                                                            <span class="compact-details"><strong>Ordernummer: </strong>{{serviceWindow.parentRequest?.orderId}}</span>
                                                            <span class="compact-details"><strong>Status: </strong> {{serviceWindow.parentRequest?.status}}</span>
                                                            <span class="compact-details"><strong>Type: </strong> {{serviceWindow.parentRequest?.type}}</span>
                                                        }
                                                    </div>
                                                </div>
                                                <div class="resources-status" *ngIf="serviceWindow.parentRequest">
                                                    <div class="edit-icon" (click)="editServiceRequest(serviceWindow.parentRequest)">
                                                        <i class="bi bi-pencil"></i>
                                                    </div>
                                                    <div class="status-icon">
                                                        <i [style.color]="getMechanicStatus(serviceWindow)" class="bi bi-person-fill-gear"></i>
                                                        <span *ngIf="serviceWindow.noMechanicsAttached" class="no-present">X</span>
                                                    </div>
                                                    <div class="status-icon">
                                                        <i [style.color]="getVehicleStatus(serviceWindow)" class="bi bi-truck"></i>
                                                        <span *ngIf="serviceWindow.noVehiclesAttached" class="no-present">X</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="expander-content" [ngClass]="{'compact-expander-content': (serviceWindow?.hoursBetween ?? 0) < 4}" *ngIf="isExpanded(serviceWindow)">
                                                <div><span><strong>Machinenummer:</strong></span> <span>{{serviceWindow.parentRequest?.machineId}}</span></div>
                                                <div><span><strong>Monteurs:</strong></span> <span>{{getMechanics(serviceWindow)}}</span></div>
                                                <div><span><strong>Voertuigen:</strong></span> <span>{{getVehicles(serviceWindow)}}</span></div>
                                                <div><span><strong>Samenvatting:</strong></span><span>{{serviceWindow.parentRequest?.requestSummary}}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                @for(unavailabilityWindow of getUnavailabilitiesMechanics(day.date); track unavailabilityWindow.$id){
                                    <div class="service-window">
                                        <div class="service-window-blank" [style.width]="unavailabilityWindow.marginStart"></div>
                                        <div class="service-window-content unavailability" [style.width]="unavailabilityWindow.width">
                                            <div class="content-wrapper" [ngClass]="{'compact-window': (unavailabilityWindow?.hoursBetween ?? 0) < 4}">
                                                <div class="details">
                                                    <div class="main-presenter" [ngClass]="{'compact-main-presenter-unavailability': (unavailabilityWindow?.hoursBetween ?? 0) < 4}">
                                                        @let mechanic = unavailabilityWindow.mechanic;
                                                        @if((unavailabilityWindow?.hoursBetween ?? 0) > 4){
                                                            <span>
                                                                @if(unavailabilityWindow.dayOfDays){
                                                                    <span class="dayOfDays">{{unavailabilityWindow.dayOfDays}} -</span>
                                                                }
                                                                <strong>Onbeschikbaarheid: </strong>{{mechanic?.personalData?.firstname}} {{mechanic?.personalData?.lastname}} - <strong>Reden:</strong> {{unavailabilityWindow.unavailability?.type}}</span>
                                                                @if(unavailabilityWindow.unavailability?.additionalMessage){
                                                                    <span><strong>Opmerking: </strong>{{unavailabilityWindow.unavailability?.additionalMessage?.substring(0, 100)}}...</span>
                                                                }
                                                        } @else {
                                                            @if(unavailabilityWindow.dayOfDays){
                                                                <span class="compact-details">{{unavailabilityWindow.dayOfDays}}</span>
                                                            }
                                                            <span class="compact-details"><strong>Onbeschikbaarheid:</strong></span>
                                                            <span class="compact-details small-description">{{mechanic?.personalData?.firstname}} {{mechanic?.personalData?.lastname}}</span>
                                                            <span class="compact-details small-description"><strong>Reden: </strong>{{unavailabilityWindow.unavailability?.type}}</span>
                                                            @if(unavailabilityWindow.unavailability?.additionalMessage){
                                                                <span class="compact-details"><strong>Opmerking: </strong>{{unavailabilityWindow.unavailability?.additionalMessage?.substring(0, 20)}}...</span>
                                                            }
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
          </div>
       </div>
    </div>
 </div>
import { Injectable } from '@angular/core';
import { ID, Models, Query } from 'appwrite';
import { Api } from 'src/app/helpers/api';
import { Mechanic } from 'src/app/models/mechanic';
import { environment } from 'src/environments/environment';
import { UnavailabilityService } from '../unavailability/unavailability.service';
import { PersonalDataService } from '../personal-data/personal-data.service';
import { CompetentionService } from '../competention/competention.service';

@Injectable({
  providedIn: 'root',
})
export class MechanicService {
  mechanicStatusItems: any[];
  mechanicTypes: string[];
  mechanicExperience: string[];
  mechanicTable: string = 'Mechanics';

  constructor(private unavailabilityService: UnavailabilityService, private competentionService: CompetentionService, private personalDataService: PersonalDataService) {
    this.mechanicTypes = ['UNIKON', "ZZP'-er", 'UNIKON Intern', 'Overig'];
    this.mechanicExperience = ['Junior', "Medior", 'Senior'];

    this.mechanicStatusItems = [
      {
        value: 'Beschikbaar',
        description: 'Beschikbaar',
        iconSource: 'bi bi-check-circle',
        color: '#198754',
        id: 1,
      },
      {
        value: 'Niet beschikbaar',
        description: 'Niet beschikbaar',
        iconSource: 'bi bi-exclamation-circle',
        color: '#ff3333',
        id: 2,
      },
      {
        value: 'Onbekend',
        description: 'Onbekend',
        iconSource: 'bi bi-question-circle',
        color: '#ff6600',
        id: 3,
      },
      {
        value: 'Op aanvraag',
        description: 'Op aanvraag',
        iconSource: 'bi bi-slash-circle',
        color: '#cc66ff',
        id: 4,
      },
    ];
  }

  /**
   * This method will retrieve all Mechanics of the current team
   */
  async getMechanicsAndUnavailabilities() {
    var resultCount = 50;
    var resultList: Mechanic[] = [];
    var lastId: string | undefined = undefined;
    var results: Models.DocumentList<Mechanic>;

    while(resultCount == 50){
      if(lastId){
        results = await Api.database.listDocuments<Mechanic>(
          environment.database,
          this.mechanicTable,
          [Query.limit(50), Query.cursorAfter(lastId)]
        );
      } else {
        results = await Api.database.listDocuments<Mechanic>(
          environment.database,
          this.mechanicTable,
          [Query.limit(50)]
        );
      }

      // Retrieve all Unavailabilities
      await Promise.all(
        results.documents.map((m) => {
          return this.unavailabilityService
            .getUnavailabilitiesOfResource(m.$id)
            .then((unavailabilities) => {
              m.unavailability = unavailabilities;
            });
        })
      );

      // Retrieve all PersonalData
      await Promise.all(
        results.documents.map((m) => {
          return this.personalDataService
            .getPersonalData(m.$id)
            .then((personalData) => {
              m.personalData = personalData;
            });
        })
      );
      
      // Retrieve all Competentions
      await Promise.all(
        results.documents.map((m) => {
          return this.competentionService
            .getMechanicCompetentions(m.$id)
            .then((competentions) => {
              m.competentions = competentions?.documents ?? [];
            });
        })
      );

      lastId = results?.documents[results.documents.length - 1]?.$id;
      resultList.push(...results.documents);
      resultCount = results.documents.length;
    }

    return resultList;
  }

  /**
   * This method will create a new Mechanic in the database
   */
  addMechanic(mechanic: Mechanic, teamId: string, writePermissions: string[]) {
    return Api.database.createDocument(
      environment.database,
      this.mechanicTable,
      ID.unique(),
      this.createPartialMechanic(mechanic)
    );
  }

  /**
   * This method will update an existing Mechanic in the Database
   */
  updateMechanic(mechanic: Mechanic) {
    return Api.database.updateDocument(
      environment.database,
      this.mechanicTable,
      mechanic.$id,
      this.createPartialMechanic(mechanic)
    );
  }

  /**
   * This method will delete an existing Mechanic from the database
   */
  deleteMechanic(mechanic: Mechanic) {
    return Api.database.deleteDocument(
      environment.database,
      this.mechanicTable,
      mechanic.$id
    );
  }

  /**
   * This method will return the icon resource based on the status
   */
  getStatusIcon(status: string) {
    if (!status) {
      return;
    }

    var statusResource = this.mechanicStatusItems.filter(
      (s) => s.value == status
    );

    if (!statusResource || statusResource?.length == 0) {
      return;
    }

    return statusResource[0].iconSource;
  }

  /**
   * Creates a partial instance of the mechanic without AppWrite properties
   * @param vehicle
   * @returns
   */
  private createPartialMechanic(mechanic: Mechanic): Partial<Mechanic> {
    if (!mechanic) {
      return {};
    }

    return {
      additionalMessage: mechanic.additionalMessage,
      available: mechanic.available,
      type: mechanic.type,
      permanentVehicleIds: mechanic.permanentVehicles
        ?.map((pv) => pv.$id)
        .join(' '),
      experience: mechanic.experience,
      hasOwnVehicle: mechanic.hasOwnVehicle,
      showAvailabilityInSchedule: mechanic.showAvailabilityInSchedule
    };
  }
}

import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { TimeSlot } from 'src/app/models/interaces/time-slot';
import { Mechanic } from 'src/app/models/mechanic';
import { ServiceRequest } from 'src/app/models/service-request';
import { Unavailability } from 'src/app/models/unavailability';
import { Vehicle } from 'src/app/models/vehicle';
import { Window } from 'src/app/models/window';
import { HoursPipe } from 'src/app/pipes/hoursPipe';
import { WindowsPresentPipe } from 'src/app/pipes/windowsPresentPipe';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { PlannerService } from 'src/app/services/planner/planner.service';
import { ServiceRequestService } from 'src/app/services/service-request/service-request.service';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    DatePipe,
    BrowserModule,
    WindowsPresentPipe
  ],
  selector: 'app-service-request-schedule',
  templateUrl: './service-request-schedule.component.html',
  styleUrls: ['./service-request-schedule.component.scss']
})
export class ServiceRequestScheduleComponent implements OnInit {

  @Input() serviceRequest?: ServiceRequest;
  @Input() readOnly?: boolean;
  scheduleDate?: Date;
  hours: any[];
  filteredHours: any[];
  mechanics?: Mechanic[];
  vehicles?: Vehicle[];
  serviceRequests?: ServiceRequest[];
  unavailabilities?: Unavailability[];
  mechanicIdToAttach?: string;
  vehicleIdToAttach?: string;
  showDropdown: boolean = false;
  filter?: string = "availableOnRequest";

  // The Subscriptions to Unsub on destroy
  subs: Subscription[] = [];

  constructor(public serviceRequestService: ServiceRequestService, public hoursPipe: HoursPipe, private communicationService: CommunicationService, public plannerSerivce: PlannerService) { 
    this.hours = [
      {
        name: "07:00",
        hoursFromMidnight: 7
      },
      {
        name: "07:30",
      },
      {
        name: "08:00",
      },
      {
        name: "08:30",
      },
      {
        name: "09:00"
      },
      {
        name: "09:30",
      },
      {
        name: "10:00"
      },
      {
        name: "10:30",
      },
      {
        name: "11:00"
      },
      {
        name: "11:30",
      },
      {
        name: "12:00"
      },
      {
        name: "12:30",
      },
      {
        name: "13:00"
      },
      {
        name: "13:30",
      },
      {
        name: "14:00"
      },
      {
        name: "14:30",
      },
      {
        name: "15:00"
      },
      {
        name: "15:30",
      },
      {
        name: "16:00"
      },
      {
        name: "16:30",
      },
      {
        name: "17:00",
        hoursToMidnight: 7
      },
    ];
    this.filteredHours = this.hoursPipe.transform(this.hours);
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe()
    })
  }

  ngOnInit(): void {
    this.subs.push(
      this.communicationService.activeStartDate.subscribe(startDate => {
        if(startDate){
          this.scheduleDate = startDate;
          this.currentWindowChanged();
        }
      })
    )

    this.subs.push(
      this.communicationService.activeMechanics.subscribe(mechanics => {
        this.mechanics = mechanics;
        
        // Trigger Change
        this.currentWindowChanged();
      })
    )

    this.subs.push(
      this.communicationService.activeVehicles.subscribe(vehicles => {
        this.vehicles = vehicles;
      })
    )

    this.subs.push(
      this.communicationService.activeServiceRequests.subscribe(serviceRequests => {
        this.serviceRequests = structuredClone(serviceRequests);
        
        if(this.serviceRequest && this.serviceRequests.filter(s => s == this.serviceRequest).length == 0){
          this.serviceRequests.push(this.serviceRequest)
        }
      })
    )

    if(this.serviceRequest){
      this.serviceRequest.windows?.forEach(w => {
        w.assignedMechanics = this.mechanics?.filter(m => w.assignedResources?.includes(m.$id));
        w.assignedVehicles = this.vehicles?.filter(v => w.assignedResources?.includes(v.$id));
      });
    }

    this.subs.push(
      this.communicationService.activeServiceRequest.subscribe(serviceRequest => {
        if(!serviceRequest){
          return;
        }
        
        this.serviceRequest = serviceRequest;
        this.scheduleDate = serviceRequest?.startTime;
        
        // Trigger Change
        this.currentWindowChanged();
      })
    )

    // Initial Trigger and setup
    this.scheduleDate = this.serviceRequest?.startTime ?? new Date();
    this.currentWindowChanged();
  }

  /**
   * This method will determine whether the mechanic has an own Vehicle
   * @param id 
   */
  getMechanicHasOwnVehicle(id: string){
    var mechanic = this.mechanics?.filter(m => m.$id == id);

    if((mechanic?.length ?? 0) > 0){
      return mechanic![0].hasOwnVehicle;
    }
    return undefined;
  }

  /**
    * Inverse the Show boolean
    */
   toggleInformationDropdown(){
    this.showDropdown = !this.showDropdown;
   }

   /**
   * Trigger update for schedule date changed
   * @param event 
   */
   scheduleDateChanged(event: any){
    this.scheduleDate = moment(event).toDate();
    this.currentWindowChanged();
  }

  /**
   * This method will trigger a schedule update
   * @returns 
   */
  currentWindowChanged(){
    if(!this.mechanics){
      return;
    }

    this.mechanics.forEach(m => {
      this.getMechanicSchedule(m);
    })
  }

  /**
   * This method will create the Mechanic Schedule for the given Mechanic
   * @param mechanic 
   * @returns 
   */
  getMechanicSchedule(mechanic: Mechanic){
    // SaftyChecks
    if(!this.serviceRequests || !this.scheduleDate){
      return;
    }

    // Gather all windows on this date
    var sortedUnavailabilities = this.plannerSerivce.getSortedUnavailabilitiessOnThisDay(mechanic.unavailability ?? [], this.scheduleDate);
    var sortedWindows = this.plannerSerivce.getSortedWindowsOnThisDay(this.serviceRequests, this.scheduleDate, mechanic.$id);
    var sortedTimeSlots: TimeSlot[] = sortedWindows.concat(sortedUnavailabilities).sort((a,b) => (a!.startTime > b!.startTime) ? 1 : ((b!.startTime > a!.startTime) ? -1 : 0));

    var windows: Window[] = [];
    var rowIndex = 1;
    
    // Check if there are no events on this date and the mechanic is available
    if(sortedTimeSlots.length == 0)
    {
      // No events on this day so the entire day is open to schedule
      windows.push({
        $id: "",
        $collectionId: "",
        $createdAt: "",
        $databaseId: "",
        $permissions: [],
        $updatedAt: "",
        startTime: moment(this.scheduleDate).startOf('day').add(this.hours[0].hoursFromMidnight, 'hours').toDate(),
        endTime: moment(this.scheduleDate).endOf('day').subtract(this.hours[this.hours.length - 1].hoursToMidnight, 'hours').add(1, 'seconds').toDate(),
        row: `${rowIndex}/span 1`,
        displayName: mechanic.available,
        backgroundColor: this.plannerSerivce.getStatusColor(mechanic.available),
        
        startCol: 1,
        colsLength: 20,
      });

      if(this.matchingFilters(windows)){
        mechanic.windows = windows;
      } else {
        mechanic.windows = []
      }
      return;
    } 

    for(let i = 0; i < sortedTimeSlots.length; i++){
      var slot = sortedTimeSlots[i];
      var window: Window | undefined;
  
      if(slot){
        // Determine minutes between
        let minutesbetweenStartAndEnd = 0;

        // Start- and EndDate are on the same day
        if(moment(this.scheduleDate).isSame(moment(slot.startTime), 'day') && moment(this.scheduleDate).isSame(moment(slot.endTime), 'day')){
          minutesbetweenStartAndEnd = moment(slot.endTime).diff(moment(slot.startTime), 'minutes');
        }
        // Startdate is not the same as the Schedule Date
        else if(moment(this.scheduleDate).isSame(moment(slot.startTime), 'day') && !moment(this.scheduleDate).isSame(moment(slot.endTime), 'day')){
          var minutesBetween = moment(slot.startTime).diff(moment(this.scheduleDate).endOf('day'), 'minutes') - 1;
          var hoursToMidnight = this.hours[this.hours.length - 1].hoursToMidnight;
          minutesbetweenStartAndEnd = Math.abs(minutesBetween) - (hoursToMidnight * 60);
        } 
        // EndDate is not the same as the Schedule Date
        else if(!moment(this.scheduleDate).isSame(moment(slot.startTime), 'day') && moment(this.scheduleDate).isSame(moment(slot.endTime), 'day')){
          var minutesBetween = moment(slot.endTime).diff(moment(this.scheduleDate).startOf('day'), 'minutes');
          var hoursFromMidnight = this.hours[0].hoursFromMidnight;
          minutesbetweenStartAndEnd = minutesBetween - (hoursFromMidnight * 60);
        } 
        // In this case the schedule date is between the Start- and EndDate
        else {
          var minutesBetween = Math.abs(moment(this.scheduleDate).startOf('day').diff(moment(this.scheduleDate).endOf('day'), 'minutes'));
          var hoursFromMidnight = this.hours[0].hoursFromMidnight;
          var hoursToMidnight = this.hours[this.hours.length - 1].hoursToMidnight;
          minutesbetweenStartAndEnd = minutesBetween - ((hoursFromMidnight * 60) + (hoursToMidnight * 60)) + 1;
        }

        var startIndex = 0

        if(this.hours.filter(h => h.name == moment(slot.startTime).format('HH:mm')).length > 0){
          startIndex = this.hours.indexOf(this.hours.filter(h => h.name == moment(slot.startTime).format('HH:mm'))[0]) + 1;
          // Check if the current window is on the day as the schedule date
          if(!moment(this.scheduleDate).isSame(moment(slot.startTime), 'day')){
            startIndex = 1;
          }

          var castedWindow = slot as Window;
          var castedUnavailability = slot as Unavailability;
          var displayName =  castedWindow?.parentRequest ? `Inzet - ${castedWindow?.parentRequest?.referencedMachine?.company?.companyName} - ${castedWindow?.parentRequest?.type}` : "Inzet";
          var backgroundColor = (this.serviceRequest?.windows?.filter(wi => wi == slot).length ?? 0) > 0 ? "#8c66ff" : this.plannerSerivce.getStatusColor("Inzet") ;

          // Override DisplayName if the slot is an unavaiability
          if(slot.slotType == "unavailability"){
            displayName = `Onbeschikbaar - ${castedUnavailability.type}`;
            backgroundColor = this.plannerSerivce.getStatusColor("Onbeschikbaar");
          }
          
          window = {
            $id: castedWindow ? castedWindow.$id : castedUnavailability.$id,
            $collectionId: "",
            $createdAt: "",
            $databaseId: "",
            $permissions: [],
            $updatedAt: "",
            startTime: moment(slot.startTime).toDate(),
            endTime: moment(slot.endTime).toDate(),
            displayName: displayName,
            backgroundColor: backgroundColor,
            assignedResources: castedWindow.assignedResources,
            assignedMechanics: this.mechanics?.filter(m => castedWindow.assignedResources?.includes(m.$id)),
            startCol: startIndex,
            colsLength: Math.ceil(minutesbetweenStartAndEnd / 30),
          }
        } 
      }

      //[0] - Add an available window in front of the current window if possible
      if(i == 0 && moment(this.scheduleDate).isSame(moment(slot.startTime), 'day')){
        this.plannerSerivce.addAvailableWindowInFront(slot.startTime, this.hours, windows, mechanic.available);
      }

      //[1]
      if(window){
        windows.push(window);
      }

      //[2] - Add Available Window in between the current and next window if there is a empty space between
      // Check if the next slot is on the same date otherwise it's the last slot on this date
      if(i >= 0 && i < sortedTimeSlots.length - 1 && moment(sortedTimeSlots[i + 1].startTime).isSame(moment(slot.endTime), 'day')){
        var nextWindow = sortedTimeSlots[i + 1];
        this.plannerSerivce.addAvailableWindowInBetween(slot.endTime, nextWindow.startTime, this.hours, windows,  mechanic.available);
      }

      //[3] - Add Available in rear if there is space left in the schedule
      if(i == sortedTimeSlots.length - 1 && moment(this.scheduleDate).isSame(moment(slot.endTime), 'day')){
        this.plannerSerivce.addAvailableWindowInRear(slot.endTime, this.hours, windows,  mechanic.available);
      }
    }

    // Implements filter
    if(this.matchingFilters(windows)){
      mechanic.windows = windows;
    } else {
      mechanic.windows = [];
    }
  }

  /**
   * This method will check wether there are windows which match the present filter
   * @returns 
   */
  matchingFilters(windows: Window[]) : boolean {
    var windowsPresent = true;
    switch(this.filter){
      case "available":
          windowsPresent = windows.filter(w => w.displayName?.includes("Beschikbaar")).length > 0;
        break;

      case "unavailable":
        windowsPresent = windows.filter(w => w.displayName?.includes("Niet beschikbaar")).length > 0;
        break;

      case "availableOnRequest":
        windowsPresent = windows.filter(w => w.displayName?.includes("Beschikbaar") || w.displayName?.includes("Op aanvraag")).length > 0;
        break;
    }

    return windowsPresent;
  }

  /**
   * This method will delete the current window from the schedule
   * @param window 
   */
  deleteWindow(window: Window){
    if(this.serviceRequest?.windows){
      this.serviceRequest.windows = this.serviceRequest.windows.filter(w => w != window);
      
      // Add to list of Removedwindows
      if(!this.serviceRequest.removedWindows){
        this.serviceRequest.removedWindows = [];
      }

      this.serviceRequest.removedWindows.push(window);

      this.currentWindowChanged();
    }
  }

  onWindowEndTimeValuesChanged(event: any, window: Window){
    window.endTime = moment(event).toDate();
    this.currentWindowChanged();
  }

  onWindowStartTimeValuesChanged(event: any, window: Window){
    window.startTime = moment(event).toDate();
    this.currentWindowChanged();
  }

  /**
   * This method will detach the given mechanic
   * @param window 
   * @param mechanicId 
   * @returns 
   */
  detachMechanic(window: Window, mechanicId: string){
    if(!mechanicId){
      return;
    }

    window.assignedResources = window.assignedResources?.filter(r => r != mechanicId);
    window.assignedMechanics = window.assignedMechanics?.filter(m => m.$id != mechanicId);

    this.currentWindowChanged();
  }

  /**
   * This method will detach a Vehicle from the window 
   * @param vehicle 
   * @param window 
   */
  detachVehicle(vehicle: Vehicle, window: Window){
    vehicle.attached = false;
    window.assignedResources = window.assignedResources?.filter(r => r != vehicle.$id);
    window.assignedVehicles = window.assignedVehicles?.filter(v => v != vehicle);
  }

  /**
   * This method will attach a Vehicle to the current Window
   * @param vehicle 
   * @param window 
   * @returns 
   */
  attachVehicle(vehicle: Vehicle, window: Window){
    vehicle.attached = true;

    if(!window.assignedResources){
      window.assignedResources = [];
    }

    // Resources can only be added onces
    if(window.assignedResources.filter(r => r == vehicle.$id).length > 0){
      return;
    }

    window.assignedResources.push(vehicle.$id);
  }

  /**
   * This method will attach the selected Mechanic to the given Window
   * @param window 
   */
  attachVehicleToWindow(window: Window){
    if(!this.vehicles || !this.vehicleIdToAttach){
      return;
    }

    var vehicle = this.vehicles?.filter(m => m.$id == this.vehicleIdToAttach);

    if(vehicle?.length ?? 0 > 0){
      if(!window.assignedResources){
        window.assignedResources = [];
      }

      if(!window.assignedVehicles){
        window.assignedVehicles = [];
      }
  
      // Resources can only be added onces
      if(window.assignedResources.filter(r => r == vehicle![0].$id).length > 0){
        return;
      }
  
      window.assignedResources.push(vehicle![0].$id);
      window.assignedVehicles.push(vehicle![0]);
      this.vehicleIdToAttach = undefined;

      this.currentWindowChanged();
    }
  }

  /**
   * This method will attach the selected Mechanic to the given Window
   * @param window 
   */
  attachMechanic(window: Window){
    if(!this.mechanics || !this.mechanicIdToAttach){
      return;
    }

    var mechanic = this.mechanics?.filter(m => m.$id == this.mechanicIdToAttach);

    if(mechanic?.length ?? 0 > 0){
      if(!window.assignedResources){
        window.assignedResources = [];
      }

      if(!window.assignedMechanics){
        window.assignedMechanics = [];
      }
  
      // Resources can only be added onces
      if(window.assignedResources.filter(r => r == mechanic![0].$id).length > 0){
        return;
      }
  
      window.assignedResources.push(mechanic![0].$id);
      window.assignedMechanics.push(mechanic![0]);
      this.mechanicIdToAttach = undefined;

      // Attach Permanent Vehicles
      mechanic![0].permanentVehicleIds?.split(" ").forEach(v => {
        // Resources can only be added onces
        if(window.assignedResources?.filter(r => r == v).length == 0){
          window.assignedResources.push(v);
        }
      });

      this.currentWindowChanged();
    }
  }

  addMechanicToSchedule(window: Window, mechanic: Mechanic){
    if(this.readOnly){
      return;
    }
    
    if(!this.serviceRequest){
      
      // Open ServiceRequest if possible
      if(window.displayName?.includes("Inzet")){
        var activeServiceRequests = this.communicationService.activeServiceRequests.getValue();
        var matchingRequests = activeServiceRequests.filter(s => (s.windows?.filter(w => w.$id == window.$id).length ?? 0) > 0);
        // If there is a matching request then open it
        if(matchingRequests?.length > 0){
          this.communicationService.activeServiceRequest.next(matchingRequests[0]);
        }
      }

      return;
    }

    if(!this.serviceRequest.windows){
      this.serviceRequest.windows = [];
    }

    if(!window.assignedResources){
      window.assignedResources = [];
    }

    if(!window.assignedMechanics){
      window.assignedMechanics = [];
    }

    if(!window.displayName?.includes("Beschikbaar") && !window.displayName?.includes("Op aanvraag")){
      return;
    }

    window.displayName = "Inzet";

    // Resources can only be added onces
    if(window.assignedResources.filter(r => r == mechanic.$id).length > 0){
      return;
    }

    window.assignedResources.push(mechanic.$id);
    window.assignedMechanics.push(mechanic);

    // Attach Permanent Vehicles
    mechanic.permanentVehicleIds?.split(" ").forEach(v => {
      // Resources can only be added onces
      if(window.assignedResources?.filter(r => r == v).length == 0){
        window.assignedResources.push(v);
      }
    });
    
    // Take over Start and End from ServiceRequest
    if (this.serviceRequest.startTime && this.serviceRequest.endTime) {
      window.startTime = this.serviceRequest.startTime;
      window.endTime = this.serviceRequest.endTime;
    }
    this.serviceRequest.windows.push(window);
    window.parentRequest = this.serviceRequest;

    this.currentWindowChanged();
  }

  /**
   * This method will get the calendar day to the next or previous
   */
    getNextOrPreviousDay(getNext: boolean){

      if(!this.scheduleDate){
        return;
      }

      var incrementedDate;
      if(getNext){
        incrementedDate = moment(this.scheduleDate).add(1, 'days');
      } else{
        incrementedDate = moment(this.scheduleDate).subtract(1, 'days');
      }

      this.scheduleDate = moment(incrementedDate).toDate();
      this.currentWindowChanged();
    }
  

  /**
   * This method will return the name of Mechanic based on the given id
   * @param id 
   * @returns 
   */
  getMechanicName(id: string){
    var mechanic = this.mechanics?.filter(m => m.$id == id);

    if((mechanic?.length ?? 0) > 0){
      return `${mechanic![0].personalData?.firstname} ${mechanic![0].personalData?.lastname}`;
    }
    return undefined;
  }
  
  /**
   * This method will return the name of Vehicle based on the given id
   * @param id 
   * @returns 
   */
  getVehicleName(id: string){
    var vehicle = this.vehicles?.filter(m => m.$id == id);

    if((vehicle?.length ?? 0) > 0){
      return `${vehicle![0].name} - ${vehicle![0].registrationNumber}`;
    }
    return undefined;
  }

  /**
   * This method will return a list of attached vehicles for the given Mechanic
   * @param mechanicId 
   */
  getMechanicVehicles(mechanicId: string, window: Window) : Vehicle[]{
    var mechanic = this.mechanics?.filter(m => m.$id == mechanicId);
    var attachedVehicles: Vehicle[] = [];

    if((mechanic?.length ?? 0) > 0){
      if(mechanic![0].permanentVehicleIds){
        
        mechanic![0].permanentVehicleIds.split(" ").forEach(vehicleId => {
          var vehicle = this.vehicles?.filter(m => m.$id == vehicleId);
          if(vehicle?.length ?? 0 > 0){
            
            // Check if the current Vehicle is attached in this window
            if((window.assignedResources?.filter(r => r == vehicleId).length ?? 0) > 0){
              vehicle![0].attached = true;
            }

            attachedVehicles.push(vehicle![0]);
          }
        })
      }
    }

    return attachedVehicles;
  }


  /**
   * This method will return the needed grid-area value based on the given input
   * @param row 
   * @param startCol 
   * @param cols 
   * @returns 
   */
  getGridAreaValue(row: number, startCol: number, cols: number){
    return `${row} / ${startCol} / span 1 / span ${cols}`;
  }
}

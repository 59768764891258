import { Injectable } from '@angular/core';
import { ID, Query } from 'appwrite';
import { Api } from 'src/app/helpers/api';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor() { }

  /**
   * This method will create a new team with the given name
   * @param establishmentName 
   * @returns 
   */
  createTeam(establishmentName: string){
    return Api.teams.create(ID.unique(), establishmentName, ["owner"]);
  }

  /**
   * This method will update an existing team
   * @param establishmentName 
   * @param teamId 
   * @returns 
   */
  updateTeamName(establishmentName: string, teamId: string){
    return Api.teams.updateName(teamId, establishmentName);
  }

  /**
   * This method will update a team membership
   * @param teamId 
   * @param membershipId 
   * @param userId 
   * @param secret 
   * @returns 
   */
  updateMembership(teamId: string, membershipId: string, userId: string, secret: string){
    return Api.teams.updateMembershipStatus(teamId, membershipId, userId, secret);
  }

  /**
   * This method will add a user to a team
   * @param emailAdress 
   * @param teamId 
   * @param roles 
   */
  addUserToTeam(emailAdress: string, teamId: string, roles: string[]){
    return Api.teams.createMembership(teamId, roles, environment.baseServiceUrl, emailAdress);
  }

  /**
   * This method will remove a user from a team
   * @param teamId 
   * @param membershipId 
   * @returns 
   */
  deleteUserFromTeam(teamId: string, membershipId: string){
    return Api.teams.deleteMembership(teamId, membershipId);
  }

  /**
   * This method will return a list of all teammembers
   * @param teamId 
   * @returns 
   */
  getTeamMembers(teamId: string){
    return Api.teams.listMemberships(teamId);
  }

  /**
   * This method will return a membership
   * @param teamId 
   * @param userId 
   */
  getMembership(teamId: string, userId: string){
    return Api.teams.listMemberships(teamId, [Query.equal('userId', userId), Query.equal('teamId', teamId)]).then(memberships => {
      if(memberships.memberships){
        return memberships.memberships[0];
      }
      return undefined;
    });
  }

  /**
   * This method will update the user roles
   * @param teamId 
   * @param membershipId 
   * @param roles 
   * @returns 
   */
  modifyMembershipRoles(teamId: string, membershipId: string, roles: string[]){
    return Api.teams.updateMembershipRoles(teamId, membershipId, roles);
  }
}

@if(company){
    <div [ngClass]="{'row mt-3': !serviceRequest}">
        <div [ngClass]="{'card': !serviceRequest}">
            <div [ngClass]="{'card-body': !serviceRequest}">
                @if(!serviceRequest){
                    @if(company.$id){
                        <h4>Klant: {{company.companyName}} wijzigen</h4>
                    } @else {
                        <h4>Klant aanmaken</h4>
                    }
                    <p>Gebruik onderstaand component om een klant aan te maken of een klant te wijzigen</p>
                }
                <div class="form">
                    <div class="row">
                        <div class="form-group" [ngClass]="{'col-2': !serviceRequest, 'col-12': serviceRequest}">
                            <label for="inputRelationNumber">Klantnummer:</label>
                            <input type="text" class="form-control" id="inputRelationNumber" placeholder="Klantnummer..."  [(ngModel)]="company.customerRelationId">
                        </div>
                        <div class="form-group col">
                            <label for="inputCompanyName">Bedrijfsnaam:</label>
                            <input type="text" class="form-control" id="inputCompanyName" placeholder="Bedrijfsnaam..."  [(ngModel)]="company.companyName">
                        </div>
                    </div>
                    @if(company.$id && !serviceRequest){
                        <div class="row mt-2">
                            <div class="form-group col-6">
                                <label for="mergeCompanyInput">Samenvoegen met:</label>
                                <select class="form-select" id="mergeCompanyInput" [(ngModel)]="mergeWithCompany">
                                    <option value="undefined">Niet gekoppeld</option>
                                    @for(company of companies; track company.$id){
                                    <option value="{{company.$id}}">
                                        {{company.companyName}} - ({{company.customerRelationId}}) - (Machines: {{getAmountOfMachines(company)}})
                                    </option>
                                  }
                                </select>
                            </div>
                            <div class="col d-flex align-items-end no-margin">
                                <button class="btn btn-warning" (click)="close()"><i class="bi bi-sign-merge-left"></i> Samenvoegen</button>
                            </div>
                            <div class="col-12">
                                <span class="explanier"><i class="bi bi-info-circle"></i> Gebruik de samenvoeg-functionaliteit om dit bedrijf op te heffen en toe te voegen aan het geselecteerde bedrijf.<br>
                                    Hiermee zullen ook alle gekoppelde machines worden gekoppeld aan het geselecteerde bedrijf.
                                </span>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <div class="contact-persons">
                                    <app-asset-management-contact-person-edit [company]="company"></app-asset-management-contact-person-edit>
                                </div>
                            </div>
                        </div>
                    }
                    <div class="row mt-4">
                        <div class="col d-flex justify-content-end action-buttons">
                            <button class="btn btn-secondary close-button" (click)="close()"><i class="bi bi-x-lg"></i>Sluiten</button>
                            @if(company.$id){
                                <button class="btn btn-success" (click)="addOrUpdateCompany()"><i class="bi bi-save"></i>Klant updaten</button>
                            } @else {
                                <button class="btn btn-success" (click)="addOrUpdateCompany()"><i class="bi bi-save"></i>Klant aanmaken</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
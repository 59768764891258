import { Injectable } from '@angular/core';
import { ID, Models, Query } from 'appwrite';
import { Api } from 'src/app/helpers/api';
import { Machine } from 'src/app/models/machine';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MachineService {

  machineTable: string = "Machines"

  constructor() {
   }

    /**
    * This method will retrieve a Machine with the current id
    * @param id 
    * @returns 
    */
    async getMachine(id: string) : Promise<Machine | undefined> {
      return await Api.database.getDocument<Machine>(environment.database, this.machineTable, id);
    }

    /**
    * This method will retrieve a Machine with the given machineNumber
    * @param machineNumber 
    * @returns 
    */
    async getMachinesByMachineNumber(machineNumber: string) : Promise<Machine | undefined> {
      var machines = await Api.database.listDocuments<Machine>(environment.database, this.machineTable, [Query.limit(1), Query.equal('machineId', machineNumber)]);
    
      if(machines?.documents?.length > 0){
        return machines.documents[0];
      }

      return undefined;
    }

   /**
    * This method will retrieve all machines of the current team
    * @param resourceId 
    * @returns 
    */
   async getMachines(){
    var resultCount = 50;
    var machineList: Machine[] = [];
    var lastId: string | undefined = undefined;
    var machines: Models.DocumentList<Machine>;

    while(resultCount == 50){
      if(lastId){
        machines = await Api.database.listDocuments<Machine>(
          environment.database,
          this.machineTable,
          [Query.limit(50), Query.cursorAfter(lastId)]
        );
      } else {
        machines = await Api.database.listDocuments<Machine>(
          environment.database,
          this.machineTable,
          [Query.limit(50)]
        );
      }

      lastId = machines?.documents[machines.documents.length - 1]?.$id;
      machineList.push(...machines.documents);
      resultCount = machines.documents.length;
    }

    return machineList;
   }

  /**
  * This method will create a new Machine in the database
  */
  addMachine(machine: Machine) {
    return Api.database.createDocument(environment.database, this.machineTable, ID.unique(), this.createPartialMachine(machine))
  }

  /**
   * This method will update an existing Machine in the Database
   */
  updateMachine(machine: Machine) {
    return Api.database.updateDocument(environment.database, this.machineTable, machine.$id, this.createPartialMachine(machine));
  }

  /**
   * This method will delete an existing Machine from the database
   */
  deleteMachine(machine: Machine) {
    return Api.database.deleteDocument(environment.database, this.machineTable, machine.$id);
  }

  /**
   * Creates a partial instance of the Machine without AppWrite properties
   * @param vehicle 
   * @returns 
   */
  private createPartialMachine(machine: Machine): Partial<Machine> {
    if (!machine) {
      return {};
    }

    return {
      machineId: machine.machineId,
      name: machine.name,
      relatedCompanyId: machine.relatedCompanyId == 'undifiend' ? undefined : machine.relatedCompanyId,
      streetName: machine.streetName,
      zipCode: machine.zipCode,
      city: machine.city,
      houseNumber: machine.houseNumber,
      country: machine.country
    };
  }
}

import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Mechanic } from 'src/app/models/mechanic';
import { Unavailability } from 'src/app/models/unavailability';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { UnavailabilityService } from 'src/app/services/unavailability/unavailability.service';

@Component({
  selector: 'app-mechanic-availability',
  templateUrl: './mechanic-availability.component.html',
  styleUrls: ['./mechanic-availability.component.scss']
})
export class MechanicAvailabilityComponent implements OnInit {

  @Input() mechanic?: Mechanic;
  searchInput: string = "";
  sortedBy?: string;
  prevSort?: string;
  sortedAscending?: boolean;
  loadingUnavailability?: boolean = false;
  lazyLoadingUnavailability?: boolean = false;
  paginatedActiveUnavailability?: [Unavailability[]];
  allUnavailability: Unavailability[] = [];
  isViewer?: boolean;
  perPage: number;
  activePage: number;
  newUnavailability?: Unavailability;
  showPastUnavailabilities: boolean;

  // The Subscriptions to Unsub on destroy
  subs: Subscription[] = [];

  constructor(public communicationService: CommunicationService, public unavailabilityService: UnavailabilityService, private permissionService: PermissionService) {
    this.perPage = 5;
    this.activePage = 0;
    this.sortedBy = "start date";
    this.addUnavailability();
    this.showPastUnavailabilities = false;
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe()
    })
  }

  ngOnInit(): void {
    this.subs.push(
      this.communicationService.activeMechanic.subscribe(mechanic => {
        this.allUnavailability = mechanic?.unavailability ?? [];
        this.paginateActiveUnavailabilities();
      })
    )
      
    if(this.sortedBy){
      this.sortUnavailabilitiesBy(this.sortedBy, false);
    }

    this.paginateActiveUnavailabilities();
  }

  /**
   * This method will save a new unavailability to the backend
   */
  addUnavailability(){
    if(this.newUnavailability){
      // Convert from LocalDate to GlobalDate
      this.newUnavailability.startTime = new Date(this.newUnavailability.startTime);
      this.newUnavailability.endTime = new Date(this.newUnavailability.endTime);

      // Set time values to 07:30 -> 17:00 is there is no time value filled in.
      if(moment(this.newUnavailability.startTime).isSame(moment(this.newUnavailability.endTime), 'hours') && moment(this.newUnavailability.startTime).isSame(moment(this.newUnavailability.endTime), 'minutes'))
      {
        this.newUnavailability.startTime = moment(this.newUnavailability.startTime).hours(7).minutes(30).seconds(0).toDate();
        this.newUnavailability.endTime = moment(this.newUnavailability.endTime).hours(17).minutes(0).seconds(0).toDate();
      }

      // Set reference
      this.newUnavailability.resourceId = this.mechanic?.$id;

      // Add to database
      this.unavailabilityService.addUnavailability(this.newUnavailability, this.communicationService.activeTeamId.getValue()!, this.permissionService.getWritePermissions()).then(result => {
        if(!this.mechanic || !this.newUnavailability){
          return;
        }

        if(!this.mechanic.unavailability){
          this.mechanic.unavailability = [];
        }

        // Save new instance
        this.newUnavailability.$id = result.$id;
        this.mechanic.unavailability.push(result as Unavailability);
        this.allUnavailability = this.mechanic.unavailability;

        // Create new instance
        this.newUnavailability = {
          $id: "#",
          $collectionId: "",
          $createdAt: "",
          $databaseId: "",
          $permissions: [],
          $updatedAt: "",
          startTime: new Date(),
          endTime: new Date(),
          type: "Overig"
        }

        this.paginateActiveUnavailabilities();

        // User feedback
        this.communicationService.activeToastKind.next("Success");
        this.communicationService.activeToastMessage.next(`Het toevoegen van de onbeschikbaarheid is gelukt!`);
      }).catch(() => {
        this.communicationService.activeToastKind.next("Error");
        this.communicationService.activeToastMessage.next(`Het toevoegen van de onbeschikbaarheid is mislukt!`);
      });
    }

    // Create new instance
    this.newUnavailability = {
      $id: "#",
      $collectionId: "",
      $createdAt: "",
      $databaseId: "",
      $permissions: [],
      $updatedAt: "",
      startTime: new Date(),
      endTime: new Date(),
      type: "Overig"
    }
  }

  /**
   * This method will search in the present set of Unavailabilities
   * based on the given search input
   * @returns 
   */
  searchUnavailability(){
    if (!this.searchInput || this.searchInput == "") {
      this.allUnavailability.forEach(t => t.notMatchingFilter = false);
      this.paginateActiveUnavailabilities();
      return;
    }


    this.allUnavailability.forEach(mechanic => {
      var matchingAttributeFound = false;
      if([mechanic].filter(
        mechanic =>
          mechanic.startTime.toString().includes(this.searchInput.toLowerCase()) ||
          mechanic.endTime.toString().includes(this.searchInput.toLowerCase()) ||
          mechanic.type?.toLowerCase().includes(this.searchInput.toLowerCase())).length > 0){
            mechanic.notMatchingFilter = false;
            matchingAttributeFound = true;
          }

          if(!matchingAttributeFound){
            mechanic.notMatchingFilter = true;
          }
      });

      this.paginateActiveUnavailabilities();
  }

  /**
   * This method will sort the set of unavailabilities ASC or DESC based on the given input
   * @param sortBy 
   */
  sortUnavailabilitiesBy(column: string, toggle: boolean = true){
    this.sortedBy = column;
    this.prevSort = column;

    if (column == "start date") {
      if (this.sortedBy == column && this.sortedAscending) {
        if(toggle){
          this.sortedAscending = false;
        }

        this.allUnavailability.sort(function (a: Unavailability, b: Unavailability) {
          var textA = b.startTime;
          var textB = a.startTime;
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.paginateActiveUnavailabilities();
        return;
      }

      this.allUnavailability.sort(function (a: Unavailability, b: Unavailability) {
        var textA = a.startTime;
        var textB = b.startTime;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      if(toggle){
        this.sortedAscending = true;
      }

      this.sortedBy = column;
    } if (column == "end date") {
      if (this.sortedBy == column && this.sortedAscending) {
        if(toggle){
          this.sortedAscending = false;
        }

        this.allUnavailability.sort(function (a: Unavailability, b: Unavailability) {
          var textA = b.endTime;
          var textB = a.endTime;
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.paginateActiveUnavailabilities();
        return;
      }

      this.allUnavailability.sort(function (a: Unavailability, b: Unavailability) {
        var textA = a.endTime;
        var textB = b.endTime;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      if(toggle){
        this.sortedAscending = true;
      }
      
      this.sortedBy = column;
    } else if (column == "type") {
      if (this.sortedBy == column && this.sortedAscending) {
     
        if(toggle){
          this.sortedAscending = false;
        }

        this.allUnavailability.sort(function (a: Unavailability, b: Unavailability) {
          var textA = b.type?.toUpperCase();
          var textB = a.type?.toUpperCase();

          if (!textA) {
            textA = "";
          }

          if (!textB) {
            textB = "";
          }

          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.paginateActiveUnavailabilities();
        return;
      }

      this.allUnavailability.sort(function (a: Unavailability, b: Unavailability) {
        var textA = a.type?.toUpperCase();
        var textB = b.type?.toUpperCase();

        if (!textA) {
          textA = "";
        }

        if (!textB) {
          textB = "";
        }

        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    
      if(toggle){
        this.sortedAscending = true;
      }

      this.sortedBy = column;
    }

    this.paginateActiveUnavailabilities();
  }

  /**
   * This method will paginate all present unavailabilities
   * @returns 
   */
  paginateActiveUnavailabilities(){
    if (!this.allUnavailability) {
      return;
    }

    this.allUnavailability.forEach(unavailability => {
      unavailability.hideInList = false;
    })
    
    var filterdActiveUnavailabilities = this.allUnavailability.filter(t => !t.hideInList && !t.notMatchingFilter)

    // Extra filter for date
    if(!this.showPastUnavailabilities){
      filterdActiveUnavailabilities = filterdActiveUnavailabilities.filter(u => moment(u.endTime).isAfter(moment(), 'day'));
    }

    // If list is empty then clear paginated list
    if (filterdActiveUnavailabilities.length == 0) {
      this.paginatedActiveUnavailability = [[]];
    }

    for (var i = 0; i < filterdActiveUnavailabilities.length / this.perPage; i++) {
      if (i == 0) {
        this.paginatedActiveUnavailability = [filterdActiveUnavailabilities.slice(i * this.perPage, i * this.perPage + this.perPage)];
        continue;
      }

      if (!this.paginatedActiveUnavailability) {
        return;
      }

      this.paginatedActiveUnavailability.push(filterdActiveUnavailabilities.slice(i * this.perPage, i * this.perPage + this.perPage));
    }

    if (!this.paginatedActiveUnavailability) {
      return;
    }

    if (this.activePage >= this.paginatedActiveUnavailability?.length) {
      this.activePage = 0;
    }
  }

  /**
   * This method will update the Unavailability status
   * @param unavailability 
   */
  typeSelectionChanged(unavailability: Unavailability, type: string, onlyLocalUpdate: boolean = false) {
    if (!unavailability || !type) {
      return;
    }

    if(onlyLocalUpdate){
      unavailability.type = type;
      return;
    }
    
    // Change status
    var currentType = unavailability.type + "";
    unavailability.type = type;

    this.unavailabilityService.updateUnavailability(unavailability).then(() => {
      this.communicationService.activeToastKind.next("Success");
      this.communicationService.activeToastMessage.next(`Het updaten van het onbeschikbaarheidstype is gelukt!`);
    }).catch(() => {
      this.communicationService.activeToastKind.next("Error");
      this.communicationService.activeToastMessage.next(`Het updaten van het onbeschikbaarheidstype is mislukt!`);
      unavailability.type = currentType;
    });
  }

  /**
   * This method will remove a Unavailability or show a cofirmation button
   * @param unavailability 
   * @param index 
   * @param confirmationNeeded 
   * @returns 
   */
  async removeUnavailability(unavailability: Unavailability, index: number, confirmationNeeded: boolean) {
    if (confirmationNeeded) {
      var confirmButton = document.getElementById("confirm-removeU" + index);
      var cancelButton = document.getElementById("cancel-removeU" + index);
      var removeButton = document.getElementById("removeU" + index);
  
      if (confirmButton && removeButton && cancelButton) {
        confirmButton.style.display = "flex";
        cancelButton.style.display = "flex";
        removeButton.style.display = "none";
      }
      return;
    }

    // Remove Mechanic from the database
    this.unavailabilityService.deleteUnavailability(unavailability).then(() => {
      // Update set of active Mechanic if removal was successfull
      this.allUnavailability = this.allUnavailability.filter(m => m != unavailability);
      this.paginateActiveUnavailabilities();
      this.communicationService.activeToastKind.next("Success");
      this.communicationService.activeToastMessage.next(`Het verwijderen van deze onbeschikbaarheid is gelukt is gelukt!`);
    }).catch(() => {
      this.communicationService.activeToastKind.next("Error");
      this.communicationService.activeToastMessage.next(`Het verwijderen van deze onbeschikbaarheid is mislukt!`);
    });
  }

  /**
   * This methow will cancel a remove action
   * @param index 
   */
  cancelRemoval(index: number) {
    var confirmButton = document.getElementById("confirm-remove" + index);
    var cancelButton = document.getElementById("cancel-remove" + index);
    var removeButton = document.getElementById("remove" + index);

    if (confirmButton && removeButton && cancelButton) {
      confirmButton.style.display = "none";
      cancelButton.style.display = "none";
      removeButton.style.display = "block";
    }
  }

  /**
   * This method will return the color of a status
   * @param status 
   * @returns 
   */
  getStatusColor(status: string) {
    if (!status) {
      return;
    }

    var statusResource = this.unavailabilityService?.unavailabilityTypeListMechanics.filter(s => s.value == status);

    if (!statusResource || statusResource?.length == 0) {
      return;
    }

    return statusResource[0].color;
  }

  /**
   * This method will set the unavailability id to the given id so that the other status line could be accessed
   * @param unavailabilityId 
   */
  onTypeExpanderClick(unavailabilityId: string | undefined) {
    this.communicationService.openMechanicUnavailabilityIdChanged = true;
    if (this.communicationService.openMechanicUnavailabilityId == unavailabilityId) {
      this.communicationService.openMechanicUnavailabilityId = undefined;
    }
    this.communicationService.openMechanicUnavailabilityId = unavailabilityId;
  }

  /**
   * When click somewhere on the screen deativate open pop-ups
   */
  onClickLostFocus() {
    if (this.communicationService.openMechanicUnavailabilityIdChanged) {
      this.communicationService.openMechanicUnavailabilityIdChanged = false;
      return;
    }

    this.communicationService.openMechanicUnavailabilityIdChanged = false;
    this.communicationService.openMechanicUnavailabilityId = undefined;
  }
}

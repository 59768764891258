<div class="container">
    <div class="row message-pop-up">
        <div class="success-warning message" *ngIf="successFeedback">
            <div class="alert alert-success" role="alert">
                <strong>Succes!</strong> {{successFeedback}}
            </div>
        </div>
        <div class="success-warning message" *ngIf="warningFeedback">
            <div class="alert alert-warning" role="alert">
                <strong>Let op!</strong> {{warningFeedback}}
            </div>
        </div>
    </div>

    <div class="row header card" *ngIf="teamMembers">
       <div class="card-body">
            <div class="col">
                <h4>Gebruikersmanagement</h4>
                <nav class="nav-tabs-wrapper">
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button class="nav-link active" id="nav-team-management-tab" data-bs-toggle="tab" data-bs-target="#nav-team-management" type="button" role="tab" aria-controls="nav-team-management" aria-selected="true">Team Management</button>
                    <button class="nav-link" id="nav-external-users-management-tab" data-bs-toggle="tab" data-bs-target="#nav-external-users-management" type="button" role="tab" aria-controls="nav-external-users-management" aria-selected="false">Externe Gebruikers</button>
                    </div>
                </nav>
                <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="nav-team-management" role="tabpanel" aria-labelledby="nav-team-management-tab">
                        <div class="row">
                            <div class="col-12 mt-2">
                                <h4>Team Management</h4>
                                <div class="col introduction">
                                    <span>In dit menu kunt u uw team beheren.</span>
                                </div>
                            </div>
                            <div class="col">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Volledige Naam:</th>
                                            <th scope="col">Rol</th>
                                            <th scope="col">Emailadres</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let member of teamMembers">
                                            <th scope="row">{{member.fullname}}</th>
                                            <td>
                                                <select class="form-select" [ngModel]="displayRole(member.roles)" [disabled]="member.userId == user?.$id" (ngModelChange)="updateCompanyRoles(member, $event)">
                                                    <option *ngFor="let kv of roleStrings" [ngValue]="kv[0]">{{kv[1]}}</option>
                                                </select>
                                            </td>
                                            <td>{{member.emailAdress}}</td>
                                            <td>
                                                <button *ngIf="user?.$id != member.userId" class="btn btn-danger remove-button" (click)="removeMemberFromTeam(member)">
                                                    <i class="bi bi-trash"></i>
                                                </button>
                                                <button disabled *ngIf="user?.$id == member.userId" class="btn btn-danger remove-button">
                                                    <i class="bi bi-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr *ngIf="newMember" class="new-member">
                                            <td>
                                                <div class="form-group col">
                                                    <input type="input" class="form-control" id="newName" placeholder="Naam nieuwe gebruiker" [(ngModel)]="newMember.fullname">
                                                </div>
                                            </td>
                                            <td>
                                                <select class="form-select" [(ngModel)]="selectedRole">
                                                    <option *ngFor="let kv of roleStrings" [ngValue]="kv[0]">{{kv[1]}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <div class="form-group col">
                                                    <input type="input" class="form-control" id="newEmailAdress" placeholder="E-mail adres nieuwe gebruiker" [(ngModel)]="newMember.emailAdress">
                                                </div>
                                                <div class="form-group col mt-1">
                                                    <input type="password" class="form-control" id="newMemberPass" placeholder="Wachtwoord nieuwe gebruiker" [(ngModel)]="newMemberPass">
                                                </div>
                                            </td>
                                            <td>
                                                <button class="btn btn-success add-button" (click)="addUserToTeam()">
                                                    <i class="bi bi-plus-square"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    
                    <div class="tab-pane fade" id="nav-external-users-management" role="tabpanel" aria-labelledby="nav-external-users-management-tab">
                        <div class="row">
                            <div class="col-12 mt-2">
                                <h4>Externe Gebruikers</h4>
                                <div class="col introduction">
                                    <span>In dit menu externe gebruikers beheren. </span>
                                    <div class="success-warning message">
                                        <div class="alert alert-warning" role="alert">
                                            <strong>Let op!</strong> Deze functionaliteit is nog niet operationeel
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-2">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Volledige Naam</th>
                                            <th scope="col">Klant</th>
                                            <th scope="col">Emailadres</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let member of externalUsers">
                                            <th scope="row">{{member.fullname}}</th>
                                            <td>
                                                Deze klant
                                            </td>
                                            <td>{{member.emailAdress}}</td>
                                            <td>
                                                <button *ngIf="user?.$id != member.userId" class="btn btn-danger remove-button" (click)="removeMemberFromTeam(member)">
                                                    <i class="bi bi-trash"></i>
                                                </button>
                                                <button disabled *ngIf="user?.$id == member.userId" class="btn btn-danger remove-button">
                                                    <i class="bi bi-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr *ngIf="newMember" class="new-member">
                                            <td>
                                                <div class="form-group col">
                                                    <input type="input" class="form-control" id="newName" placeholder="Naam nieuwe gebruiker" [(ngModel)]="newMember.fullname">
                                                </div>
                                            </td>
                                            <td>
                                                Klant
                                            </td>
                                            <td>
                                                <div class="form-group col">
                                                    <input type="input" class="form-control" id="newEmailAdress" placeholder="E-mail adres nieuwe gebruiker" [(ngModel)]="newMember.emailAdress">
                                                </div>
                                                <div class="form-group col mt-1">
                                                    <input type="password" class="form-control" id="newMemberPass" placeholder="Wachtwoord nieuwe gebruiker" [(ngModel)]="newMemberPass">
                                                </div>
                                            </td>
                                            <td>
                                                <button disabled class="btn btn-success add-button" (click)="addExternalUser()">
                                                    <i class="bi bi-plus-square"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
<!-- End Tabs -->
                </div>
            </div>
        </div>
    </div>


    <div class="row header card" *ngIf="teamMembers">
        <div class="card-body">
         <div class="col-12">
             <h4>Rechten Management</h4>
             <div class="col introduction">
                 <span>In dit menu kunt u uw module rechten beheren. Klik op het icoontje van de module om de volledige naam te zien. Het aan/uitvinken van rechten wordt direct opgeslagen. </span>
             </div>
         </div>
         <div class="col">
             <table class="table">
                 <thead>
                     <tr>
                         <th scope="col">Rol</th>
                         @for (mod of moduleStrings; track mod) {
                         <th scope="col">
                            <i class="clickable bi {{getModuleIcon(mod[0])}}" [satPopoverAnchor]="popover" (click)="popover.toggle()"></i>
                            <sat-popover #popover hasBackdrop horizontalAlign="center" verticalAlign="below">
                                <div class="bg-dark text-white border rounded p-2" style="max-width: 520px">
                                    {{mod[1]}}
                                </div>
                            </sat-popover>
                         </th>
                         }
                     </tr>
                 </thead>
                 <tbody>
                    @for (rolekv of roleStrings; track rolekv) {
                        @if (canPrintRoleDepartmentHeader(rolekv[1])) {
                            <tr>
                                <td colspan="100%" class="fw-bold">{{getRoleDepartmentHeader(rolekv[1])}}</td>
                            </tr>
                        }
                            
                        <tr>
                            <td scope="row">{{rolekv[1]}}</td>
                            @for (modkv of moduleStrings; track modkv) {
                                <td><input type="checkbox" [checked]="roleModChecked(rolekv[0], modkv[0])" (change)="updateTeamPrefs(rolekv[0], modkv[0])"></td>
                            }
                        </tr>
                    }
                 </tbody>
             </table>
         </div>
        </div>
     </div>
</div>

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  showEmailWarning: boolean = false;
  emailWarningMessage: string = "";
  showPasswordWarning: boolean = false;
  passwordWarningMessage: string = "";
  showPasswordConfirmWarning: boolean = false;
  passwordConfirmWarningMessage: string = "";
  showAgreedOnTermsWarning: boolean = false;
  agreedOnTermsWarningMessage: string = "";

  form: FormGroup = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
    passwordConfirm: new FormControl(''),
    agreedOnTerms: new FormControl(false),
  });
  submitted = false;

  constructor(
    public authService: AuthService,
    private readonly router: Router,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      passwordConfirm: ['', Validators.required],
      agreedOnTerms: [false, Validators.required]
    });
  }

  get email() {
    return this.form.get('email');
  }

  get password() {
    return this.form.get('password');
  }

  get passwordCofirm() {
    return this.form.get('passwordConfirm');
  }

  get agreedOnTerms() {
    return this.form.get('agreedOnTerms');
  }

  signUp(){
    const email: string = this.email?.value;
    const password: string = this.password?.value;
    const passwordConfirm: string = this.passwordCofirm?.value;
    const agreedOnTerms: boolean = this.agreedOnTerms?.value;

    // Reset values
    this.showPasswordWarning = false;
    this.showPasswordConfirmWarning = false;
    this.showEmailWarning = false;
    this.showAgreedOnTermsWarning = false;

    if(email == ""){
      this.showEmailWarning = true;
      this.emailWarningMessage = "Voer een email adres in";
    }

    if(password == ""){
      this.showPasswordWarning = true;
      this.passwordWarningMessage = "Voer een wachtwoord in";
    }

    if(passwordConfirm == ""){
      this.showPasswordConfirmWarning = true;
      this.passwordConfirmWarningMessage = "Herhaal het ingevoerde wachtwoord";
    }

    if(password != "" && passwordConfirm != ""){
      if(password != passwordConfirm){
        this.showPasswordConfirmWarning = true;
        this.passwordConfirmWarningMessage = "Het ingevoerde wachtwoord komt niet overeen";
        return;
      }
    }

    if(password.length < 8){
      this.showPasswordWarning = true;
      this.passwordWarningMessage = "Het wachtwoord moet tenminste 8 tekens bevatten";
      return;
    }

    if(email == "" || password == "" || passwordConfirm == ""){
      return;
    }

    if(!agreedOnTerms){
      this.showAgreedOnTermsWarning = true;
      this.agreedOnTermsWarningMessage = "Het is verplicht om akkoord te gaan met de gestelde voorwaarden en het privacy-statement";
      return;
    }

    this.authService
    .register({email, password})
    .then((userCredentials) => {
      if(userCredentials.user.email != null){
        // Fetch User
        this.authService.checkUserState();
      }
      this.router.navigate(['/portal']);
    })
    .catch((e) => {
      this.showEmailWarning = true;
      this.emailWarningMessage = "Registratie mislukt! Heeft u al een account met dit e-mailadres?"
    });
  }
}


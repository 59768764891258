<div class="row">
    <h5>Transfers &#38; Hotels</h5>
    <p>Beheer in onderstaand gedeelte eventuele Transfers &#38; Hotels welke behoren tot deze inzet. De gekoppelde monteurs kan deze gegevens later inzien.</p>
</div>
<div class="row">
    @if(serviceRequest?.travelComponents){
        <div class="col">
            @for(travelComponent of serviceRequest!.travelComponents; track travelComponent.$id){
                <div>
                    <app-travel-component [travelComponent]="travelComponent"></app-travel-component>
                </div>
            }
        </div>
    }
</div>
<div class="row">
    <div class="col">
        <div class="form-group add-new-travel-component">
            <label for="travelCoponentTypeInput">Nieuwe Transfer/Hotel:</label>
            <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <i class="{{travelComponentService.getStatusIcon(travelComponentType)}}"></i>
                </div>
                </div>
                <select [(ngModel)]="travelComponentType" class="form-control" name="travelComponentType" id="travelCoponentTypeInput">
                    @for(statusItem of travelComponentService.travelComponentTypes; track statusItem){
                        <option value="{{statusItem.value}}">
                            {{statusItem.description}} 
                        </option>
                    }
                </select>
                <button class="btn btn-success" (click)="addNewTravelComponent()"><i class="bi bi-plus-square"></i></button>
              </div>
        </div>
    </div>
</div>

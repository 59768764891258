import { Injectable } from '@angular/core';
import { ID, Models, Permission, Query, Role } from 'appwrite';
import { Api } from 'src/app/helpers/api';
import { Tool } from 'src/app/models/tool';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ToolService {

  toolStatusItems: any[];
  toolTable: string = "Tools";
  toolTypes: string[];

  constructor() { 
    this.toolTypes = ['Bedraad', "Accu", 'Spanningsloos', 'Luchtdruk', 'Overig'];

    this.toolStatusItems = [
      {
        value: "Beschikbaar",
        description: "Beschikbaar",
        iconSource: "bi bi-check-circle",
        color: "#198754",
        id: 1
      },
      {
        value: "Niet Beschikbaar",
        description: "Niet Beschikbaar",
        iconSource: "bi bi-exclamation-circle",
        color: "#ff3333",
        id: 2
      },
      {
        value: "Afgekeurd",
        description: "Afgekeurd",
        iconSource: "bi bi-x-circle",
        color: "#e6b800",
        id: 2
      },
      {
        value: "Onbekend",
        description: "Onbekend",
        iconSource: "bi bi-question-circle",
        color: "#ff6600",
        id: 3
      }
    ];
  }

  /**
    * This method will retrieve all active Tools for the current team
   */
  async getTools(){
    var resultCount = 50;
    var resultList: Tool[] = [];
    var lastId: string | undefined = undefined;
    var results: Models.DocumentList<Tool>;

    while(resultCount == 50){
      if(lastId){
        results = await Api.database.listDocuments<Tool>(
          environment.database,
          this.toolTable,
          [Query.limit(50), Query.cursorAfter(lastId)]
        );
      } else {
        results = await Api.database.listDocuments<Tool>(
          environment.database,
          this.toolTable,
          [Query.limit(50)]
        );
      }

      lastId = results?.documents[results.documents.length - 1]?.$id;
      resultList.push(...results.documents);
      resultCount = results.documents.length;
    }

    return resultList;
  }

  /**
   * This method will create a new Tool in the database
   */
  addTool(tool: Tool, teamId: string, writePermissions: string[]) {
    return Api.database.createDocument(environment.database, this.toolTable, ID.unique(), this.createPartialTool(tool))
  }

  /**
   * This method will update an existing Tool in the Database
   */
  updateTool(tool: Tool) {
    return Api.database.updateDocument(environment.database, this.toolTable, tool.$id, this.createPartialTool(tool));
  }

  /**
   * This method will delete an existing Tool from the database
   */
  deleteTool(tool: Tool) {
    return Api.database.deleteDocument(environment.database, this.toolTable, tool.$id);
  }

  private createPartialTool(tool: Tool) : Partial<Tool> {
    return {
      name: tool.name,
      attachedToId: tool.attachedToId,
      additionalMessage: tool.additionalMessage,
      available: tool.available,
      brand: tool.brand,
      checkBeforeDate: tool.checkBeforeDate,
      purchaseDate: tool.purchaseDate,
      scheduledInspectionDate: tool.scheduledInspectionDate,
      type: tool.type
    };
  }

  /**
   * This method will return the icon resource based on the status
   */
  getStatusIcon(status: string) {
    if (!status) {
      return;
    }

    var statusResource = this.toolStatusItems.filter(s => s.value == status);

    if (!statusResource || statusResource?.length == 0) {
      return;
    }

    return statusResource[0].iconSource;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { LogEntry } from 'src/app/models/logEntry';
import { Role } from 'src/app/models/roleEnum';
import { ServiceRequest } from 'src/app/models/service-request';
import { WVBTask } from 'src/app/models/wvb-task';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { WvbTaskService } from 'src/app/services/wvb-task/wvb-task.service';

@Component({
  selector: 'app-work-preparation-task-create',
  templateUrl: './work-preparation-task-create.component.html',
  styleUrl: './work-preparation-task-create.component.scss'
})
export class WorkPreparationTaskCreateComponent implements OnInit {

  @Input() wvbTask?: WVBTask
  @Input() serviceRequest?: ServiceRequest
  newLogEntry: Partial<LogEntry>

  // The Subscriptions to Unsub on destroy
  subs: Subscription[] = [];

  constructor(public wvbTaskService: WvbTaskService, private communicationService: CommunicationService,
    private permissionService: PermissionService
  ) { 
    
    // LogEntry Related
    let uName = permissionService.currentUser?.name
    let uRole = Role[permissionService.currentUserRole as keyof typeof Role]
    this.newLogEntry = {by: `${uName} (${uRole})`, message: ''}
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe()
    })
  }

  ngOnInit(): void {
    // Prevent Add/Edit from staying open on wrong SRQ
    this.subs.push(
      this.communicationService.activeServiceRequest.subscribe(srq => {
        if (this.serviceRequest?.$id != srq?.$id) {
          this.close()
        }
      })
    )
  }

  /**
   * Trigger close operation 
   */
  close(){
    this.wvbTask = undefined;
    this.communicationService.activeWVBTask.next(undefined);
  }

   /**
   * This method will create a new WVBTask
   * @returns 
   */
   async createWVBTask(){
    if(!this.wvbTask){
      return;
    }

    if(this.newLogEntry.message && this.newLogEntry.message?.length > 0) {
      this.wvbTask.logEntries?.push(this.newLogEntry)
    }

    if(this.wvbTask.$id != "") {
      // Edit Current
      this.wvbTaskService.updateWVBTask(this.wvbTask).then(async result => {
        if(!result || !this.wvbTask){
          return;
        }
        
        // this.wvbTask!.$id = result.$id;
        
        // let activeWVBTasks = this.communicationService.activeWVBTasks.getValue();
        // activeWVBTasks.push(result as WVBTask);
        // this.communicationService.activeWVBTasks.next(activeWVBTasks);
        this.communicationService.activeToastKind.next("Success");
        this.communicationService.activeToastMessage.next(`Het aanpassen van WVB Taak ${this.wvbTask?.name} is gelukt!`);
      
        this.wvbTask = undefined;
        this.communicationService.activeWVBTask.next(undefined);
        
        return Promise.resolve();
      });

    } else {
      // Create New
      this.wvbTaskService.addWVBTask(this.wvbTask).then(async result => {
        if(!result || !this.wvbTask){
          return;
        }
        
        this.wvbTask!.$id = result.$id;
        
        let activeWVBTasks = this.communicationService.activeWVBTasks.getValue();
        activeWVBTasks.push(result as WVBTask);

        this.communicationService.activeToastKind.next("Success");
        this.communicationService.activeToastMessage.next(`Het toevoegen van WVB Taak ${this.wvbTask?.name} is gelukt!`);


        this.communicationService.activeWVBTasks.next(activeWVBTasks);
        this.wvbTask = undefined;
        this.communicationService.activeWVBTask.next(undefined);
        
        return Promise.resolve();
      });
    }
  }

  /**
   * Callback event for changing the Deadline
   * @param event 
   */
  onDeadlineValuesChanged(event: any){
    if(this.wvbTask){
      this.wvbTask.deadline = moment(event).toDate();
    }
  }
}

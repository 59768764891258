import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import * as moment from 'moment';
import { ServiceRequest } from 'src/app/models/service-request';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { MachineService } from 'src/app/services/machine/machine.service';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { ServiceRequestService } from 'src/app/services/service-request/service-request.service';
import { WindowService } from 'src/app/services/window/window.service';
import { ServiceRequestScheduleComponent } from '../service-request-schedule/service-request-schedule.component';
import { ContextService } from 'src/app/services/context/context.service';
import { TravelComponentService } from 'src/app/services/travel-component/travel-component.service';
import { ServiceRequestContactPersonsComponent } from "../service-request-contact-persons/service-request-contact-persons.component";
import { TravelComponentsListComponent } from '../../travel-components/travel-components-list/travel-components-list.component';
import { ContactPersonService } from 'src/app/services/contact-person/contact-person.service';
import { ServiceRequestCustomerValuesComponent } from "../service-request-customer-values/service-request-customer-values.component";

@Component({
  standalone: true,
  imports: [
    FormsModule,
    BrowserModule,
    TravelComponentsListComponent,
    ServiceRequestScheduleComponent,
    ServiceRequestContactPersonsComponent,
    ServiceRequestCustomerValuesComponent
],
  selector: 'app-service-request-create',
  templateUrl: './service-request-create.component.html',
  styleUrls: ['./service-request-create.component.scss']
})
export class ServiceRequestCreateComponent implements OnInit {

  @Input() serviceRequest?: ServiceRequest;
  showScheduleAndTransfers: boolean = false;

  constructor(public contextService: ContextService, private contactPersonService: ContactPersonService, private travelComponentService: TravelComponentService, public serviceRequestService: ServiceRequestService, private machineService: MachineService, private companyService: CompanyService, private communicationService: CommunicationService, private windowService: WindowService, private permissionService: PermissionService) { 
  }

  ngOnInit(): void {
    if (this.serviceRequest && !this.serviceRequest.$id) {
      // Set Defaults for Create
      this.serviceRequest.startTime = moment(new Date()).hours(7).minutes(30).seconds(0).toDate();
      this.serviceRequest.endTime = moment(new Date()).hours(17).minutes(0).seconds(0).toDate();
    }
  }

  /**
   * Trigger close operation 
   */
  close(){
    this.serviceRequest = undefined;
    this.communicationService.activeServiceRequest.next(undefined);
  }

  /**
   * This method will create a new ServiceRequest
   * @returns 
   */
  async createServiceRequest(){
    if(!this.serviceRequest){
      return;
    }

    // After createing ServiceRequest, Add Windows
    this.serviceRequestService.addServiceRequest(this.serviceRequest, this.communicationService.activeTeamId.getValue()!, this.permissionService.getWritePermissions()).then(async result => {
      if(!result || !this.serviceRequest){
        return;
      }

      this.serviceRequest!.$id = result.$id;

      if(this.serviceRequest.windows){
        await Promise.all(this.serviceRequest.windows.map(async (window) => {
          window.serviceRequestId = result.$id;

          if(window.displayName?.includes("Inzet")){
            return this.windowService.addWindow(window, this.communicationService.activeTeamId.getValue()!, this.permissionService.getWritePermissions()).then(windowResult => {
              window.$id = windowResult?.$id
              window.parentRequest = this.serviceRequest;
           });
          } else {
            return Promise.resolve();
          }
        }));
      }

      // Create TravelComponents if present
      if(this.serviceRequest.travelComponents){
        this.serviceRequest.travelComponents.map(travelComponent => {
          // Check wether the TravelComponents are existing or needs to be created
          // And set reference to the service Request
          travelComponent.serviceRequestId = this.serviceRequest!.$id;

          if(travelComponent.$id){
            // Update Component
            this.travelComponentService.updateTravelComponent(travelComponent).catch(() => {
              this.communicationService.activeToastKind.next("Error");
              this.communicationService.activeToastMessage.next(`Het updaten van reisdocument '${travelComponent?.name}' is mislukt!`);
            });
          } else {
            // Create Component
            this.travelComponentService.addTravelComponent(travelComponent).then(travelComponentResult => {
              travelComponent.$id = travelComponentResult.$id;
            }).catch(() => {
              this.communicationService.activeToastKind.next("Error");
              this.communicationService.activeToastMessage.next(`Het updaten van reisdocument '${travelComponent?.name}' is mislukt!`);
            });
          }
        });
      }

      // If there are removed TravelComponents then remove these from the database including the documents
      if(this.serviceRequest.removedTravelComponents){
        await Promise.all(await this.serviceRequest.removedTravelComponents.map(async removedTravelComponent => {
          // Check if the TravelComponent has document references
          removedTravelComponent.documentReferences?.split(" ").map(async documentReference => {
            // Remove document
            await this.travelComponentService.removeTravelDocument(documentReference);
          });

          // Remove actual component
          return this.travelComponentService.deleteTravelComponent(removedTravelComponent);
        }));

        this.serviceRequest.removedTravelComponents = [];
      }

      var activeServiceRequests = this.communicationService.activeServiceRequests.getValue();
      var createdServiceRequest = result as ServiceRequest;

      // Red-Add windows and company in order to be able to display the ServiceRequest without a refresh
      createdServiceRequest.windows = structuredClone(this.serviceRequest.windows);

      activeServiceRequests.push(createdServiceRequest);
      this.communicationService.activeServiceRequests.next(activeServiceRequests);

      this.communicationService.activeToastKind.next("Success");
      this.communicationService.activeToastMessage.next(`Het aanmaken van serviceverzoek '${this.serviceRequest?.name}' is gelukt!`);
      
      this.serviceRequest = undefined;
      this.communicationService.activeServiceRequest.next(undefined);
      return Promise.resolve();
    }).catch(() => {
      this.communicationService.activeToastKind.next("Error");
      this.communicationService.activeToastMessage.next(`Het aanmaken van serviceverzoek '${this.serviceRequest?.name}' is mislukt!`);
    });
  }

  /**
   * Callback event for changing the EndTime
   * @param event 
   */
  onEndTimeValuesChanged(event: any){
    if(this.serviceRequest){
      this.serviceRequest.endTime = moment(event).toDate();
    }
  }

  /**
   * Callback event for changing the StartTime
   * @param event 
   */
  onStartTimeValuesChanged(event: Date){
    if(this.serviceRequest){
      //  Do Not Force 7:30 and do not autocomplete enddate
      this.serviceRequest.startTime = moment(event).toDate();
      this.communicationService.activeStartDate.next(this.serviceRequest.startTime);

      if(moment(this.serviceRequest.endTime).isBefore(moment(this.serviceRequest.startTime))){
        this.serviceRequest.endTime = moment(this.serviceRequest.startTime).hours(17).minutes(0).toDate();
      }
    }
  }

  /**
   * This method will set the name of the ServiceRequest
   * @param name 
   */
  setName(name: string){
    if(this.serviceRequest){
      this.serviceRequest.name = name;
    }
  }

  /**
   * This method will try to find an existing machine with the presented number 
   */
  machineNumberInputChanged(){
    if((this.serviceRequest?.machineId?.length ?? 0) < 3){
      this.serviceRequest!.referencedMachine = undefined;
      this.serviceRequest!.referencedMachineId = undefined;
      return;
    }

    this.machineService.getMachinesByMachineNumber(this.serviceRequest!.machineId!).then(machine => {
      if(!machine){
        this.serviceRequest!.referencedMachine = undefined;
        this.serviceRequest!.referencedMachineId = undefined;
        return;
      }

      this.serviceRequest!.referencedMachine = machine;
      this.serviceRequest!.referencedMachineId = machine.$id;
      this.serviceRequest!.machineId = machine.machineId;

      // Only if the referenced machine is not present yet
      if(machine.relatedCompanyId && !machine.company){
        var matchingCompanies = this.communicationService.activeCompanies.getValue()?.filter(c => c.$id.includes(machine.relatedCompanyId!));

        if(matchingCompanies.length > 0){
          this.serviceRequest!.referencedMachine!.company = matchingCompanies[0];
        }
      }

      // When the CustomRelationId is present search for the active set of ContactPersons
      if(this.serviceRequest!.referencedMachine?.relatedCompanyId){
        // Find contact persons to attach
        this.contactPersonService.getContactPersonsOfCompany(this.serviceRequest!.referencedMachine?.relatedCompanyId).then(contactPersons => {
          if(this.serviceRequest?.referencedMachine?.company){
            this.serviceRequest.referencedMachine.company.contactPersons = contactPersons.documents;
          }
        });
      }

    }).catch(() => {
      this.serviceRequest!.referencedMachine = undefined;
      this.serviceRequest!.referencedMachineId = undefined;
    });
  }
}

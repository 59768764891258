import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {

  showEmailWarning: boolean = false;
  emailWarningMessage: string = "";
  successFeedback?: string;

  form: FormGroup = new FormGroup({
    email: new FormControl(''),
  });

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

  get email() {
    return this.form.get('email');
  }

  set setEmail(value: string) {
    this.form.setValue({ key: 'email', value: value});
  }

  recoverPassword(){
    var email = this.email?.value;

    if(email){
      this.authService.recoverPassword(email).then(token => {
        if(!token){
          this.emailWarningMessage = "Het versturen van een herstel-bericht is niet gelukt";
          this.showEmailWarning = true;
        }
        this.successFeedback = "Als er een gebruiker bestaat met het opgegeven email adres is er een herstelbericht verstuurd met een geldigheid van één uur."
      }).catch(e => {
      });
    } else {
      this.emailWarningMessage = "Vul een geldig emailadres in";
      this.showEmailWarning = true;
    }
  }
}

@if(showAllMode || (!showAllMode && activeServiceRequest) ){
    <!-- Do not show if not ShowAllmode and no servicerequest is active -->
<div class="row mt-2" (click)="onClickLostFocus()">
    <div class="div card {{viewOnly? 'border-0' : ''}}">
        <div class="card-body">
            <div class="row">
                <!-- We do not display details when ViewOnly, Only the table -->
                @if (!viewOnly) { 

                    <h4 class="col card-title">Overzichtslijst {{showAllMode? "Alle" : "Serviceverzoek"}} Werkvoorbereiding Taken</h4>
                    <div class="col-11">
                        <p>In onderstaande lijst kunnen de WVB-taken worden beheerd en bekeken. Er kunnen losse nieuwe taken worden toegevoegd, daarnaast is het mogelijk om een preset te kiezen waardoor er taken in bulk kunnen worden toegevoegd.</p>
                    </div>
                <div class="header-wrapper row">
                    @if(activeServiceRequest && !showAllMode){
                        <div class="col-4">
                            <span>Service inzet: <strong>{{activeServiceRequest.orderId}} - {{activeServiceRequest.referencedMachine?.company?.companyName}}</strong></span>
                            <button class="btn bi bi-x-circle" (click)="removeServiceRequestFilter()"></button>
                        </div>
                    }
                    <div class="col header-actions">
                        <div class="slider-filter">
                            <div class="switch-wrapper">
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" role="switch" id="displayPerformedTasks" [(ngModel)]="showClosedTasks" (ngModelChange)="toggleShowPerformedTasks()">
                                    <label class="form-check-label" for="displayPerformedTasks">Uitgevoerde taken weergeven</label>
                                </div>
                            </div>
                        </div>
                        <div class="filter-selection">
                            <i class="bi bi-funnel"></i>
                            <p-multiSelect 
                            [options]="allDepartments" 
                            [(ngModel)]="selectedDepartments" 
                            optionLabel="name" 
                            (onChange)="filterDepartments($event)"
                            placeholder="Selecteer Afdeling" />
                        </div>
                        <!-- Preset should only work when service request is selected -->
                        @if (!!activeServiceRequest?.$id) {
                            <div class="preset-wrapper">
                                <i class="bi bi-collection"></i>
                                <select id="WVBTaskDepartment" class="form-select" [(ngModel)]="selectedPreset">
                                    @for (preset of allPresets; track $index) {
                                        <option [ngValue]="preset"><span>{{preset.name}}</span></option>
                                    }
                                </select>
                            </div>
                            <div class="preset-actions">
                                <div class="add-preset btn btn-success" (click)="insertPreset()"><i class="bi bi-plus-square"></i><span>Preset</span></div>
                            </div>
                        }
                    </div>
                </div>
                
                }
            </div>

            <table class="table">
                <thead>
                    <tr>
                        <th style="width: {{!viewOnly ? '45%' : '60%'}}" class="sortable" scope="col" (click)="sortWVBTasksBy('name')">Naam
                            <i class="bi bi-caret-down-fill" *ngIf="sortedBy == 'name' && sortedAscending"></i>
                            <i class="bi bi-caret-up-fill" *ngIf="sortedBy == 'name' && !sortedAscending"></i>
                        </th>
                        <th style="width: 16%" class="sortable" scope="col" (click)="sortWVBTasksBy('department')">Afdeling
                            <i class="bi bi-caret-down-fill" *ngIf="sortedBy == 'department' && sortedAscending"></i>
                            <i class="bi bi-caret-up-fill" *ngIf="sortedBy == 'department' && !sortedAscending"></i>
                        </th>

                        <th style="width: 12%" class="sortable" scope="col" (click)="sortWVBTasksBy('deadline')">Deadline
                            <i class="bi bi-caret-down-fill" *ngIf="sortedBy == 'deadline' && sortedAscending"></i>
                            <i class="bi bi-caret-up-fill" *ngIf="sortedBy == 'deadline' && !sortedAscending"></i>
                        </th>
                        <th style="width: 12%" class="sortable" scope="col" (click)="sortWVBTasksBy('status')">Status
                            <i class="bi bi-caret-down-fill" *ngIf="sortedBy == 'status' && sortedAscending"></i>
                            <i class="bi bi-caret-up-fill" *ngIf="sortedBy == 'status' && !sortedAscending"></i>
                        </th>
                        @if (!viewOnly) { 
                        <th  style="width: 15%" scope="col">Acties</th>
                        }
                    </tr>
                </thead>
                <tbody *ngIf="paginatedActiveWVBTasks">
                    <tr *ngFor="let wVBTask of paginatedActiveWVBTasks[activePage]; index as i" [ngClass]="{'active': activeWVBTask == wVBTask}">
                        <td>
                            @if (!!wVBTask.logEntries && wVBTask.logEntries.length > 0) {
                                <button class="btn log-book-open" (click)="openLogBook(wVBTask)"><i class="bi bi-journal-text"></i></button>
                            } @else {
                                <button class="btn border-0 fade-out" disabled><i class="bi bi-journal-x"></i></button>
                            }
                            @if (!!wVBTask.description) 
                            {<button class="btn" (click)="toggleShowTaskDescription(wVBTask)">
                                @if (showDescriptionTaskIds.indexOf(wVBTask.$id) != -1) {<i class="bi bi-arrow-up-circle"></i>}
                                @else{<i class="bi bi-arrow-down-circle"></i>}
                            </button>}
                            {{wVBTask.name}} 
                            @if (showDescriptionTaskIds.indexOf(wVBTask.$id) != -1) {
                                <div class="task-description p-2">
                                    {{wVBTask.description}}
                                </div>
                            }
                        </td>
                        <td>{{wVBTask.department}}</td>
                        <td>{{wVBTask.deadline | date: 'dd-MM-yyyy - HH:mm'}}</td>

                        <td class="status-line">
                            <div class="status-badge-section">
                                <span class="badge badge-primary" (click)="onStatusExpanderClick(wVBTask.$id)" [ngStyle]="{'background-color': getStatusColor(wVBTask.status)}">
                                    <i class="{{getStatusIcon(wVBTask.status)}}"></i>
                                    {{wVBTask.status}}
                                </span>
                                <div (click)="onStatusExpanderClick(wVBTask.$id)" *ngIf="!isViewer">
                                    <i class="bi bi-caret-down-fill"></i>
                                </div>
                                <div class="other-status" *ngIf="communicationService.openStatusWVBTaskId == wVBTask.$id && communicationService.openStatusWVBTaskId && !isViewer">
                                    <div class="row" *ngFor="let statusItem of wvbTaskService.wvbTaskStatus">
                                        <div class="col">
                                            <span (click)="statusSelectionChanged(wVBTask, statusItem.value)" class="badge badge-primary" [ngStyle]="{'background-color': getStatusColor(statusItem.value)}">
                                                <i class="{{getStatusIcon(statusItem.value)}}"></i>
                                                {{statusItem.description}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="service-request-actions">
                            @if (!viewOnly) { 
                                <button class="btn btn-primary action-button first-button ms-1" id="WVBTedit{{i}}" (click)="editWVBTask(wVBTask)">
                                    <i class="bi bi-pencil"></i>
                                </button>
                                <button *ngIf="!isViewer" class="btn btn-danger remove-button ms-1" id="WVBTremove{{i}}" (click)="removeWVBTask(wVBTask, i, true)">
                                    <i class="bi bi-trash"></i>
                                </button>
                                <button *ngIf="!isViewer" class="btn btn-danger remove-confirmation" id="WVBTconfirm-remove{{i}}" (click)="removeWVBTask(wVBTask, i, false)">Bevestig</button>
                                <button *ngIf="!isViewer" class="btn btn-primary action-button cancel-remove" id="WVBTcancel-remove{{i}}" (click)="cancelRemoval(i)"><i class="bi bi-x-circle"></i></button>
                            } 
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="table-footer">
                <div class="table-footer-actions" [ngClass]="{'align-right': false || !paginatedActiveWVBTasks}">
                    <nav aria-label="Page navigation" *ngIf="paginatedActiveWVBTasks?.length ?? 0 > 0">
                        <ul class="pagination">
                            <li class="page-item" (click)="activePage = activePage - 1">
                                <a class="page-link" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>
                            <li class="page-item" (click)="activePage = i" *ngFor="let subSet of paginatedActiveWVBTasks; let i = index">
                                <a class="page-link active-page" *ngIf="i == activePage">{{i + 1}}</a>
                                <a class="page-link" *ngIf="i != activePage">{{i + 1}}</a>
                            </li>
                            <li class="page-item" (click)="activePage = activePage + 1">
                                <a class="page-link" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        </ul>
                        <div class="per-page">
                            <div class="form-group">
                                <select class="form-select" [(ngModel)]="perPage" (ngModelChange)="onPerPageChanged()">
                                    <option [ngValue]="5"><span>5</span></option>
                                    <option [ngValue]="10"><span>10</span></option>
                                    <option [ngValue]="20"><span>20</span></option>
                                    <option [ngValue]="50"><span>50</span></option>
                                </select>
                            </div>
                            <span>Taken per pagina</span>
                        </div>
                    </nav>
                    @if (!viewOnly) { 
                    @if(activeServiceRequest){
                        <div class="task-actions">
                            <div class="btn btn-success create-request" (click)="createWVBTask()"><i class="bi bi-plus-square"></i> Taak aanmaken</div>
                        </div>
                    }
                    }
                </div>

            </div>
        </div>
    </div>
</div>
}
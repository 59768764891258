<div class="modal-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <span class="float-end clickable" (click)="closeModal()"><i class="bi bi-x-lg"></i></span>
                <h5 class="text-center"><i class="bi bi-journal-text"></i> Logboek</h5>
                <hr>
                @for (logEntry of logEntries; track $index) {
                    <app-log-book-line [logEntry]="logEntry"></app-log-book-line>
                }
            </div>
        </div>
    </div>
    
</div>
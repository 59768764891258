import { Injectable } from '@angular/core';
import { ID, Models, Permission, Query, Role } from 'appwrite';
import * as moment from 'moment';
import { Api } from 'src/app/helpers/api';
import { Mechanic } from 'src/app/models/mechanic';
import { Unavailability } from 'src/app/models/unavailability';
import { Vehicle } from 'src/app/models/vehicle';
import { Window } from 'src/app/models/window';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UnavailabilityService {

  unavailabilityTypeListVehicles: any[]
  filteredUnavailabilityTypeListVehicles: any[]
  unavailabilityTypeListMechanics: any[]
  unavailabilityTable: string = "Unavailabilities"

  constructor() {
    this.unavailabilityTypeListVehicles = [
      {
        value: "Ingepland",
        description: "Ingepland",
        iconSource: "bi bi-check-circle",
        color: "#ff99ff",
        id: 1
      },
      {
        value: "Onderhoud",
        description: "Onderhoud",
        iconSource: "bi bi-check-circle",
        color: "#ffcc00",
        id: 2
      },
      {
        value: "Reparatie",
        description: "Reparatie",
        iconSource: "bi bi-check-circle",
        color: "#ff4d4d",
        id: 3
      },
      {
        value: "Defect",
        description: "Defect",
        iconSource: "bi bi-check-circle",
        color: "#ff4d4d",
        id: 3
      },
      {
        value: "Overig",
        description: "Overig",
        iconSource: "bi bi-check-circle",
        color: "#ff4d4d",
        id: 3
      },
    ];

    this.filteredUnavailabilityTypeListVehicles = this.unavailabilityTypeListVehicles.slice(1)
    
    this.unavailabilityTypeListMechanics = [
      {
        value: "Gereserveerd",
        description: "Gereserveerd",
        iconSource: "bi bi-check-circle",
        color: "#cc3399",
        id: 1
      },
      {
        value: "Verlof",
        description: "Verlof",
        iconSource: "bi bi-check-circle",
        color: "#33cc33",
        id: 2
      },
      {
        value: "Vakantie",
        description: "Vakantie",
        iconSource: "bi bi-check-circle",
        color: "#33cc80",
        id: 3
      },
      {
        value: "Ziek",
        description: "Ziek",
        iconSource: "bi bi-check-circle",
        color: "#cca633",
        id: 3
      },
      {
        value: "Feestdag",
        description: "Feestdag",
        iconSource: "bi bi-check-circle",
        color: "#3359cc",
        id: 3
      },
      {
        value: "Overig",
        description: "Overig",
        iconSource: "bi bi-check-circle",
        color: "#cc3333",
        id: 3
      },
    ];
   }

   /**
    * This method will retrieve all unavailabilities of the given resource
    * @param resourceId 
    * @returns 
    */
   async getUnavailabilitiesOfResource(resourceId: string){
    var resultCount = 50;
    var resultList: Unavailability[] = [];
    var lastId: string | undefined = undefined;
    var results: Models.DocumentList<Unavailability>;

    while(resultCount == 50){
      if(lastId){
        results = await Api.database.listDocuments<Unavailability>(
          environment.database,
          this.unavailabilityTable,
          [Query.limit(50), Query.equal('resourceId', resourceId), Query.cursorAfter(lastId)]
        );
      } else {
        results = await Api.database.listDocuments<Unavailability>(
          environment.database,
          this.unavailabilityTable,
          [Query.limit(50), Query.equal('resourceId', resourceId)]
        );
      }

      lastId = results?.documents[results.documents.length - 1]?.$id;
      resultList.push(...results.documents);
      resultCount = results.documents.length;
    }

    return resultList;
   }

  /**
  * This method will create a new Unavailability in the database
  */
  addUnavailability(unavailability: Unavailability, teamId: string, writePermissions: string[]) {
    return Api.database.createDocument(environment.database, this.unavailabilityTable, ID.unique(), this.createPartialUnavailability(unavailability))
  }

  /**
   * This method will update an existing Unavailability in the Database
   */
  updateUnavailability(unavailability: Unavailability) {
    return Api.database.updateDocument(environment.database, this.unavailabilityTable, unavailability.$id, this.createPartialUnavailability(unavailability));
  }

  /**
   * This method will delete an existing Unavailability from the database
   */
  deleteUnavailability(unavailability: Unavailability) {
    return Api.database.deleteDocument(environment.database, this.unavailabilityTable, unavailability.$id);
  }

  /**
   * This method will return the icon resource based on the status
  */
  getMechanicUnavailabilityStatusIcon(status: string) {
    if (!status) {
      return;
    }

    var statusResource = this.unavailabilityTypeListMechanics.filter(s => s.value == status);

    if (!statusResource || statusResource?.length == 0) {
      return;
    }

    return statusResource[0].iconSource;
  }

  /**
   * This method will return the icon resource based on the status
  */
  getVehicleUnavailabilityStatusIcon(status: string) {
    if (!status) {
      return;
    }

    var statusResource = this.unavailabilityTypeListVehicles.filter(s => s.value == status);

    if (!statusResource || statusResource?.length == 0) {
      return;
    }

    return statusResource[0].iconSource;
  }

  /**
   * This method will check wether there are vehicles attached and available
   * @param assignedResources 
   * @param vehicles 
   * @param serviceWindow
   * @returns 
   */
  vehiclesAreUnavailable(assignedResources: string[] | undefined, vehicles: Vehicle[], serviceWindow: Window) : string {
    if(!assignedResources || assignedResources?.length == 0 || !vehicles || vehicles?.length == 0){
      return "No-Resources-Attached";
    }

    // Find all attached Vehicles
    var assignedVehicles = vehicles.filter(v => assignedResources.filter(r => r == v.$id).length > 0);
    var unavaiable = false;  
    if(assignedVehicles.length > 0){
      assignedVehicles.forEach(v => {
        v.unavailability?.forEach(u => {

          var uStartDate = new Date(u.startTime);
          var sStartDate = new Date(serviceWindow.startTime);
          var uEndDate = new Date(u.endTime);

          let minutesTillUnavailability = moment(sStartDate).diff(moment(uStartDate), 'minutes');
          let minutesbetweenStartAndEnd = moment(uEndDate).diff(moment(uStartDate), 'minutes');

          // Check if unavailability is currently occuring
          if(minutesTillUnavailability >= 0 && minutesTillUnavailability < minutesbetweenStartAndEnd){
            unavaiable = true;
          }
        });
      });
    } else {
      return "No-Resources-Attached";
    }

    if(unavaiable){
      return "Unavailable";
    }

    return "Available";
  }

  /**
   * This method will check wether there are mechanics attached and available
   * @param assignedResources 
   * @param mechanics 
   * @param serviceWindow
   * @returns 
   */
  mechanicsAreUnavailable(assignedResources: string[] | undefined, mechanics: Mechanic[], serviceWindow: Window) : string {
    if(!assignedResources || assignedResources?.length == 0 || !mechanics || mechanics?.length == 0){
      return "No-Resources-Attached";
    }


    // Find all attached Vehicles
    var assignedVehicles = mechanics.filter(v => assignedResources.filter(r => r == v.$id).length > 0);
    var unavaiable = false;  
    if(assignedVehicles.length > 0){
      assignedVehicles.forEach(v => {
        v.unavailability?.forEach(u => {

          var uStartDate = new Date(u.startTime);
          var sStartDate = new Date(serviceWindow.startTime);
          var uEndDate = new Date(u.endTime);

          let minutesTillUnavailability = moment(sStartDate).diff(moment(uStartDate), 'minutes');
          let minutesbetweenStartAndEnd = moment(uEndDate).diff(moment(uStartDate), 'minutes');

          // Check if unavailability is currently occuring
          if(minutesTillUnavailability >= 0 && minutesTillUnavailability < minutesbetweenStartAndEnd){
            unavaiable = true;
          }
        });
      });
    } else {
      return "No-Resources-Attached";
    }

    if(unavaiable){
      return "Unavailable";
    }

    return "Available";
  }

  /**
   * Creates a partial instance of the mechanic without AppWrite properties
   * @param vehicle 
   * @returns 
   */
  private createPartialUnavailability(unavailability: Unavailability): Partial<Unavailability> {
    if (!unavailability) {
      return {};
    }

    return {
      additionalMessage: unavailability.additionalMessage,
      endTime: unavailability.endTime,
      startTime: unavailability.startTime,
      type: unavailability.type,
      resourceId: unavailability.resourceId
    };
  }
}

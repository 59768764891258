import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  showEmailWarning: boolean = false;
  emailWarningMessage: string = "";
  showPasswordWarning: boolean = false;
  passwordWarningMessage: string = "";

  form: FormGroup = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });
  submitted = false;

  // The Subscriptions to Unsub on destroy
  subs: Subscription[] = [];

  constructor(public authService: AuthService, private readonly router: Router, private fb: FormBuilder) {
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe()
    })
  }

  ngOnInit(): void {
    this.subs.push(
      this.authService.user.subscribe(usr => {
        if (usr.$id) {
          this.router.navigate(["/portal"]);
        }
      })
    )

    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  get email() {
    return this.form.get('email');
  }

  get password() {
    return this.form.get('password');
  }

  signIn() {
    const email: string = this.email?.value;
    const password: string = this.password?.value;

    // Reset values
    this.showPasswordWarning = false;
    this.showEmailWarning = false;

    if (email == "") {
      this.showEmailWarning = true;
      this.emailWarningMessage = "Voer een email adres in";
    }

    if (password == "") {
      this.showPasswordWarning = true;
      this.passwordWarningMessage = "Voer een wachtwoord in";
    }

    if (email == "" || password == "") {
      return;
    }

    this.authService
      .signIn({ email, password })
      .then((userCredentials) => {
        var email = userCredentials.user.email;

        if (email != null) {
          // Fetch User
          this.authService.checkUserState();
        }
        this.router.navigate(['/portal']);
      })
      .catch((e) => {
        this.showEmailWarning = true;
        this.emailWarningMessage = "Er is geen gebruiker gevonden met deze combinatie van inloggegevens"
      });
  }
}


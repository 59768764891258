<div class="row mt-2" *ngIf="wvbTask" id="new-wvb-task">
    <div class="col card">
        <div class="card-body">
           <h4>Werkvoorbereiding Taak {{wvbTask.$id ? "Aanpassen" : "Aanmaken"}}</h4>
           <p>Voer in onderstaande velden alle gegevens in die betrekking hebben tot de aan te maken of te beheren taak.</p>
           <div class="row input-row">
             <div class="col">
                <div class="task-attached-to">
                    <span>Taak gekoppeld aan: <strong>{{serviceRequest?.orderId}} - {{serviceRequest?.referencedMachine?.company?.companyName}}</strong></span>
                </div>
                 <div class="tab-pane fade show active" id="nav-request-details" role="tabpanel" aria-labelledby="nav-request-details-tab">
                    <div class="form-group">
                        <label for="inputWVBTaskName">Naam:</label>
                        <input type="text" class="form-control" id="inputWVBTaskName" placeholder="Taak naam..."  [(ngModel)]="wvbTask.name">
                    </div>
                    <div class="form-group">
                        <label for="inputWVBTaskDescription">Beschrijving:</label>
                        <textarea class="form-control" maxlength="1500" rows="2" [(ngModel)]="wvbTask.description"></textarea>
                        <div class="char-counter">
                            @if(wvbTask.description){
                                <div class="length-warning">
                                    @if(wvbTask.description!.length >= 1500){
                                        <i class="bi bi-exclamation-triangle-fill"></i>
                                        <small>Het maximaal aantal tekens is 1500</small>
                                    }
                                </div>
                                <small [ngClass]="wvbTask.description.length >= 1500 ? 'exceeded' : 'counter'">{{wvbTask.description.length}}/1500</small>
                            } @else {
                                <small>0/1500</small>
                            }
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <label for="inputDeadline">Deadline:</label>
                            <input type="datetime-local" class="form-control" id="inputDeadline" [ngModel]="wvbTask.deadline | date:'yyyy-MM-dd HH:mm'" (ngModelChange)="onDeadlineValuesChanged($event)">
                        </div>
                        <div class="form-group col">
                            <label for="WVBTaskDepartment">Afdeling:</label>
                            <select id="WVBTaskDepartment" class="form-select" [(ngModel)]="wvbTask.department">
                                <option [ngValue]="'Engineering'"><span>Engineering</span></option>
                                <option [ngValue]="'Elektro'"><span>Elektro</span></option>
                                <option [ngValue]="'Inkoop'"><span>Inkoop</span></option>
                                <option [ngValue]="'Bedrijfsbureau'"><span>Bedrijfsbureau</span></option>
                                <option [ngValue]="'Plaatwerkerij'"><span>Plaatwerkerij</span></option>
                                <option [ngValue]="'Machinebouw'"><span>Machinebouw</span></option>
                                <option [ngValue]="'Kwaliteit'"><span>Kwaliteit</span></option>
                                <option [ngValue]="'Service'"><span>Service</span></option>
                                <option [ngValue]="'Magazijn'"><span>Magazijn</span></option>
                                <option [ngValue]="'Finance'"><span>Finance</span></option>
                                <option [ngValue]="'Verkoop'"><span>Verkoop</span></option>
                                <option [ngValue]="'ICT'"><span>ICT</span></option>
                                <option [ngValue]="'Operations'"><span>Operations</span></option>
                                <option [ngValue]="'GeneralManagement'"><span>GeneralManagement</span></option>
                            </select>
                        </div>
                    </div>
                 </div>
             </div>
           </div>
                <!-- Logbook Field -->
            <div class="form-group">
                <label for="inputWVBTaskLog">Logboek Bericht: (Optioneel)</label>
                <textarea id="inputWVBTaskLog" class="form-control" maxlength="1500" rows="2" [(ngModel)]="newLogEntry.message"></textarea>
                <div class="char-counter">
                    @if(newLogEntry.message){
                        <div class="length-warning">
                            @if(newLogEntry.message!.length >= 1500){
                                <i class="bi bi-exclamation-triangle-fill"></i>
                                <small>Het maximaal aantal tekens is 1500</small>
                            }
                        </div>
                        <small [ngClass]="newLogEntry.message.length >= 1500 ? 'exceeded' : 'counter'">{{newLogEntry.message.length}}/1500</small>
                    } @else {
                        <small>0/1500</small>
                    }
                </div>
            </div>
                       
           <div class="row mt-2">
             <div class="col actions">
                 <button class="btn btn-secondary close-operation" (click)="close()"><i class="bi bi-x-lg"></i> Sluiten</button>
                 <button class="btn btn-success ms-2" (click)="createWVBTask()"><i class="bi bi-plus-square"></i> Taak {{wvbTask.$id ? "Aanpassen" : "Aanmaken"}}</button>
             </div>
           </div>
        </div>
     </div>
 </div>
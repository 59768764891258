<div class="container-fluid p0 sign-up-component">
    <div class="row-section">
        <div class="col brand-side">
            <div class="blur">

            </div>
        </div>
        <div class="col-4 sign-in-wrapper container-fluid p0">
            <div class="brand-wrapper row">
                <img src="assets/unikon-logo-fullcolor.svg">
            </div>
            <div class="row">
                <div class="col">
                    <form class="login-form col" [formGroup]="form" (ngSubmit)="signUp()">
                        <h3>Registreren</h3>
                        <div class="form-group form-wrapper">
                            <label for="email-adress">E-mailadres</label>
                            <input required type="email" formControlName="email" #email class="form-control" id="email-adress" placeholder="Uw E-mailadres">
                            <small *ngIf="showEmailWarning" id="emailHelp" class="form-text text-muted">{{emailWarningMessage}}</small>
                        </div>
                        <div class="form-group form-wrapper">
                            <label for="password-input">Kies een wachtwoord</label>
                            <input required type="password" formControlName="password" #password class="form-control" id="password-input" placeholder="Wachtwoord">
                            <small *ngIf="showPasswordWarning" id="passwordHelp" class="form-text text-muted">{{passwordWarningMessage}}</small>
                        </div>
                        <div class="form-group form-wrapper">
                            <label for="password-input">Herhaal uw wachtwoord</label>
                            <input required type="password" formControlName="passwordConfirm" #password class="form-control" id="passwordConfirm-input" placeholder="Herhaal uw wachtwoord">
                            <small *ngIf="showPasswordConfirmWarning" id="passwordConfirmHelp" class="form-text text-muted">{{passwordConfirmWarningMessage}}</small>
                        </div>
                        <div class="form-check terms-and-privacy">
                            <input type="checkbox" class="form-check-input" id="agreeOnTerms" formControlName="agreedOnTerms">
                            <label class="form-check-label" for="agreeOnTerms">Ik heb het <a routerLink="/privacy-statement" routerLinkActive="active" target="_blank">privacy-statement</a> en de <a onclick="window.open('../../../../assets/Algemene-voorwaarden-2024-Head4.pdf')">algemene voorwaarden</a> gelezen en ga hiermee akkoord.</label>
                            <small *ngIf="showAgreedOnTermsWarning" id="registerAsHelp" class="form-text text-muted">{{agreedOnTermsWarningMessage}}</small>
                        </div>
                        <button type="submit" class="btn btn-primary login-button">Registreer</button>
                    </form>
                    <div class="extra-actions">
                        <button class="btn btn-primary" routerLink="/password-recovery">Wachtwoord vergeten</button>
                        <button class="btn btn-primary primary-action" routerLink="/sign-in">Inloggen</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
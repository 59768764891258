import { Injectable } from '@angular/core';
import { ID, Permission, Query, Role } from 'appwrite';
import { Api } from 'src/app/helpers/api';
import { Competention } from 'src/app/models/competention';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompetentionService {
  
  competentionTable: string = "Competentions";

  constructor() { }

    /**
     * This method will return all the Competentions of a Mechanic with the given Id
    */
    async getMechanicCompetentions(mechanicId: string) {
      return await Api.database.listDocuments<Competention>(environment.database, this.competentionTable, [Query.limit(50), Query.equal('mechanicId', mechanicId)]);
    }

    /**
    * This method will create a new Competention in the database
    */
    addCompetention(competention: Competention, teamId: string, writePermissions: string[]) {
      return Api.database.createDocument(environment.database, this.competentionTable, ID.unique(), this.createPartialCompetention(competention))
    }
  
    /**
     * This method will update an existing Competention in the Database
     */
    updateCompetention(competention: Competention) {
      return Api.database.updateDocument(environment.database, this.competentionTable, competention.$id, this.createPartialCompetention(competention));
    }
  
    /**
     * This method will delete an existing Competention from the database
     */
    deleteCompetention(competention: Competention) {
      return Api.database.deleteDocument(environment.database, this.competentionTable, competention.$id);
    }

      /**
   * Creates a partial instance of the Competention without AppWrite properties
   * @param vehicle 
   * @returns 
   */
  private createPartialCompetention(competention: Competention): Partial<Competention> {
    if (!competention) {
      return {};
    }

    return {
      name: competention.name,
      mechanicId: competention.mechanicId
    };
  }
}

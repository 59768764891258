import { Pipe, PipeTransform } from '@angular/core';
import { Mechanic } from '../models/mechanic';

@Pipe({
    name: 'windowsPresent',
    pure: false
})
export class HoursPipe implements PipeTransform {
    transform(items: any[] | undefined): any {
        if (!items) {
            return items;
        }
        var ar = [];
        for (var i = 0; i < items.length; i++) {
            if(i % 2 === 0) { // index is even
                ar.push(items[i]);
            }
        }

        return ar;
    }
}
<div class="access-denied">
    <div class="content">
        <div class="access-denied-content">
            <div class="main-content">
                <div class="brand">
                    <img src="assets/unikon-logo-fullcolor.svg" />
                </div> 
                <h3><i class="bi bi-shield-lock"></i> Whoops! Toegang geweigerd</h3>
                <span>Het lijkt erop dat je account nog niet is geactiveerd of dat je geen toegang hebt tot deze pagina. <br> Neem contact op met de beheerder.</span>
            </div>
            <div class="take-me-home">
                <button class="btn btn-primary retry" (click)="retryPreviousPage()"><i class="bi bi-arrow-repeat"></i></button>
            </div>
        </div>
    </div>
    <div class="context-icon">
        <i class="bi bi-shield-lock"></i>
    </div>
</div>
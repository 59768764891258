<div class="row mechanic-create" *ngIf="mechanic">
    <div class="col card">
        <div class="card-body">
            <h4>Nieuwe Monteur</h4>
            <div class="row custom-attributes-row">
                <div class="col" *ngIf="mechanic.personalData">
                    <h5>Monteurgegevens</h5>
                    <p>Gebruik onderstaande invoervelden om alle persoonsgegevens van de monteur op te geven.</p>
                    <div class="form-group">
                        <label for="inputFirstname">Voornaam:</label>
                        <input type="text" class="form-control" id="inputFirstname" placeholder="Voornaam..."  [(ngModel)]="mechanic.personalData.firstname">
                    </div>
                    <div class="form-group">
                        <label for="inputLastname">Achternaam:</label>
                        <input type="text" class="form-control" id="inputLastname" placeholder="Achternaam..."  [(ngModel)]="mechanic.personalData.lastname">
                    </div>
                    <div class="form-group">
                        <label for="inputEmailAdress">Emailadres:</label>
                        <input type="text" class="form-control" id="inputEmailAdress" placeholder="Emailadres..."  [(ngModel)]="mechanic.personalData.emailAdress">
                    </div>
                    <div class="form-group">
                        <label for="inputPhoneNumber">Telefoonnummer:</label>
                        <input type="text" class="form-control" id="inputPhoneNumber" placeholder="Telefoonnummer..."  [(ngModel)]="mechanic.personalData.phoneNumber">
                    </div>
                    <div class="form-group">
                        <label for="inputType">Type:</label>
                        <select [(ngModel)]="mechanic.type" class="form-control" name="mechanicTypeDropdown" id="inputType">
                            <option *ngFor="let type of mechanicService.mechanicTypes; let i = index" value="{{type}}">
                                {{type}} 
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="mechanicExperience">Ervaring:</label>
                        <select [(ngModel)]="mechanic.experience" class="form-control" name="mechanicExperienceDropdown" id="mechanicExperience">
                            <option *ngFor="let experience of mechanicService.mechanicExperience; let i = index" value="{{experience}}">
                                {{experience}} 
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col">
                    <h5>Beschikbaarheid</h5>
                    <p>Beheer met onderstaand component de globale beschikbaarheid van de monteur. Dag-specifieke beschikbaarheid kan elders worden ingesteld.</p>
                    <div class="form-group">
                        <label for="vehicleStatusInput">Status:</label>
                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="{{mechanicService.getStatusIcon(mechanic.available)}}"></i></div>
                              </div>
                              <select [(ngModel)]="mechanic.available" class="form-control" name="mechanicStatusDropdown" id="mechanicStatusInput">
                                  <option *ngFor="let statusItem of mechanicService.mechanicStatusItems; let i = index" value="{{statusItem.value}}">
                                      {{statusItem.description}} 
                                  </option>
                              </select>
                          </div>
                    </div>
                    <div class="switch-group">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" id="showAvailabilityInSchedule" [(ngModel)]="mechanic.showAvailabilityInSchedule">
                            <label class="form-check-label" for="showAvailabilityInSchedule">Toon beschikbaarheid in service-inzetten rooster</label>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="mechanicStatusMessageInput">Aanvullende Informatie:</label>
                        <textarea [(ngModel)]="mechanic.additionalMessage" rows="6" class="form-control" id="mechanicStatusMessageInput"></textarea>
                    </div>
                </div>
            </div>
            <div class="row custom-attributes-row">
                <div class="col">
                    <h5>Competenties</h5>
                    <p>Voeg in onderstaande lijst de competenties van deze monteur toe.</p>
                    <div class="competentions">
                        <div class="competention" *ngFor="let competention of mechanic.competentions">
                            <span>{{competention.name}}</span> 
                            <div class="remove-competention" (click)="removeCompetention(competention)">
                                <i class="bi bi-x-lg"></i>
                            </div>
                        </div>
                        <span class="no-competentions" *ngIf="!mechanic.competentions || mechanic.competentions?.length == 0">Geen competenties om weer te geven</span>
                    </div>
                    <div class="form-group new-component">
                        <label for="inputNewCompetention">Competentie toevoegen:</label>
                        <div class="form-wrapper">
                            <input type="text" class="form-control" id="inputNewCompetention" placeholder="Competentie..."  [(ngModel)]="newCompetentionName">
                            <button class="btn btn-success add-button" (click)="addCompetention()"><i class="bi bi-plus-square"></i></button>
                        </div>
                        <div class="invalid-feedback"  [ngClass]="{'show': competentionsFailureFeedback}">
                           {{competentionsFailureFeedback}}
                        </div>
                    </div>
                </div>
                <div class="col">
                    <h5>Voertuigen</h5>
                    <p>Koppel in onderstaande lijst de permanent gekoppelde voertuigen voor deze monteur.</p>
                    <div class="on-vehicles">
                        <div class="on-vehicle" *ngFor="let permanentVehicle of mechanic.permanentVehicles">
                            <span>{{permanentVehicle.name}}</span> 
                            <div class="remove-on-vehicle" (click)="removePermanentVehicle(permanentVehicle)">
                                <i class="bi bi-x-lg"></i>
                            </div>
                        </div>
                        <div class="on-vehicle" *ngIf="mechanic.hasOwnVehicle">
                            <span>Eigen voertuig</span> 
                            <div class="remove-on-vehicle" (click)="removeOwnVehicle(mechanic)">
                                <i class="bi bi-x-lg"></i>
                            </div>
                        </div>
                        <span class="no-permanentVehicles" *ngIf="(!mechanic.permanentVehicles || mechanic.permanentVehicles?.length == 0) && !mechanic.hasOwnVehicle">Geen gekoppelde voertuigen om weer te geven</span>
                    </div>
                    <div class="form-group new-component">
                        <label for="mechanicVehicleInput">Voertuig koppelen:</label>
                        <div class="form-wrapper">
                            <select [(ngModel)]="vehicleToAttachId" class="form-control" name="mechanicVehicleDropdown" id="mechanicVehicleInput">
                                <option *ngFor="let vehicleToAttach of activeVehicles; let i = index" value="{{vehicleToAttach.$id}}">
                                    {{vehicleToAttach.registrationNumber}} - {{vehicleToAttach.name}} - {{vehicleToAttach.available}} 
                                </option>
                                <option value="own-vehicle">Eigen voertuig</option>
                            </select>
                            <button class="btn btn-success add-button" (click)="attachVehicle()"><i class="bi bi-plus-square"></i></button>
                        </div>
                        <div class="invalid-feedback"  [ngClass]="{'show': attachedVehiclesFailureFeedback}">
                           {{attachedVehiclesFailureFeedback}}
                        </div>
                    </div>
                    <div class="form-actions">
                        <button class="btn btn-success" (click)="createMechanic()">Monteur aanmaken</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import * as moment from 'moment';
import { Company } from 'src/app/models/company';
import { Machine } from 'src/app/models/machine';
import { ServiceRequest } from 'src/app/models/service-request';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { MachineService } from 'src/app/services/machine/machine.service';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { ServiceRequestService } from 'src/app/services/service-request/service-request.service';
import { WindowService } from 'src/app/services/window/window.service';
import { ServiceRequestScheduleComponent } from "../service-request-schedule/service-request-schedule.component";
import { ContextService } from 'src/app/services/context/context.service';
import { TravelComponentService } from 'src/app/services/travel-component/travel-component.service';
import { TravelComponentsListComponent } from '../../travel-components/travel-components-list/travel-components-list.component';
import { ServiceRequestContactPersonsComponent } from "../service-request-contact-persons/service-request-contact-persons.component";
import { ContactPersonService } from 'src/app/services/contact-person/contact-person.service';
import { ServiceRequestCustomerValuesComponent } from "../service-request-customer-values/service-request-customer-values.component";
import { WorkPreparationTasksTableComponent } from '../../../work-preparation-module/work-preparation-tasks-table/work-preparation-tasks-table.component';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    BrowserModule,
    TravelComponentsListComponent,
    ServiceRequestScheduleComponent,
    ServiceRequestContactPersonsComponent,
    ServiceRequestCustomerValuesComponent,
    WorkPreparationTasksTableComponent
],
  selector: 'app-service-request-edit',
  templateUrl: './service-request-edit.component.html',
  styleUrls: ['./service-request-edit.component.scss']
})
export class ServiceRequestEditComponent implements OnInit {

  @Input() serviceRequest?: ServiceRequest;
  showScheduleAndTransfers: boolean = false;
  showWVB: boolean = true;

  // The Subscriptions to Unsub on destroy
  subs: Subscription[] = [];

  constructor(public contextService: ContextService, private contactPersonService: ContactPersonService, private travelComponentService: TravelComponentService, public serviceRequestService: ServiceRequestService, private machineService: MachineService, private companyService: CompanyService, private communicationService: CommunicationService, private windowService: WindowService, private permissionService: PermissionService) { 
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe()
    })
  }

  ngOnInit(): void {
    this.subs.push(

      this.communicationService.activeServiceRequest.subscribe(serviceRequest => {
        if(!serviceRequest){
          return;
        }
        
        // When the CustomRelationId is present search for the active set of ContactPersons
      if(serviceRequest.referencedMachine?.relatedCompanyId){
        // Find contact persons to attach
        this.contactPersonService.getContactPersonsOfCompany(serviceRequest.referencedMachine?.relatedCompanyId).then(contactPersons => {
          if(this.serviceRequest?.referencedMachine?.company){
            this.serviceRequest.referencedMachine.company.contactPersons = contactPersons.documents;
          }
        });
      }
      
      // Try to retrieve TravelComponents
      this.travelComponentService.getAllTravelComponentsOfServiceRequest(serviceRequest.$id).then(travelComponents => {
        if(this.serviceRequest){
          this.serviceRequest.travelComponents = travelComponents;
        }
      }).catch(() => {
        this.communicationService.activeToastKind.next("Error");
        this.communicationService.activeToastMessage.next(`Het ophalen van gekoppelde reizen/transfers is mislukt!`);
      });
    })
    )
  }

  /**
   * Trigger close operation 
   */
  close(){
    this.serviceRequest = undefined;
    this.communicationService.activeServiceRequest.next(undefined);
  }

  /**
   * This method will upate an existing ServiceRequest
   * @returns 
   */
  async updateServiceRequest(){
    if(!this.serviceRequest){
      return;
    }
    
    this.serviceRequestService.updateServiceRequest(this.serviceRequest).then(async result => {
      if(!result || !this.serviceRequest){
        return;
      }

      if(this.serviceRequest.windows){
        await Promise.all(this.serviceRequest.windows.map(async (window) => {
          window.serviceRequestId = result.$id;

          // Only add or update Inzet Windows
          if(window.displayName?.includes("Inzet")){
           
            // Check if an update or create action is needed
            if(window.$id && window.$id != ""){
              return this.windowService.updateWindow(window);
            } else {
              return this.windowService.addWindow(window, this.communicationService.activeTeamId.getValue()!, this.permissionService.getWritePermissions()).then(windowResult => {
                window.$id = windowResult?.$id
             });
            }
          }

          return Promise.resolve();
        }));
      }

      // Remove the detached windows
      if(this.serviceRequest?.removedWindows){
        await Promise.all(this.serviceRequest.removedWindows.map(async (windowToRemove) => {
          return this.windowService.deleteWindow(windowToRemove);
        }));
        
        this.serviceRequest.removedWindows = [];
      }

      // Create TravelComponents if present
      if(this.serviceRequest.travelComponents){
        this.serviceRequest.travelComponents.map(travelComponent => {
          // Check wether the TravelComponents are existing or needs to be created
          // And set reference to the service Request
          travelComponent.serviceRequestId = this.serviceRequest!.$id;

          if(travelComponent.$id){
            // Update Component
            this.travelComponentService.updateTravelComponent(travelComponent).catch(() => {
              this.communicationService.activeToastKind.next("Error");
              this.communicationService.activeToastMessage.next(`Het updaten van reisdocument '${travelComponent?.name}' is mislukt!`);
            });
          } else {
            // Create Component
            this.travelComponentService.addTravelComponent(travelComponent).then(travelComponentResult => {
              travelComponent.$id = travelComponentResult.$id;
            }).catch(() => {
              this.communicationService.activeToastKind.next("Error");
              this.communicationService.activeToastMessage.next(`Het updaten van reisdocument '${travelComponent?.name}' is mislukt!`);
            });
          }
        });
      }

      // If there are removed TravelComponents then remove these from the database including the documents
      if(this.serviceRequest.removedTravelComponents){
        await Promise.all(await this.serviceRequest.removedTravelComponents.map(async removedTravelComponent => {
          // Check if the TravelComponent has document references
          removedTravelComponent.documentReferences?.split(" ").map(async documentReference => {
            // Remove document
            await this.travelComponentService.removeTravelDocument(documentReference);
          });

          // Remove actual component
          return this.travelComponentService.deleteTravelComponent(removedTravelComponent);
        }));

        this.serviceRequest.removedTravelComponents = [];
      }
        
      this.communicationService.activeToastKind.next("Success");
      this.communicationService.activeToastMessage.next(`Het updaten van serviceverzoek '${this.serviceRequest?.name}' is gelukt!`);
      
      
      this.serviceRequest = undefined;
      this.communicationService.activeServiceRequest.next(undefined);
      return Promise.resolve();
    }).catch(() => {
      this.communicationService.activeToastKind.next("Error");
      this.communicationService.activeToastMessage.next(`Het updaten van serviceverzoek '${this.serviceRequest?.name}' is mislukt!`);
    });;
  }

  /**
   * Callback event for changing the EndTime
   * @param event 
   */
  onEndTimeValuesChanged(event: any){
    if(this.serviceRequest){
      this.serviceRequest.endTime = moment(event).toDate();
    }
  }

  /**
   * Callback event for changing the StartTime
   * @param event 
   */
  onStartTimeValuesChanged(event: any){
    if(this.serviceRequest){
      //  Do Not Force 7:30 and do not autocomplete enddate
      this.serviceRequest.startTime = moment(event).toDate();
      this.communicationService.activeStartDate.next(this.serviceRequest.startTime);

      if(moment(this.serviceRequest.endTime).isBefore(moment(this.serviceRequest.startTime))){
        this.serviceRequest.endTime = moment(this.serviceRequest.startTime).hours(17).minutes(0).toDate();
      }
    }
  }

  /**
   * This method will try to find an existing machine with the presented number 
   */
  machineNumberInputChanged(){
    if((this.serviceRequest?.machineId?.length ?? 0) < 3){
      this.serviceRequest!.referencedMachine = undefined;
      this.serviceRequest!.referencedMachineId = undefined;
      return;
    }

    this.machineService.getMachinesByMachineNumber(this.serviceRequest!.machineId!).then(machine => {
      if(!machine){
        this.serviceRequest!.referencedMachine = undefined;
        this.serviceRequest!.referencedMachineId = undefined;
        return;
      }

      this.serviceRequest!.referencedMachine = machine;
      this.serviceRequest!.referencedMachineId = machine.$id;
      this.serviceRequest!.machineId = machine.machineId;

      // Only if the referenced machine is not present yet
      if(machine.relatedCompanyId && !machine.company){
        var matchingCompanies = this.communicationService.activeCompanies.getValue()?.filter(c => c.$id.includes(machine.relatedCompanyId!));

        if(matchingCompanies.length > 0){
          this.serviceRequest!.referencedMachine!.company = matchingCompanies[0];
        }
      }
    }).catch(() => {
      this.serviceRequest!.referencedMachine = undefined;
      this.serviceRequest!.referencedMachineId = undefined;
    });
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Application } from 'src/app/models/application';
import { Company } from 'src/app/models/company';
import { Issue } from 'src/app/models/issue';
import { Machine } from 'src/app/models/machine';
import { Mechanic } from 'src/app/models/mechanic';
import { ServiceRequest } from 'src/app/models/service-request';
import { Tool } from 'src/app/models/tool';
import { Vehicle } from 'src/app/models/vehicle';
import { WVBTask } from 'src/app/models/wvb-task';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  openStatusVehicleId?: string;
  openStatusVehicleIdChanged?: boolean;

  openStatusMechanicId?: string;
  openStatusMechanicIdChanged?: boolean;

  openStatusToolId?: string;
  openStatusToolIdChanged?: boolean;

  openStatusServiceRequestId?: string;
  openStatusServiceRequestIdChanged?: boolean;

  openMechanicUnavailabilityId?: string;
  openMechanicUnavailabilityIdChanged?: boolean;

  openVehicleUnavailabilityId?: string;
  openVehicleUnavailabilityIdChanged?: boolean;

  openStatusIssueId?: string;
  openStatusIssueIdChanged?: boolean;

  openStatusServiceContractId?: string;
  openStatusServiceContractIdChanged?: boolean;

  openPriorityIssueId?: string;
  openPriorityIssueIdChanged?: boolean;

  activeVehicles: BehaviorSubject<Vehicle[]>;
  activeVehicle: BehaviorSubject<Vehicle | undefined>;

  activeMechanics: BehaviorSubject<Mechanic[]>;
  activeMechanic: BehaviorSubject<Mechanic | undefined>;

  activeServiceRequests: BehaviorSubject<ServiceRequest[]>;
  activeServiceRequest: BehaviorSubject<ServiceRequest | undefined>;

  activeTeamId: BehaviorSubject<string | undefined>;
  activeWritePermissions: BehaviorSubject<string[] | undefined>;

  activeToastMessage: BehaviorSubject<string | undefined>;
  activeToastKind: BehaviorSubject<string | undefined>;

  activeTools: BehaviorSubject<Tool[]>;
  activeTool: BehaviorSubject<Tool | undefined>;

  activeMachines: BehaviorSubject<Machine[]>;
  activeMachine: BehaviorSubject<Machine | undefined>;

  activeWVBTasks: BehaviorSubject<WVBTask[]>;
  activeWVBTask: BehaviorSubject<WVBTask | undefined>;
  openStatusWVBTaskIdChanged?: boolean;
  openStatusWVBTaskId?: string;

  activeCompanies: BehaviorSubject<Company[]>;
  activeCompany: BehaviorSubject<Company | undefined>;

  activeStartDate: BehaviorSubject<Date | undefined>;
  updatedIssue: BehaviorSubject<Issue | undefined>;
  application: BehaviorSubject<Application | undefined>;

  loadingCompanies: boolean = false;
  loadingMachines: boolean = false;

  constructor() { 
    this.activeVehicles = new BehaviorSubject<Vehicle[]>([]);
    this.activeVehicle = new BehaviorSubject<Vehicle | undefined>(undefined);

    this.activeTools = new BehaviorSubject<Tool[]>([]);
    this.activeTool = new BehaviorSubject<Tool | undefined>(undefined);

    this.activeWVBTasks = new BehaviorSubject<WVBTask[]>([]);
    this.activeWVBTask = new BehaviorSubject<WVBTask | undefined>(undefined);

    this.activeMachines = new BehaviorSubject<Machine[]>([]);
    this.activeMachine = new BehaviorSubject<Machine | undefined>(undefined);

    this.activeServiceRequests = new BehaviorSubject<ServiceRequest[]>([]);
    this.activeServiceRequest = new BehaviorSubject<ServiceRequest | undefined>(undefined);

    this.activeMechanics = new BehaviorSubject<Mechanic[]>([]);
    this.activeMechanic = new BehaviorSubject<Mechanic | undefined>(undefined);

    this.activeTeamId = new BehaviorSubject<string | undefined>(undefined);
    this.activeWritePermissions = new BehaviorSubject<string[] | undefined>(undefined);

    this.activeToastMessage = new BehaviorSubject<string | undefined>(undefined);
    this.activeToastKind = new BehaviorSubject<string | undefined>(undefined);

    this.activeCompanies = new BehaviorSubject<Company[]>([]);
    this.activeCompany = new BehaviorSubject<Company | undefined>(undefined);

    this.activeStartDate = new BehaviorSubject<Date | undefined>(undefined);
    this.updatedIssue = new BehaviorSubject<Issue | undefined>(undefined);
    this.application = new BehaviorSubject<Application | undefined>(undefined);
  }
}

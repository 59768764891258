<div class="container">
    <div class="row second-row">
        <div class="col card">
            <div class="card-body">
                <h4>Serviceverzoeken Module</h4>
                <p>Beheer en bekijk in onderstaand component alle serviceverzoeken en krijg inzicht in de openstaande taken en benodigde acties. Configureer de huidige weergave door componenten in en uit te schakelen met de onderstaande knoppen.</p>
                <div class="header-actions">
                    <div class="quick-actions">
                        @if(showServiceRequestListComponent){
                            <div class="component-toggle" (click)="toggleServiceRequestListComponent(false)" [ngStyle]="{'background-color': !showServiceRequestListComponent ? 'rgb(38, 52, 140, 0.6)' : 'rgb(38, 140, 38, 0.6)'}">
                                <i class="bi bi-eye"></i>
                                <span>Overzichtslijst serviceverzoeken</span>
                            </div>
                        } @else {
                            <div class="component-toggle" (click)="toggleServiceRequestListComponent(true)" [ngStyle]="{'background-color': !showServiceRequestListComponent ? 'rgb(38, 52, 140, 0.6)' : 'rgb(38, 140, 38, 0.6)'}">
                                <i class="bi bi-eye-slash"></i>
                                <span>Overzichtslijst serviceverzoeken</span>
                            </div>
                        }
                        @if(showServiceRequestsComponent){
                            <div class="component-toggle" (click)="toggleServiceRequestComponent(false)" [ngStyle]="{'background-color': !showServiceRequestsComponent ? 'rgb(38, 52, 140, 0.6)' : 'rgb(38, 140, 38, 0.6)'}">
                                <i class="bi bi-eye"></i>
                                <span>Service-inzetten overzicht</span>
                            </div>
                        } @else {
                            <div class="component-toggle" (click)="toggleServiceRequestComponent(true)" [ngStyle]="{'background-color': !showServiceRequestsComponent ? 'rgb(38, 52, 140, 0.6)' : 'rgb(38, 140, 38, 0.6)'}">
                                <i class="bi bi-eye-slash"></i>
                                <span>Service-inzetten overzicht</span>
                            </div>
                        }
                        @if(showMechanicsScheduleComponent){
                            <div class="component-toggle" (click)="toggleMechanicScheduleComponent(false)" [ngStyle]="{'background-color': !showMechanicsScheduleComponent ? 'rgb(38, 52, 140, 0.6)' : 'rgb(38, 140, 38, 0.6)'}">
                                <i class="bi bi-eye"></i>
                                <span>Beschikbaarheidsrooster monteurs</span>
                            </div>
                        } @else {
                            <div class="component-toggle" (click)="toggleMechanicScheduleComponent(true)" [ngStyle]="{'background-color': !showMechanicsScheduleComponent ? 'rgb(38, 52, 140, 0.6)' : 'rgb(38, 140, 38, 0.6)'}">
                                <i class="bi bi-eye-slash"></i>
                                <span>Beschikbaarheidsrooster monteurs</span>
                            </div>
                        }
                    </div>
                    <div class="service-request-actions">
                        <div class="btn btn-success create-request" (click)="createServiceRequest()"><i class="bi bi-plus-square"></i>Serviceverzoek aanmaken</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-service-request-availability *ngIf="showServiceRequestsComponent" id="service-request-week-schedule"></app-service-request-availability>
    <app-service-request-table *ngIf="showServiceRequestListComponent" id="service-request-table"
    [displayedColumns]="['Name', 'Type', 'StartDate', 'Company', 'Status', 'Actions']"></app-service-request-table>
    <div class="row second-row" *ngIf="showMechanicsScheduleComponent">
        <div class="col card">
            <div class="card-body">
                <app-service-request-schedule [readOnly]="true" id="service-request-mechanic-shedule"></app-service-request-schedule>
            </div>
        </div>
    </div>
    <div class="row second-row" *ngIf="!newServiceRequest && !activeServiceRequest">
        <div class="col card">
            <div class="card-body">
                <div class="service-request-actions">
                    <button class="btn btn-success" (click)="createServiceRequest()"><i class="bi bi-plus-square"></i>Serviceverzoek aanmaken</button>
                </div>
            </div>
        </div>
    </div>
    <app-service-request-create *ngIf="newServiceRequest" [serviceRequest]="newServiceRequest" id="new-service-request"></app-service-request-create>
    <app-service-request-edit *ngIf="activeServiceRequest" [serviceRequest]="activeServiceRequest" id="edit-service-request"></app-service-request-edit>
</div>
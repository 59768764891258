import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Api } from './helpers/api';
import { AccessDeniedComponent } from './components/public/access-denied/access-denied.component';
import { SignInComponent } from './components/authentication/sign-in/sign-in.component';
import { SignUpComponent } from './components/authentication/sign-up/sign-up.component';
import { PasswordRecoveryComponent } from './components/authentication/password-recovery/password-recovery.component';
import { DatePipe } from '@angular/common';
import { AuthService } from './services/auth/auth.service';
import { WindowsPresentPipe } from './pipes/windowsPresentPipe';
import { MultiSelectModule } from 'primeng/multiselect';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { HoursPipe } from './pipes/hoursPipe';
import { MechanicPlanningDashboardComponent } from './components/protected/planning-module/mechanic-planning-dashboard/mechanic-planning-dashboard.component';
import { MechanicAvailabilityComponent } from './components/protected/planning-module/mechanic/mechanic-availability/mechanic-availability.component';
import { MechanicCreateComponent } from './components/protected/planning-module/mechanic/mechanic-create/mechanic-create.component';
import { MechanicDashboardComponent } from './components/protected/planning-module/mechanic/mechanic-dashboard/mechanic-dashboard.component';
import { MechanicDetailsComponent } from './components/protected/planning-module/mechanic/mechanic-details/mechanic-details.component';
import { ServiceRequestAvailabilityComponent } from './components/protected/planning-module/service-requests/service-request-availability/service-request-availability.component';
import { ServiceRequestCreateComponent } from './components/protected/planning-module/service-requests/service-request-create/service-request-create.component';
import { ServiceRequestDashboardComponent } from './components/protected/planning-module/service-requests/service-request-dashboard/service-request-dashboard.component';
import { ServiceRequestEditComponent } from './components/protected/planning-module/service-requests/service-request-edit/service-request-edit.component';
import { ServiceRequestScheduleComponent } from './components/protected/planning-module/service-requests/service-request-schedule/service-request-schedule.component';
import { ServiceRequestTableComponent } from './components/protected/planning-module/service-requests/service-request-table/service-request-table.component';
import { ToolCreateComponent } from './components/protected/planning-module/tools/tool-create/tool-create.component';
import { ToolDashboardComponent } from './components/protected/planning-module/tools/tool-dashboard/tool-dashboard.component';
import { ToolDetailsComponent } from './components/protected/planning-module/tools/tool-details/tool-details.component';
import { VehicleAvailabilityComponent } from './components/protected/planning-module/vehicle/vehicle-availability/vehicle-availability.component';
import { VehicleCreateComponent } from './components/protected/planning-module/vehicle/vehicle-create/vehicle-create.component';
import { VehicleDashboardComponent } from './components/protected/planning-module/vehicle/vehicle-dashboard/vehicle-dashboard.component';
import { VehicleDetailsComponent } from './components/protected/planning-module/vehicle/vehicle-details/vehicle-details.component';
import { AdminSettingsComponent } from './components/protected/settings-module/admin-settings/admin-settings.component';
import { WorkPreparationDashboardComponent } from './components/protected/work-preparation-module/work-preparation-dashboard/work-preparation-dashboard.component';
import { WorkPreparationTaskCreateComponent } from './components/protected/work-preparation-module/work-preparation-task-create/work-preparation-task-create.component';
import { WorkPreparationTasksTableComponent } from './components/protected/work-preparation-module/work-preparation-tasks-table/work-preparation-tasks-table.component';

@NgModule({
  declarations: [
    AppComponent,
    MechanicPlanningDashboardComponent,
    VehicleDashboardComponent,
    MechanicDashboardComponent,
    AccessDeniedComponent,
    SignInComponent,
    SignUpComponent,
    PasswordRecoveryComponent,
    VehicleDetailsComponent,
    VehicleAvailabilityComponent,
    VehicleCreateComponent,
    MechanicAvailabilityComponent,
    MechanicCreateComponent,
    MechanicDetailsComponent,
    ServiceRequestAvailabilityComponent,
    ServiceRequestDashboardComponent,
    ServiceRequestTableComponent,
    ToolDashboardComponent,
    ToolCreateComponent,
    ToolDetailsComponent,
    AdminSettingsComponent,
    WorkPreparationDashboardComponent,
    WorkPreparationTaskCreateComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MultiSelectModule,
    BrowserAnimationsModule,
    SatPopoverModule,
    ServiceRequestEditComponent,
    ServiceRequestScheduleComponent,
    WindowsPresentPipe,
    ServiceRequestCreateComponent,
    WorkPreparationTasksTableComponent,
  ],
  providers: [Api, DatePipe, AuthService, HoursPipe],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(){
    Api.provider();
  }
}

import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Company } from 'src/app/models/company';
import { ContactPerson } from 'src/app/models/contact-person';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { ContactPersonService } from 'src/app/services/contact-person/contact-person.service';

@Component({
  selector: 'app-asset-management-contact-person-edit',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './asset-management-contact-person-edit.component.html',
  styleUrl: './asset-management-contact-person-edit.component.scss'
})
export class AssetManagementContactPersonEditComponent implements OnInit {

  @Input() company?: Company;
  selectedContactPersons?: string[];
  activeContactPerson?: ContactPerson;
  feedbackMessage?: string;
  roles: any[] = [
    {
      value: 'Ter plaatse',
      description: 'Ter plaatse',
      id: 1,
    },
    {
      value: 'Administratief',
      description: 'Administratief',
      id: 2,
    },
    {
      value: 'Overig',
      description: 'Overig',
      id: 3,
    },
  ]

  constructor(private contactPersonService: ContactPersonService, private communicationService: CommunicationService){}
  
  
  ngOnInit(): void {
    if(this.company){
      // Retrieve contact persons of this company
      this.contactPersonService.getContactPersonsOfCompany(this.company.$id).then(contactPersons => {
        this.company!.contactPersons = contactPersons.documents;
      });
    }
  }

  /**
   * This method will open the first Contact Person as contact person to edit
   */
  editContactPerson(){
    if((this.selectedContactPersons?.length ?? 0) > 1){
      // Present message
      this.feedbackMessage = "Selecteer maximaal 1 contact persoon om te kunnen wijzigen";

      setTimeout(() => {
        this.feedbackMessage = undefined;
      }, 10000);
      return;
    }

    if(this.selectedContactPersons?.length == 1){
      this.activeContactPerson = this.company?.contactPersons?.filter(c => c.$id == this.selectedContactPersons![0])[0];
    }
  }

  /**
   * This method will create a new Contact Person to add
   */
  newContactPerson(){
    this.activeContactPerson = {
      $id: "",
      $collectionId: "",
      $createdAt: "",
      $databaseId: "",
      $permissions: [],
      $updatedAt: "",
      role: 'Ter Plaatse'
    }
  }

  /**
   * This method will save changes to the open Contact Person
   */
  saveChanges(){
    if(!this.activeContactPerson){
      return;
    }

    this.activeContactPerson.companyId = this.company?.$id;

    if(this.activeContactPerson.$id){
      this.contactPersonService.updateContactPerson(this.activeContactPerson).then(() => {
        this.communicationService.activeToastKind.next("Success");
        this.communicationService.activeToastMessage.next(`Het updaten van contactpersoon '${this.activeContactPerson?.firstname ?? 'Onbekend'}' is gelukt!`);
      }).catch(() => {
        this.communicationService.activeToastKind.next("Error");
        this.communicationService.activeToastMessage.next(`Het updaten van contactpersoon '${this.activeContactPerson?.firstname ?? 'Onbekend'}' is mislukt!`);
      });
    } else {
      this.contactPersonService.addContactPerson(this.activeContactPerson).then(() => {
        this.communicationService.activeToastKind.next("Success");
        this.communicationService.activeToastMessage.next(`Het aanmaken van contactpersoon '${this.activeContactPerson?.firstname ?? 'Onbekend'}' is gelukt!`);
      }).catch(() => {
        this.communicationService.activeToastKind.next("Error");
        this.communicationService.activeToastMessage.next(`Het aanmaken van contactpersoon '${this.activeContactPerson?.firstname ?? 'Onbekend'}' is mislukt!`);
      });
    }

    this.close();
  }

  /**
   * This method will close the open Edit/create window for Contact Person
   */
  close(){
    this.activeContactPerson = undefined;
  }

  /**
   * This method will remove a Contact Person from the current company
   */
  remove(){
    if(!this.activeContactPerson){
      return;
    }

    if(this.activeContactPerson.$id){
      this.contactPersonService.deleteContactPerson(this.activeContactPerson).then(() => {
        this.communicationService.activeToastKind.next("Success");
        this.communicationService.activeToastMessage.next(`Het verwijderen van contactpersoon '${this.activeContactPerson?.firstname ?? 'Onbekend'}' is gelukt!`);
        this.close();

      }).catch(() => {
        this.communicationService.activeToastKind.next("Error");
        this.communicationService.activeToastMessage.next(`Het verwijderen van contactpersoon '${this.activeContactPerson?.firstname ?? 'Onbekend'}' is mislukt!`);
      });
    }
  }
}

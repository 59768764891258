import { Component, OnInit } from '@angular/core';
import { TravelComponent } from 'src/app/models/travel-component';
import { TravelComponentService } from 'src/app/services/travel-component/travel-component.service';
import { TravelComponentComponent } from "../travel-component/travel-component.component";
import { FormsModule } from '@angular/forms';
import { ServiceRequest } from 'src/app/models/service-request';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    TravelComponentComponent,
    FormsModule
],
  selector: 'app-travel-components-list',
  templateUrl: './travel-components-list.component.html',
  styleUrls: ['./travel-components-list.component.scss']
})
export class TravelComponentsListComponent implements OnInit {

  travelComponentType: string;
  serviceRequest?: ServiceRequest;

  // The Subscriptions to Unsub on destroy
  subs: Subscription[] = [];

  constructor(public travelComponentService: TravelComponentService, private communicationService: CommunicationService) {
    this.travelComponentType = this.travelComponentService.travelComponentTypes[0]?.value;
   }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe()
    })
  }

  ngOnInit(): void {
    this.communicationService.activeServiceRequest?.subscribe(serviceRequest => {
      this.serviceRequest = serviceRequest;
    });
  }

  /**
   * This method will add a new TravelComponent to the list of Active Components
   */
  addNewTravelComponent(){
    // Guard
    if(this.serviceRequest && !this.serviceRequest.travelComponents){
      this.serviceRequest.travelComponents = [];
    }

    if(!this.serviceRequest?.travelComponents){
      return;
    }

    this.serviceRequest.travelComponents.push({
      $id: "",
      $collectionId: "",
      $createdAt: "",
      $databaseId: "",
      $permissions: [],
      $updatedAt: "",
      name: "",
      type: this.travelComponentType,

      // Disabled for now
      // // Check which of the components needs to be created
      // flightInformation: this.travelComponentType == this.travelComponentService.travelComponentTypes[2].value ? 
      // {
      //   $id: "",
      //   $collectionId: "",
      //   $createdAt: "",
      //   $databaseId: "",
      //   $permissions: [],
      //   $updatedAt: "",
      // } : undefined,

      // rentalCarInformation: this.travelComponentType == this.travelComponentService.travelComponentTypes[1].value ? 
      // {
      //   $id: "",
      //   $collectionId: "",
      //   $createdAt: "",
      //   $databaseId: "",
      //   $permissions: [],
      //   $updatedAt: "",
      // } : undefined,

      // hotelInformation: this.travelComponentType == this.travelComponentService.travelComponentTypes[0].value ? 
      // {
      //   $id: "",
      //   $collectionId: "",
      //   $createdAt: "",
      //   $databaseId: "",
      //   $permissions: [],
      //   $updatedAt: "",
      // } : undefined,
    });
  }

}

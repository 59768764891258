import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppwriteException } from 'appwrite';
import { Subscription } from 'rxjs';
import { Document } from 'src/app/models/document';
import { ServiceRequest } from 'src/app/models/service-request';
import { TravelComponent } from 'src/app/models/travel-component';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { TravelComponentService } from 'src/app/services/travel-component/travel-component.service';

@Component({
  standalone: true,
  imports: [
    FormsModule
  ],
  selector: 'app-travel-component',
  templateUrl: './travel-component.component.html',
  styleUrls: ['./travel-component.component.scss']
})
export class TravelComponentComponent implements OnInit {

  @Input() travelComponent?: TravelComponent;
  showConfirmationButtons: boolean = false;
  serviceRequest?: ServiceRequest;

  // The Subscriptions to Unsub on destroy
  subs: Subscription[] = [];

  constructor(public travelComponentService: TravelComponentService, public communicationService: CommunicationService) { }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe()
    })
  }

  ngOnInit(): void {
    // Subscribe on the current ServiceRequest
    this.subs.push(
      this.communicationService.activeServiceRequest.subscribe(serviceRequest => {
        this.serviceRequest = serviceRequest;
      })
    )
  }

  /**
   * This mehthod will toggle the confirmmation buttons
   */
  toggleConfirmationButtons(){
    this.showConfirmationButtons = true;
  }

  /**
   * This method will remove the current TravelComponent and the beloning documents
   */
  deleteTravelComponent(){
    if(!this.travelComponent || !this.serviceRequest){
      return;
    }

    // Add to removed list --> and create list if not existing yet
    if(this.serviceRequest && !this.serviceRequest.removedTravelComponents){
      this.serviceRequest!.removedTravelComponents = []
    }

    this.serviceRequest.removedTravelComponents?.push(this.travelComponent);

    // Remove from list
    if(this.serviceRequest?.travelComponents){
      this.serviceRequest.travelComponents = this.serviceRequest.travelComponents.filter(t => t != this.travelComponent);
    }
  }

  /**
   * This method will cancel the removal action
   */
  cancelRemoval(){
    this.showConfirmationButtons = false;
  }

  downloadFile(documentRef: Document){
    if(!documentRef){
      return;
    }

    // Download file
    var documentUrl = this.travelComponentService.downloadTravelDocument(documentRef.id);
    const link = document.createElement("a");
    link.href = documentUrl.href;
    link.download = documentRef.name;
    link.click();
  }

  /**
   * This method will remove the selected document
   * @param document 
   */
  removeFile(document: Document){
    if(!document){
      return;
    }

    this.travelComponentService.removeTravelDocument(document.id).then(() => {
      if(this.travelComponent){
        this.travelComponent.documents = this.travelComponent.documents?.filter(d => d != document);
        this.travelComponent.documentReferences = this.travelComponent.documents?.map(d => d.id).join(" ");
      }
      
      // Succesfeedback
    }).catch((e: AppwriteException) => {
      if(e.code == 401){
        // this.warningFeedback = "Het is niet gelukt om het bestand te uploaden. U heeft onvoldoende rechten voor deze actie";
      } else {
        // this.warningFeedback = "Het is niet gelukt om het bestand te uploaden.";
      }
      // Hide after 3 seconds
      // setTimeout(() => { this.warningFeedback = undefined }, 3000);
    });;
  }

  /**
   * Upload a file and add it to this TravelComponent
   * @param event 
   */
  uploadFile(event: any){
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      this.travelComponentService.uploadTravelDocument(fileList![0]).then(uploadResult => {
        if (uploadResult && this.travelComponent) {
          // Add DocumentReference to set of references
          if(!this.travelComponent.documents){
            this.travelComponent.documents = [];
          }

          if(this.travelComponent.documentReferences){
            this.travelComponent.documentReferences += ` ${uploadResult.$id}`;
          } else{
            this.travelComponent.documentReferences = uploadResult.$id;
          }

          this.travelComponent.documents?.push({
            id: uploadResult.$id,
            name: uploadResult.name,
            type: uploadResult.mimeType?.split("/")[1]
          });
        }
      }).catch((e: AppwriteException) => {
        if(e.code == 401){
          // this.warningFeedback = "Het is niet gelukt om het bestand te uploaden. U heeft onvoldoende rechten voor deze actie";
        } else {
          // this.warningFeedback = "Het is niet gelukt om het bestand te uploaden.";
        }
        // Hide after 3 seconds
        // setTimeout(() => { this.warningFeedback = undefined }, 3000);
      });
    }
  }
}
